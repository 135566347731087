import * as React from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import {IconButton, Stack, Typography, Avatar, Modal, ModalDialog} from '@mui/joy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileUploadModal from './FileUploadModal';
import {sendMessage, editMessage, createPrivateChat} from '@/api';
import {useState, useCallback, useEffect, useRef} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import CustomTextarea from "./CustomTextarea";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiPickerPopover from "./EmojiPickerPopover";
import {ChatProps, DecodedToken, MessageProps} from "../core/types";
import {motion} from "framer-motion";
import {useNavigate} from "react-router-dom";
import {AttachmentBinary, useWebSocket} from "@/hooks/useWebSocket";
import type { SecuPayload } from '@/hooks/useWebSocket';
import MicIcon from "@mui/icons-material/Mic";
import {logger} from "@/utils/logger";

export type UploadedFileData = {
    file: File;
};

export type MessageInputProps = {
    chatId: number | null;
    selectedChat: ChatProps | null;
    setSelectedChat: (chat: ChatProps) => void;
    currentUserId: number;
    onSubmit: (newMessage: any) => void;
    editingMessage?: { id: number | null; content: string | null } | null;
    setEditingMessage: (message: { id: number | null; content: string | null } | null) => void;
    sendSecuLineMessage: (payload: SecuPayload, chatId: number, msgType?: number, groupKey?: string) => Promise<void>;
    otherUserPublicKeyBase64: string;
    setChats: React.Dispatch<React.SetStateAction<ChatProps[]>>;
    currentUser: DecodedToken | null;
    onEditMessage?: (editMessage: MessageProps) => void;
};

export default function MessageInput(props: MessageInputProps) {
    const { t } = useTranslation();
    const {
        chatId, selectedChat, setSelectedChat, currentUserId,
        editingMessage, setEditingMessage, onSubmit, sendSecuLineMessage, otherUserPublicKeyBase64, currentUser, onEditMessage
    } = props;
    const [message, setMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFileData[]>([]);
    const [isFileUploadOpen, setFileUploadOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [isImageOpen, setIsImageOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [isSending, setIsSending] = useState(false);
    const navigate = useNavigate();
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
    const [recordTime, setRecordTime] = useState(0);
    const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(null);
    const stopReasonRef = useRef<'send' | 'cancel' | null>(null);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });

            const chunks: Blob[] = [];

            recorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.push(e.data);
                }
            };

            recorder.onstop = async () => {
                stream.getTracks().forEach((track) => track.stop());

                const reason = stopReasonRef.current;
                stopReasonRef.current = null;

                if (reason === 'cancel') {
                    setRecordedChunks([]);
                    setRecordTime(0);
                    setIsRecording(false);
                    return;
                }

                if (chunks.length === 0) return;

                const blob = new Blob(chunks, { type: 'audio/webm' });
                const file = new File([blob], `voice-${Date.now()}.webm`, { type: 'audio/webm' });

                setUploadedFiles([{ file }]);
                setRecordTime(0);
                setIsRecording(false);

                await handleClick();
            };


            recorder.start();
            setMediaRecorder(recorder);
            setIsRecording(true);
            setRecordTime(0);

            const interval = setInterval(() => {
                setRecordTime((prev) => prev + 10);
            }, 10);
            setTimerInterval(interval);
        } catch (err) {
            logger.error('🎙️ Ошибка при доступе к микрофону:', err);
        }
    };


    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
        }
        if (timerInterval) clearInterval(timerInterval);
        setTimerInterval(null);
        setIsRecording(false);
    };

    const cancelRecording = () => {
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
        }
        if (timerInterval) clearInterval(timerInterval);
        setTimerInterval(null);
        setRecordedChunks([]);
        setIsRecording(false);
        setRecordTime(0);
    };

    const sendVoiceMessage = async () => {
        const blob = new Blob(recordedChunks, { type: 'audio/webm' });
        const file = new File([blob], `voice-${Date.now()}.webm`, { type: 'audio/webm' });

        setUploadedFiles([{ file }]);

        setRecordedChunks([]);
        setIsRecording(false);
        setRecordTime(0);
        await handleClick();
    };

    const formatTime = (ms: number) => {
        const minutes = Math.floor(ms / 60000);
        const seconds = Math.floor((ms % 60000) / 1000);
        const milliseconds = Math.floor((ms % 1000) / 10);
        return `${minutes}:${seconds.toString().padStart(2, '0')},${milliseconds.toString().padStart(2, '0')}`;
    };




    const handleImageClick = (imageUrl: string) => {
        setImageSrc(imageUrl);
        setIsImageOpen(true);
    };

    const handleVideoClick = (src: string) => {
        setVideoSrc(src);
        setIsVideoOpen(true);
    };

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsImageOpen(false);
            setIsVideoOpen(false);
            setIsClosing(false);
        }, 100);
    };

    useEffect(() => {
        if (editingMessage?.content) {
            setMessage(editingMessage.content);
        }
        setUploadedFiles([]);
    }, [editingMessage, chatId]);


    const handleClick = async () => {
        if (isSending) return;
        setIsSending(true);

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                logger.error("❌ Ошибка: Authorization token is missing");
                return;
            }

            const content = message.trim();
            if (content === "" && uploadedFiles.length === 0) {
                logger.warn("⚠️ Нельзя отправить пустое сообщение");
                return;
            }

            let finalChatId = selectedChat?.id;

            if (editingMessage && editingMessage.id !== null) {
                logger.info("✏️ Редактирование сообщения через SecuLine (optimistic edit):", editingMessage.id);

                const trimmedContent = message.trim();
                if (!trimmedContent) {
                    logger.warn("⚠️ Пустое сообщение не отправляется");
                    setIsSending(false);
                    return;
                }

                const optimisticEditedMessage: MessageProps = {
                    id: editingMessage.id,
                    chatId: selectedChat!.id,
                    userId: currentUserId,
                    content: trimmedContent,
                    isEdited: true,
                    pending: true,
                    createdAt: new Date().toISOString(),
                    user: {
                        id: currentUserId,
                        public_key: currentUser?.publicKey || '',
                        avatar: currentUser?.avatar || '',
                        solachat_pub: currentUser?.solachat_pub || '',
                        verified: currentUser?.verified || false,
                        online: true,
                        role: 'member',
                        lastOnline: new Date().toISOString(),
                    },
                    attachment: [],
                };

                logger.info("🔄 Обновляем UI (optimistic edit):", optimisticEditedMessage);

                if (onEditMessage) {
                    onEditMessage(optimisticEditedMessage);
                }


                await sendSecuLineMessage(
                    {
                        id: editingMessage.id,
                        user_id: currentUserId!,
                        chat_id: selectedChat!.id,
                        content: trimmedContent,
                        recipientPublicKeyBase64: !selectedChat?.isGroup ? otherUserPublicKeyBase64 || '' : '',

                        groupKey: selectedChat?.isGroup ? selectedChat.groupKey : undefined,
                        attachment: [],
                        user: {
                            id: currentUserId,
                            solachat_pub: currentUser!.solachat_pub!,
                        },
                    },
                    selectedChat!.id,
                    7
                );

                setEditingMessage(null);
                setMessage("");
                setIsSending(false);
                return;
            }



            if (!finalChatId || finalChatId === -1) {
                const recipientId = Number(window.location.hash.replace("#", ""));

                if (!recipientId || recipientId === currentUserId) {
                    logger.error("❌ Ошибка: Получатель не найден.");
                    return;
                }

                logger.info(`🔄 Создаем новый чат с userId ${recipientId} перед отправкой...`);
                const newChat = await createPrivateChat(currentUserId, recipientId, token);

                if (!newChat || !newChat.id) {
                    logger.error("❌ Ошибка: Сервер не вернул ID нового чата!");
                    return;
                }

                finalChatId = newChat.id;

                logger.info(`✅ Чат создан с ID: ${newChat.id}, обновляем selectedChat.`);

                if (!selectedChat || selectedChat.id !== newChat.id) {
                    logger.info(`🔄 Устанавливаем selectedChat: ${newChat.id}`);
                    setSelectedChat(newChat);
                }

                navigate(`/chat/#${recipientId}`);
            }


            if (!finalChatId) {
                logger.error("❌ Ошибка: Chat ID отсутствует. Сообщение не отправлено.");
                return;
            }

            const tempId = Date.now();
            logger.info(`📝 Создано временное сообщение с tempId: ${tempId}`);

            const optimisticFiles = uploadedFiles.map(fileData => ({
                fileName: fileData.file.name,
                filePath: URL.createObjectURL(fileData.file),
                fileType: fileData.file.type,
            }));

            const optimisticMessage = {
                id: tempId,
                tempId,
                chatId: finalChatId,
                userId: currentUserId,
                content,
                createdAt: new Date().toISOString(),
                pending: true,
                isRead: false,
                isOptimistic: true,
                attachment: optimisticFiles,
                user: {
                    id: currentUserId,
                    public_key: currentUser?.publicKey,
                    avatar: currentUser?.avatar
                }
            };


            logger.info("📩 Добавляем оптимистичное сообщение в UI:", optimisticMessage);
            onSubmit(optimisticMessage);

            const pendingMessages = JSON.parse(localStorage.getItem("pendingMessages") || "[]");
            localStorage.setItem("pendingMessages", JSON.stringify([...pendingMessages, optimisticMessage]));

            if (!sendSecuLineMessage) {
                logger.error("❌ SecuLine не готов");
                return;
            }

            const attachments: AttachmentBinary[] = await Promise.all(
                uploadedFiles.map(async (fileData) => {
                    const arrayBuffer = await fileData.file.arrayBuffer();
                    return {
                        fileName: fileData.file.name,
                        fileType: fileData.file.type,
                        data: new Uint8Array(arrayBuffer),
                    };
                })
            );

            await sendSecuLineMessage(
                {
                    user_id: currentUserId,
                    chat_id: finalChatId,
                    content,
                    attachment: attachments,
                    tempId,
                    recipientPublicKeyBase64: selectedChat?.isGroup ? '' : otherUserPublicKeyBase64,
                    groupKey: selectedChat?.isGroup ? selectedChat.groupKey : undefined,
                },
                finalChatId
            );

            logger.info("📤 Отправлено через SecuLine");


            const updatedPendingMessages = JSON.parse(localStorage.getItem("pendingMessages") || "[]")
                .filter((msg: any) => msg.id !== tempId);
            localStorage.setItem("pendingMessages", JSON.stringify(updatedPendingMessages));

            logger.info("🧹 Очищаем поле ввода");
            setMessage("");
            setUploadedFiles([]);
        } catch (error) {
            logger.error("❌ Ошибка при отправке сообщения:", error);
        } finally {
            setIsSending(false);
        }
    };

    const handleEmojiSelect = (emoji: string) => {
        setMessage((prev) => prev + emoji);
        setAnchorEl(null);
    };

    const toggleEmojiMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleFileSelect = useCallback((file: File) => {
        setUploadedFiles((prevFiles) => [...prevFiles, { file }]);
    }, []);

    const removeUploadedFile = useCallback((index: number) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }, []);

    const keyboardOption = sessionStorage.getItem("keyboardOption") || "enter";

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            if (keyboardOption === 'ctrlEnter' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                handleClick();
            } else if (keyboardOption === 'enter' && !e.shiftKey) {
                e.preventDefault();
                handleClick();
            }
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].kind === 'file') {
                const file = items[i].getAsFile();
                if (file) {
                    handleFileSelect(file);
                }
            }
        }
    };


    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        setIsDragging(false);
        if (files && files.length > 0) {
            Array.from(files).forEach((file) => handleFileSelect(file));
            event.dataTransfer.clearData();
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };


    return (
        <Box
            sx={{ position: 'relative', px: 2, pb: 1 }}
            onPaste={handlePaste}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {isDragging && (
                <Box
                    component={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(0, 168, 255, 0.1)',
                        color: '#00a8ff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.25rem',
                        zIndex: 20,
                        border: '2px dashed rgba(0, 168, 255, 0.3)',
                        borderRadius: 'lg',
                    }}
                >
                    {t('Drop your files here')}
                </Box>
            )}
            <FormControl sx={{ position: 'sticky', zIndex: 10 }}>
                <Stack direction="row" alignItems="flex-end" spacing={1} sx={{ width: '100%' }}>
                    <Stack
                        direction="column"
                        sx={{
                            flexGrow: 1,
                            border: '1px solid rgba(0, 168, 255, 0.3)',
                            borderRadius: 'lg',
                            padding: '8px',
                            bgcolor: 'rgba(0, 168, 255, 0.05)',
                            boxShadow: '0 4px 16px rgba(0, 168, 255, 0.1)',
                            transition: 'width 0.2s ease-in-out',
                            width: isRecording ? 'calc(100% - 120px)' : '100%',
                        }}
                    >
                        {editingMessage && editingMessage.content && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ width: '100%', mb: 1 }}
                            >
                                <EditIcon sx={{ color: '#a0d4ff', mr: 1, bgcolor: 'transparent' }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        overflow: 'hidden',
                                        borderRadius: 'md',
                                        backgroundColor: 'rgba(0, 168, 255, 0.08)',
                                        px: 1.5,
                                        py: 0.8,
                                        flex: 1,
                                    }}
                                >

                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography level="body-sm"
                                                    sx={{ color: 'text.secondary' }}
                                        >
                                            {t('Editing')}
                                        </Typography>
                                        <Typography
                                            level="body-sm"
                                            noWrap
                                            sx={{ maxWidth: '300px', color: 'text.secondary' }}
                                        >
                                            {editingMessage.content}
                                        </Typography>
                                    </Box>
                                </Box>

                                <IconButton
                                    size="sm"
                                    onClick={() => {
                                        setEditingMessage(null);
                                        setMessage('');
                                    }}
                                    sx={{
                                        ml: 1,
                                        bgcolor: 'transparent',
                                        '&:hover': { bgcolor: 'rgba(255,255,255,0.04)' },
                                    }}
                                >
                                    <CloseIcon sx={{ color: '#a0d4ff' }} />
                                </IconButton>
                            </Stack>
                        )}

                        <Stack direction="row" alignItems="center" spacing={1}>
                            <IconButton
                                sx={{
                                    color: '#00a8ff',
                                    '&:hover': { bgcolor: 'rgba(0, 168, 255, 0.1)' },
                                }}
                                onClick={toggleEmojiMenu}
                            >
                                <EmojiEmotionsIcon />
                            </IconButton>

                            <EmojiPickerPopover
                                onEmojiSelect={handleEmojiSelect}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                            />

                            <CustomTextarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder={t('writeMessage')}
                                onKeyDown={handleKeyDown}
                                sx={{
                                    flexGrow: 1,
                                    minHeight: 'auto',
                                    resize: 'none',
                                    maxWidth: '100%',
                                    border: 'none',
                                    outline: 'none',
                                    color: '#a0d4ff',
                                    bgcolor: 'transparent',
                                    '&::placeholder': { color: '#8ab4f8' },
                                    '--Textarea-focusedHighlight': 'transparent',
                                    '&:focus': { outline: 'none', boxShadow: 'none' },
                                }}
                            />

                            {!isRecording && (
                                <IconButton
                                    size="sm"
                                    variant="plain"
                                    sx={{
                                        ml: 1,
                                        color: '#00a8ff',
                                        '&:hover': { bgcolor: 'rgba(0, 168, 255, 0.1)' },
                                    }}
                                    onClick={() => setFileUploadOpen(true)}
                                >
                                    <AttachFileIcon />
                                </IconButton>
                            )}

                            {isRecording && (
                                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1.5, pr: 1.5 }}>
                                    <Typography
                                        sx={{
                                            color: '#ffffff',
                                            fontSize: '15px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        {formatTime(recordTime)}
                                    </Typography>
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            bgcolor: '#ff4d4f',
                                            ml: 1,
                                            animation: 'pulse 1s infinite',
                                            '@keyframes pulse': {
                                                '0%': { opacity: 1 },
                                                '50%': { opacity: 0.3 },
                                                '100%': { opacity: 1 },
                                            },
                                        }}
                                    />
                                </Box>
                            )}
                        </Stack>
                    </Stack>

                    {/* Кнопки отправки/записи */}
                    {isRecording ? (
                        <>
                            <IconButton
                                size="lg"
                                onClick={() => {
                                    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
                                        stopReasonRef.current = 'cancel';
                                        mediaRecorder.stop();
                                    }
                                }}
                                sx={{
                                    bgcolor: '#132f4c',
                                    color: '#ff4d4f',
                                    borderRadius: '50%',
                                    width: '55px',
                                    height: '55px',
                                    '&:hover': { bgcolor: '#ff4d4f' },
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>

                            <IconButton
                                size="lg"
                                onClick={() => {
                                    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
                                        stopReasonRef.current = 'send';
                                        mediaRecorder.stop();
                                    }
                                }}
                                sx={{
                                    bgcolor: '#132f4c',
                                    color: '#00a8ff',
                                    borderRadius: '50%',
                                    width: '55px',
                                    height: '55px',
                                    '&:hover': { bgcolor: '#1c3e5c' },
                                }}
                            >
                                <SendRoundedIcon />
                            </IconButton>
                        </>
                    ) : (
                        <IconButton
                            size="lg"
                            onClick={message.trim() !== '' ? handleClick : startRecording}
                            sx={{
                                bgcolor: '#132f4c',
                                color: '#00a8ff',
                                borderRadius: '50%',
                                width: '60px',
                                height: '55px',
                                '&:hover': { bgcolor: '#1c3e5c' },
                            }}
                        >
                            {message.trim() !== '' ? <SendRoundedIcon /> : <MicIcon />}
                        </IconButton>
                    )}
                </Stack>
            </FormControl>


            {uploadedFiles.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    <Stack direction="row" flexWrap="wrap" spacing={2} sx={{ mt: 1 }}>
                        {uploadedFiles.map((file, index) => {
                            const isImage = file.file.type.startsWith('image/');
                            const isVideo = file.file.type.startsWith('video/');
                            const fileUrl = URL.createObjectURL(file.file);

                            return (
                                <Box
                                    key={index}
                                    component={motion.div}
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '8px 16px',
                                        border: '1px solid rgba(0, 168, 255, 0.3)',
                                        borderRadius: 'lg',
                                        bgcolor: 'rgba(0, 168, 255, 0.05)',
                                        minWidth: '200px',
                                        backdropFilter: 'blur(10px)',
                                    }}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        {isImage ? (
                                            <Avatar
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '4px',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                    border: '1px solid rgba(0, 168, 255, 0.3)',
                                                }}
                                                src={fileUrl}
                                                alt="preview"
                                                onClick={() => handleImageClick(fileUrl)}
                                            />
                                        ) : isVideo ? (
                                            <Box
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '4px',
                                                    overflow: 'hidden',
                                                    cursor: 'pointer',
                                                    border: '1px solid rgba(0, 168, 255, 0.3)',
                                                }}
                                                onClick={() => handleVideoClick(fileUrl)}
                                            >
                                                <video width="40" height="40" style={{ objectFit: 'cover' }}>
                                                    <source src={fileUrl} type={file.file.type} />
                                                </video>
                                            </Box>
                                        ) : (
                                            <Avatar sx={{ bgcolor: 'rgba(0, 168, 255, 0.1)', color: '#00a8ff' }}>
                                                <InsertDriveFileIcon />
                                            </Avatar>
                                        )}
                                        <Typography noWrap sx={{ maxWidth: '120px', color: '#a0d4ff' }}>
                                            {file.file.name}
                                        </Typography>
                                    </Stack>
                                    <IconButton
                                        onClick={() => removeUploadedFile(index)}
                                        size="sm"
                                        sx={{ color: '#00a8ff' }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            );
                        })}
                    </Stack>
                </Box>
            )}

            {isImageOpen && imageSrc && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 999,
                        cursor: 'pointer',
                        animation: `${isClosing ? 'fade-out' : 'fade-in'} 0.2s ease-in-out`,
                    }}
                    onClick={handleClose}
                >
                    <Box sx={{                            position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', }}>
                        <img
                            src={imageSrc}
                            alt="attachment-preview"
                            style={{
                                maxWidth: '70%',
                                maxHeight: '70%',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </Box>
            )}

            {isVideoOpen && videoSrc && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        fleft: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 999,
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <video
                            controls
                            autoPlay
                            style={{
                                maxWidth: '70%',
                                maxHeight: '70%',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        >
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                    </Box>
                </Box>
            )}

            <FileUploadModal
                open={isFileUploadOpen}
                handleClose={() => setFileUploadOpen(false)}
                onFileSelect={handleFileSelect}
            />
        </Box>
    );
}
