export const saveKeyPair = (publicKey: Uint8Array, privateKey: Uint8Array) => {
    localStorage.setItem("solachat_pub", Buffer.from(publicKey).toString("base64"));
    localStorage.setItem("solachat_priv", Buffer.from(privateKey).toString("base64"));
};

export const loadKeyPair = (): { publicKey: Uint8Array; privateKey: Uint8Array } | null => {
    const pub = localStorage.getItem("solachat_pub");
    const priv = localStorage.getItem("solachat_priv");

    if (!pub || !priv) return null;

    return {
        publicKey: Uint8Array.from(atob(pub), c => c.charCodeAt(0)),
        privateKey: Uint8Array.from(atob(priv), c => c.charCodeAt(0)),
    };
};

export const getItem = (key: "solachat_pub" | "solachat_priv"): string | null => {
    return localStorage.getItem(key);
};
