import {Box, Typography, Link} from '@mui/joy';
import {motion} from 'framer-motion';
import {CustomDivider} from './CustomDivider';
import {GeneralQuestions} from './GeneralQuestions';
import React from "react";
import {PrivacyAndSecurityQuestions} from "@/components/faq/PrivacyAndSecurityQuestions";
import {UsingSolaChatQuestions} from "@/components/faq/UsingSolaChatQuestions";
import {EncryptionKeyQuestions} from "@/components/faq/EncryptionKeyQuestions";

const fadeIn = {
    hidden: {opacity: 0, y: 20},
    visible: {opacity: 1, y: 0, transition: {duration: 0.5}}
};

export const FAQSection = () => (
    <motion.div initial="hidden" animate="visible" variants={fadeIn}>
        <Box sx={{maxWidth: 800, mx: 'auto'}}>
            <Typography level="h2" sx={{
                color: '#00a8ff',
                fontSize: '1.5rem',
                mb: 4,
                fontWeight: 600,

            }}>
                General
            </Typography>

            <Box>
                <motion.div variants={fadeIn}>
                    <Link href="#what-is" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',

                        '&:hover': {color: '#007bff'}
                    }}>
                        • What is SolaChat?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#who-is-it-for" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Who is it for?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#how-is-it-different-from-web2" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • How is it different from Web2?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#why-was-solachat-created" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Why was SolaChat created?
                    </Link>
                </motion.div>
            </Box>
        </Box>

        <CustomDivider/>
        <Box sx={{maxWidth: 800, mx: 'auto'}}>
            <Typography level="h2" sx={{
                color: '#00a8ff',
                fontSize: '1.5rem',
                mb: 4,
                fontWeight: 600,

            }}>
                Privacy & Security
            </Typography>

            <Box>
                <motion.div variants={fadeIn}>
                    <Link href="#how-is-data-protected" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',

                        '&:hover': {color: '#007bff'}
                    }}>
                        • How is my data protected?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#who-can-read" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Who can read my messages?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#end-to-end" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Is SolaChat end-to-end encrypted?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#personal-data" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Do you collect any personal data?
                    </Link>
                </motion.div>
            </Box>
        </Box>

        <CustomDivider/>

        <Box sx={{maxWidth: 800, mx: 'auto'}}>
            <Typography level="h2" sx={{
                color: '#00a8ff',
                fontSize: '1.5rem',
                mb: 4,
                fontWeight: 600,

            }}>
                Using SolaChat
            </Typography>

            <Box>
                <motion.div variants={fadeIn}>
                    <Link href="#use-without-wallet" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',

                        '&:hover': {color: '#007bff'}
                    }}>
                        • Can I use SolaChat without a crypto wallet?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#multiple-devices" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • How do I use SolaChat on multiple devices?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#end-to-end" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • What happens if I lose my device or keys?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#available-platforms" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Is there a desktop or mobile version?
                    </Link>
                </motion.div>
            </Box>
        </Box>
        <CustomDivider/>

        <Box sx={{maxWidth: 800, mx: 'auto'}}>
            <Typography level="h2" sx={{
                color: '#00a8ff',
                fontSize: '1.5rem',
                mb: 4,
                fontWeight: 600,

            }}>
                Encryption & Keys
            </Typography>

            <Box>
                <motion.div variants={fadeIn}>
                    <Link href="#how-encryption-works" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',

                        '&:hover': {color: '#007bff'}
                    }}>
                        • How does encryption work in SolaChat?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#export-keys" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Can I export and import my encryption keys?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#why-transfer-keys" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • Why do I need to transfer my key manually?
                    </Link>
                </motion.div>
                <motion.div variants={fadeIn}>
                    <Link href="#what-if-key-leaked" sx={{
                        display: 'block',
                        fontSize: '1.1rem',
                        color: '#00a8ff',
                        '&:hover': {color: '#007bff'}
                    }}>
                        • What if someone gets my encryption key?
                    </Link>
                </motion.div>
            </Box>
        <CustomDivider/>

            <GeneralQuestions/>
            <CustomDivider/>
            <PrivacyAndSecurityQuestions/>
            <CustomDivider/>
            <UsingSolaChatQuestions/>
            <CustomDivider/>
            <EncryptionKeyQuestions/>
        </Box>
    </motion.div>
);
