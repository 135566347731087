const isDev = process.env.NODE_ENV === 'development';

export function logDev(...args: any[]) {
    if (isDev) {
        console.log('%c[DEV]', 'color: #00a8ff; font-weight: bold;', ...args);
    }
}

export const logger = {
    info: (...args: any[]) => {
        if (isDev) {
            console.info('%cℹ️ [INFO]', 'color: #a0d4ff; font-weight: bold;', ...args);
        }
    },
    warn: (...args: any[]) => {
        if (isDev) {
            console.warn('%c⚠️ [WARN]', 'color: orange; font-weight: bold;', ...args);
        }
    },
    error: (...args: any[]) => {
        console.error('%c❌ [ERROR]', 'color: red; font-weight: bold;', ...args);
    }
};
