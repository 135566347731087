import React from 'react';
import { Box, Typography } from '@mui/joy';
import { FAQItem } from './FAQItem';

export const GeneralQuestions = () => (
    <Box>
        <Typography level="h2" sx={{
            color: '#00a8ff',
            fontSize: '1.5rem',
            mb: 4,
            fontWeight: 600,
        }}>
            General Questions
        </Typography>
        <FAQItem id="what-is" question="Q: What is SolaChat?" answer={
            <>
                SolaChat is a decentralized messenger focused on privacy and data ownership. It's fast, simple,
                and doesn’t require a phone number or email. All conversations are encrypted, and only chat participants can read them.
            </>
        } />

        <FAQItem id="who-is-it-for" question="Q: Who is it for?" answer={
            <>
                SolaChat is for anyone who wants full control over their messages — Web3 users, crypto enthusiasts, activists,
                developers, or anyone who values freedom. You can use it on multiple devices, but to read encrypted messages,
                you need to manually transfer your encryption key.
            </>
        } />

        <FAQItem id="how-is-it-different-from-web2" question="Q: How is it different from Web2?" answer={
            <>
                Unlike Web2 messengers, SolaChat doesn’t rely on centralized servers and doesn’t require a phone number or email to sign up.
                Your data isn’t collected or shared with third parties. Thanks to decentralization, encryption, and wallet-based identity,
                only you control your personal information and conversations. It’s communication freedom — with no middlemen or censorship.
            </>
        } />
        <FAQItem id="why-was-solachat-created" question="Q: Why was SolaChat created?" answer={
            <>
                We’ve been witnessing for years how popular messengers and big tech companies abuse user trust — collecting data,
                analyzing conversations, collaborating with governments, and selling information to third parties.
                <br /><br />
                SolaChat was born as a protest against this. Our mission is digital freedom, privacy protection, and freedom of speech.
                We believe everyone has the right to communicate without fear of surveillance, censorship, or interference.
                SolaChat is a space where you truly own your data.
            </>
        } />
    </Box>
);
