import React from 'react';
import { Box, Typography } from '@mui/joy';
import { FAQItem } from './FAQItem';

export const PrivacyAndSecurityQuestions = () => (
    <Box>
        <Typography level="h2" sx={{
            color: '#00a8ff',
            fontSize: '1.5rem',
            mb: 4,
            fontWeight: 600,
        }}>
            Privacy and Security Questions
        </Typography>
        <FAQItem id="how-is-data-protected" question="Q: How is my data protected?" answer={
            <>
                SolaChat uses end-to-end encryption, meaning only you and the person you're talking to can read the messages.
                No servers, admins or third parties have access to your private conversations — not even us.
            </>
        } />

        <FAQItem id="who-can-read" question="Q: Who can read my messages?" answer={
            <>
                Only you and the recipient. Messages are encrypted on your device before being sent and decrypted only
                by the recipient using their private key. Even if someone intercepts the message, they won’t be able to read it.
            </>
        } />


        <FAQItem id="end-to-end" question="Q: Is SolaChat end-to-end encrypted?" answer={
            <>
                Yes. All messages in SolaChat are end-to-end encrypted by default. We don’t store your keys, and we
                can’t decrypt your messages. Your privacy is mathematically guaranteed.
            </>
        } />

        <FAQItem id="personal-data" question="Q: Do you collect any personal data?" answer={
            <>
                No. SolaChat does not require a phone number or email to use. We don’t collect any metadata,
                and your identity is tied only to your crypto wallet or encryption keys — not to your real name.
            </>
        } />
    </Box>
);
