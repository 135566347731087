import {
    Box,
    Typography,
    IconButton,
    Input,
    Avatar,
} from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import React, { useState } from 'react';
import GroupAvatarModal from '../modals/GroupAvatarModal';
import {createGroupChat} from "@/api";
import { useNavigate } from 'react-router-dom';
import {generateGroupKey} from "@/crypto/keygen";
import { encode as encodeBase64, decode as decodeBase64 } from "@stablelib/base64";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

interface SelectedUser {
    id: number;
    solachat_pub: string;
}

export default function CreateGroupScreen({
                                              users,
                                              onBack,
                                          }: {
    users: SelectedUser[];
    onBack: () => void;
}) {
    const [groupName, setGroupName] = useState('');
    const [groupAvatarBlob, setGroupAvatarBlob] = useState<Blob | null>(null);
    const [avatarModalOpen, setAvatarModalOpen] = useState(false);
    const navigate = useNavigate();

    console.log('👥 Пользователи для группы:', users.map(u => u.id));

    const handleCreateGroup = async () => {
        if (!groupName.trim()) return;

        try {
            const file = groupAvatarBlob
                ? new File([groupAvatarBlob], 'avatar.jpg', { type: 'image/jpeg' })
                : null;

            const groupKey = generateGroupKey();
            const groupKeyBase64 = encodeBase64(groupKey);

            // Из объектов пользователей выбираем только id
            const userIds = users.map((u) => u.id);

            const groupId = await createGroupChat(
                groupName.trim(),
                file,
                userIds,
                groupKeyBase64
            );

            if (groupId) {
                console.log('✅ Групповой чат создан, ID:', groupId);
                navigate(`/chat/#-${groupId}`);
            } else {
                console.warn('⚠️ Не удалось создать групповой чат');
            }
        } catch (error) {
            console.error('❌ Ошибка при создании группы:', error);
        }
    };



    return (
        <>
            <GroupAvatarModal
                open={avatarModalOpen}
                onClose={() => setAvatarModalOpen(false)}
                onSuccess={(blob) => {
                    setGroupAvatarBlob(blob);
                    setAvatarModalOpen(false);
                }}
            />

            <Box
                sx={{
                    height: '100dvh',
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'linear-gradient(180deg, #00162d 0%, #000d1a 100%)',
                    overflow: 'hidden',
                }}
            >
                {/* Header */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 2,
                        borderBottom: '1px solid rgba(0,168,255,0.3)',
                        background: 'rgba(0,22,45,0.9)',
                        backdropFilter: 'blur(12px)',
                    }}
                >
                    <IconButton onClick={onBack} sx={{ color: '#a0d4ff', mr: 2 }}>
                        <ArrowBackIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                    <Typography level="h4" sx={{ color: '#a0d4ff' }}>
                        Создать группу
                    </Typography>
                </Box>

                {/* Content */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        px: 4,
                        pt: 2,
                        gap: 4,
                    }}
                >
                    {/* Upload avatar */}
                    <Box
                        sx={{
                            width: 120,
                            height: 120,
                            borderRadius: '50%',
                            backgroundColor: '#003b5c',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            transition: '0.2s',
                            boxShadow: '0 0 0 0 transparent',
                            overflow: 'hidden',
                            '&:hover': {
                                backgroundColor: '#005c87',
                                boxShadow: '0 0 12px rgba(0, 168, 255, 0.3)',

                            },
                        }}
                        onClick={() => setAvatarModalOpen(true)}
                    >
                        {groupAvatarBlob ? (
                            <Avatar
                                src={URL.createObjectURL(groupAvatarBlob)}
                                sx={{ width: '100%', height: '100%' }}
                            />
                        ) : (
                            <AddAPhotoIcon sx={{ fontSize: 42 }} />
                        )}
                    </Box>

                    {/* Group name input */}
                    <Input
                        placeholder="Название группы"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        sx={{
                            width: '100%',
                            maxWidth: 460,
                            height: 54,
                            borderRadius: 'lg',
                            fontSize: 16,
                            background: 'transparent',
                            color: '#a0d4ff',
                            border: '1px solid rgba(0, 168, 255, 0.3)',
                            '&:hover': {
                                borderColor: '#00a8ff',
                            },
                            '&:focus-within': {
                                borderColor: '#00a8ff',
                                boxShadow: '0 0 0 2px rgba(0,168,255,0.1)',
                            },
                        }}
                    />
                </Box>

                {/* ➡️ Button */}
                <Box
                    onClick={handleCreateGroup}
                    sx={{
                        position: 'fixed',
                        bottom: 24,
                        left: 470,
                        zIndex: 99,
                        backgroundColor: 'rgba(0, 168, 255, 0.15)',
                        borderRadius: '50%',
                        padding: '12px',
                        backdropFilter: 'blur(6px)',
                        boxShadow: '0 4px 16px rgba(0, 168, 255, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <ArrowForwardIcon sx={{ fontSize: 24, color: '#00a8ff' }} />
                </Box>
            </Box>
        </>
    );
}
