import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/joy/Typography';
import { Box, CircularProgress, Input, List } from '@mui/joy';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ChatListItem from './ChatListItem';
import { ChatProps, MessageProps, UserProps } from '../core/types';
import { searchUsers, fetchChatsFromServer } from '@/api';
import { CssVarsProvider } from '@mui/joy/styles';
import Sidebar from '@/components/core/UI/Sidebar';
import IconButton from '@mui/joy/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsScreen from '../screen/SettingsScreen';
import { useEffect, useState, useRef } from "react";
import {AnimatePresence, motion } from 'framer-motion';
import LanguageScreen from "../screen/LanguageScreen";
import GeneralSettingsScreen from '../screen/GeneralSettingsScreen';
import EditProfileScreen from "../screen/EditProfileScreen";
import SessionScreen from "../screen/SessionScreen";
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import SpeakerIcon from '@mui/icons-material/Campaign';
import PersonIcon from '@mui/icons-material/Person';
import AddGroupScreen from '../screen/AddGroupScreen';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreateGroupScreen from "@/components/screen/CreateGroupScreen";
import {logger} from "@/utils/logger";
import EncryptionScreen from "@/components/screen/EncryptionIcon";



type ChatsPaneProps = {
    chats: ChatProps[];
    setSelectedChat: (chat: ChatProps) => void;
    selectedChatId: string;
    selectedChat: ChatProps | null;
    currentUser: { id: number };
    isConnecting: boolean;
};

interface SelectedUser {
    id: number;
    solachat_pub: string;
}


export default function ChatsPane({
                                      chats,
                                      setSelectedChat,
                                      selectedChatId,
                                      currentUser,
                                      selectedChat,
                                      isConnecting,
                                  }: ChatsPaneProps) {
    const {t} = useTranslation();
    const [localChats, setChats] = useState<ChatProps[]>(chats);
    const [isHoveringChatList, setIsHoveringChatList] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<
        (UserProps & { lastMessage?: MessageProps; chatId?: number })[]
    >([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    type Screen =
        | 'chats'
        | 'settings'
        | 'language'
        | 'general_settings'
        | 'edit_profile'
        | 'sessions'
        | 'add_group'
        | 'create_group'
        | 'encryption';

    const [activeScreen, setActiveScreen] = useState<Screen>('chats');


    const prevSelectedChatId = useRef<number>(-1);
    useEffect(() => {
        if (selectedChat && prevSelectedChatId.current !== selectedChat.id) {
            logger.info(`🔄 selectedChat обновлён в ChatsPane: ${selectedChat.id}`);
            prevSelectedChatId.current = selectedChat.id;
        }
    }, [selectedChat]);

    useEffect(() => {
        setChats(chats);
    }, [chats]);

    const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term.trim()) {
            try {
                const userResults = await searchUsers(term);
                const chatResults = chats
                    .map((chat) => {
                        const user = chat.users.find((user) => user.id !== currentUser.id);
                        if (!user) return null;
                        const lastMessage =
                            chat.lastMessage ??
                            (Array.isArray(chat.messages) && chat.messages.length > 0
                                ? chat.messages.reduce((prev, curr) =>
                                    new Date(curr.timestamp || curr.createdAt).getTime() > new Date(prev.timestamp || prev.createdAt).getTime()
                                        ? curr
                                        : prev
                                )
                                : null);
                        return {
                            ...user,
                            lastMessage,
                            chatId: chat.id,
                        };
                    })
                    .filter(Boolean) as (UserProps & { lastMessage?: MessageProps; chatId?: number })[];
                const mergedResults = Array.from(
                    new Map<string, UserProps & { lastMessage?: MessageProps; chatId?: number }>(
                        [...userResults, ...chatResults].map((user) => [user.id.toString(), user])
                    ).values()
                ).sort((a, b) => {
                    const timeA: number = a.lastMessage?.timestamp ? new Date(a.lastMessage.timestamp).getTime() : 0;
                    const timeB: number = b.lastMessage?.timestamp ? new Date(b.lastMessage.timestamp).getTime() : 0;
                    return timeB - timeA;
                });
                setSearchResults(mergedResults);
            } catch (error) {
                console.error("Ошибка поиска пользователей:", error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleCloseSettings = () => {
        setActiveScreen('chats');
        setIsSidebarOpen(false);
    };
    const [showMenu, setShowMenu] = useState(false);

    const headerHeight = 68;
    const borderStyle = '1px solid rgba(0, 168, 255, 0.3)';
    const gradientBorder = 'linear-gradient(90deg, transparent 0%, rgba(0, 168, 255, 0.4) 50%, transparent 100%)';
    const backdropStyles = {
        backgroundColor: 'rgba(0, 22, 45, 0.85)',
        backdropFilter: 'blur(20px)',
    };
    const shouldShowFloatingButton = isHoveringChatList || activeScreen === 'add_group';
    const isAddGroup = activeScreen === 'add_group';

    const [createGroupUsers, setCreateGroupUsers] = useState<SelectedUser[]>([]);



    return (
        <CssVarsProvider defaultMode="dark">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    maxWidth: "100%",
                    background: 'radial-gradient(circle at center, #0a192f 0%, #081428 100%)',
                    minHeight: '100vh',
                }}
                onMouseEnter={() => setIsHoveringChatList(true)}
                onMouseLeave={() => setIsHoveringChatList(false)}
            >
                <Box sx={{ position: 'relative', flex: 1, overflow: 'hidden' }}>
                    <AnimatePresence mode="wait">
                        {activeScreen === 'settings' && (
                            <motion.div
                                key="settings"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{
                                    position: 'absolute',
                                    inset: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'hidden',
                                }}
                            >
                                <SettingsScreen onBack={handleCloseSettings} />
                            </motion.div>
                        )}

                        {activeScreen === 'language' && (
                            <motion.div
                                key="language"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <LanguageScreen onBack={() => setActiveScreen('settings')} />
                            </motion.div>
                        )}

                        {activeScreen === 'general_settings' && (
                            <motion.div
                                key="general_settings"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <GeneralSettingsScreen onBack={() => setActiveScreen('settings')} />
                            </motion.div>
                        )}

                        {activeScreen === 'edit_profile' && (
                            <motion.div
                                key="edit_profile"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <EditProfileScreen onBack={() => setActiveScreen('settings')} />
                            </motion.div>
                        )}

                        {activeScreen === 'sessions' && (
                            <motion.div
                                key="sessions"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <SessionScreen sessions={[]} onBack={() => setActiveScreen('settings')} />
                            </motion.div>
                        )}

                        {activeScreen === 'add_group' && (
                            <motion.div
                                key="add_group"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <AddGroupScreen
                                    onBack={() => setActiveScreen('chats')}
                                    onNext={(selectedUsers) => {
                                        setCreateGroupUsers(selectedUsers);
                                        setActiveScreen('create_group');
                                    }}
                                />
                            </motion.div>
                        )}

                        {activeScreen === 'create_group' && (
                            <motion.div
                                key="create_group"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <CreateGroupScreen users={createGroupUsers} onBack={() => setActiveScreen('add_group')} />
                            </motion.div>
                        )}

                        {activeScreen === 'encryption' && (
                            <motion.div
                                key="encryption"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -20 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <EncryptionScreen onBack={() => setActiveScreen('settings')} />
                            </motion.div>
                        )}

                        {activeScreen === 'chats' && (
                            <motion.div
                                key="chats"
                                initial={{ opacity: 0, y: 16 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -16 }}
                                transition={{ duration: 0.1 }}
                                style={{ position: 'absolute', inset: 0 }}
                            >
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: {xs: '56px', sm: '64px', md: headerHeight},
                                            width: "100%",
                                            flexShrink: 0,
                                            borderBottom: borderStyle,
                                            position: 'relative',
                                            ...backdropStyles,
                                            '&:after': {
                                                content: '""',
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                height: '1px',
                                                background: gradientBorder,
                                            },
                                        }}
                                    >
                                        <IconButton
                                            component={motion.div}
                                            whileHover={{scale: 1.05}}
                                            sx={{
                                                mr: 2,
                                                ml: "16px",
                                                color: '#00a8ff',
                                                bgcolor: isSidebarOpen ? 'rgba(0, 168, 255, 0.1)' : 'transparent',
                                                '&:hover': {bgcolor: 'rgba(0, 168, 255, 0.2)'},
                                            }}
                                            onClick={() => {
                                                setIsSidebarOpen(!isSidebarOpen);
                                                setActiveScreen('chats');
                                            }}
                                        >
                                            <MenuIcon/>
                                        </IconButton>
                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                            <Input
                                                size="sm"
                                                startDecorator={
                                                    isConnecting ? (
                                                        <CircularProgress size="sm" sx={{color: '#00a8ff'}}/>
                                                    ) : (
                                                        <SearchRoundedIcon sx={{color: '#00a8ff'}}/>
                                                    )
                                                }
                                                onChange={handleSearchChange}
                                                value={searchTerm}
                                                aria-label={isConnecting ? "Connecting" : "Search"}
                                                placeholder={isConnecting ? t("Connecting") : t("Search")}
                                                sx={{
                                                    flex: 1,
                                                    maxWidth: {xs: '100%', sm: 600},
                                                    minWidth: {xs: 290, sm: 420},
                                                    height: {xs: 36, sm: 40},
                                                    fontSize: {xs: 14, sm: 16},
                                                    bgcolor: 'rgba(0, 168, 255, 0.05)',
                                                    borderColor: 'rgba(0, 168, 255, 0.3)',
                                                    color: '#a0d4ff',
                                                    '&:focus-within': {borderColor: '#00a8ff'},
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box
                                        component={motion.div}
                                        initial={false}

                                        animate={{
                                            maxHeight: isSidebarOpen ? "400px" : "0px",
                                            opacity: isSidebarOpen ? 1 : 0,
                                        }}
                                        transition={{duration: 0.3}}
                                        sx={{
                                            position: "absolute",
                                            top: "56px",
                                            left: 0,
                                            width: "auto",
                                            zIndex: 9,
                                            bgcolor: 'rgba(255, 255, 255, 0.05)',
                                            border: '1px solid rgba(0, 168, 255, 0.3)',
                                            boxShadow: '0 8px 32px rgba(0, 168, 255, 0.2)',
                                            borderRadius: 'lg',
                                            overflow: "hidden",
                                            ml: 2,
                                        }}
                                    >
                                        <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)}
                                                 setActiveScreen={setActiveScreen}/>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                            overflowY: "auto",
                                            maxWidth: "100%",
                                            pb: {xs: 1, sm: 3, md: 1},
                                            px: {xs: 1, sm: 3, md: 0},
                                        }}
                                    >
                                        {searchResults.length > 0 ? (
                                            <List sx={{gap: 1}}>
                                                {searchResults
                                                    .filter((user) => user.id !== currentUser.id)
                                                    .map((user) => (
                                                        <motion.div key={user.id} initial={{opacity: 0, y: 10}}
                                                                    animate={{opacity: 1, y: 0}}>
                                                            <ChatListItem
                                                                id={user.id.toString()}
                                                                sender={user}
                                                                messages={user.lastMessage ? [{...user.lastMessage}] : []}
                                                                setSelectedChat={setSelectedChat}
                                                                currentUserId={currentUser.id}
                                                                chats={chats}
                                                                setChats={setChats}
                                                            />
                                                        </motion.div>
                                                    ))}
                                            </List>
                                        ) : chats.length > 0 ? (
                                            <List sx={{gap: 1}}>
                                                {chats.map((chat) => (
                                                    <motion.div key={chat.id} initial={{opacity: 0, y: 10}}
                                                                animate={{opacity: 1, y: 0}}>
                                                        <ChatListItem
                                                            id={chat.id.toString()}
                                                            sender={chat.isGroup ? undefined : chat.users.find((user) => user.id !== currentUser.id)}
                                                            messages={chat.messages || []}
                                                            setSelectedChat={setSelectedChat}
                                                            currentUserId={currentUser.id}
                                                            chats={chats}
                                                            setChats={setChats}
                                                            selectedChatId={selectedChatId}
                                                            isGroup={chat.isGroup}
                                                        />
                                                    </motion.div>
                                                ))}
                                            </List>
                                        ) : (
                                            <Typography
                                                sx={{
                                                    textAlign: "center",
                                                    mt: 3,
                                                    color: '#a0d4ff',
                                                    background: 'linear-gradient(45deg, #00a8ff 30%, #007bff 90%)',
                                                    WebkitBackgroundClip: 'text',
                                                    WebkitTextFillColor: 'transparent',
                                                }}
                                            >
                                                {t('')}
                                            </Typography>
                                        )}
                                        <AnimatePresence>
                                            {isHoveringChatList && (
                                                <>
                                                    {showMenu && (
                                                        <motion.div
                                                            initial={{opacity: 0, y: 10}}
                                                            animate={{opacity: 1, y: 0}}
                                                            exit={{opacity: 0, y: 10}}
                                                            transition={{duration: 0.2}}
                                                            style={{
                                                                position: 'fixed',
                                                                bottom: 88,
                                                                left: 470,
                                                                zIndex: 100,
                                                                background: 'rgba(0, 168, 255, 0.15)',
                                                                backdropFilter: 'blur(6px)',
                                                                borderRadius: '12px',
                                                                padding: '8px 0',
                                                                boxShadow: '0 8px 24px rgba(0, 168, 255, 0.25)',
                                                                width: 200,
                                                                border: '1px solid rgba(0, 168, 255, 0.3)',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    px: 2,
                                                                    py: 1.5,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1.5,
                                                                    cursor: 'pointer',
                                                                    '&:hover': {background: 'rgba(0, 168, 255, 0.2)'},
                                                                }}
                                                            >
                                                                <SpeakerIcon sx={{fontSize: 18, color: '#a0d4ff'}}/>
                                                                <Typography sx={{color: '#a0d4ff', fontSize: 14}}>Создать
                                                                    канал</Typography>
                                                            </Box>

                                                            <Box
                                                                sx={{
                                                                    px: 2,
                                                                    py: 1.5,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1.5,
                                                                    cursor: 'pointer',
                                                                    '&:hover': {background: 'rgba(0, 168, 255, 0.2)'},
                                                                }}
                                                                onClick={() => {
                                                                    setShowMenu(false);
                                                                    setActiveScreen('add_group');
                                                                }}
                                                            >
                                                                <GroupIcon sx={{fontSize: 18, color: '#a0d4ff'}}/>
                                                                <Typography sx={{color: '#a0d4ff', fontSize: 14}}>Создать
                                                                    группу</Typography>
                                                            </Box>

                                                            <Box
                                                                sx={{
                                                                    px: 2,
                                                                    py: 1.5,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1.5,
                                                                    cursor: 'pointer',
                                                                    '&:hover': {background: 'rgba(0, 168, 255, 0.2)'},
                                                                }}
                                                            >
                                                                <PersonIcon sx={{fontSize: 18, color: '#a0d4ff'}}/>
                                                                <Typography sx={{color: '#a0d4ff', fontSize: 14}}>Новое
                                                                    сообщение</Typography>
                                                            </Box>
                                                        </motion.div>

                                                    )}

                                                    {/* Карандаш */}
                                                    <motion.div
                                                        initial={{opacity: 0, scale: 0.8}}
                                                        animate={{opacity: 1, scale: 1}}
                                                        exit={{opacity: 0, scale: 0.8}}
                                                        transition={{duration: 0.2}}
                                                        style={{
                                                            position: 'fixed',
                                                            bottom: 24,
                                                            left: 470,
                                                            zIndex: 99,
                                                        }}
                                                    >
                                                        <Box
                                                            onClick={() => {
                                                                if ((activeScreen as 'add_group' | 'chats') === 'add_group') {

                                                                    setActiveScreen('chats');
                                                                } else {
                                                                    setShowMenu((prev) => !prev);
                                                                }
                                                            }}
                                                            sx={{
                                                                backgroundColor: 'rgba(0, 168, 255, 0.15)',
                                                                borderRadius: '50%',
                                                                padding: '12px',
                                                                backdropFilter: 'blur(6px)',
                                                                boxShadow: '0 4px 16px rgba(0, 168, 255, 0.3)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            {(activeScreen as Screen) === 'add_group' ? (
                                                                <ArrowForwardIcon sx={{fontSize: 24, color: '#00a8ff'}}/>
                                                            ) : (
                                                                <EditIcon sx={{fontSize: 24, color: '#00a8ff'}}/>
                                                            )}
                                                        </Box>
                                                    </motion.div>

                                                </>
                                            )}
                                        </AnimatePresence>
                                    </Box>

                                </>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Box>
            </Box>
        </CssVarsProvider>
    );
}
