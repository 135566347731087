import {
    Box,
    Typography,
    IconButton,
    Input,
    Avatar,
    CircularProgress,
    Checkbox,
    Divider,
} from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { searchUsers } from '@/api';
import { UserProps } from '../core/types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface SelectedUser {
    id: number;
    solachat_pub: string;
}

export default function AddGroupScreen({
                                           onBack,
                                           onNext,
                                       }: {
    onBack: () => void;
    onNext: (users: SelectedUser[]) => void;
}) {

    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<UserProps[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UserProps[]>([]);
    const [loading, setLoading] = useState(false);

    const debouncedSearch = useCallback(
        debounce(async (term: string) => {
            if (term.trim()) {
                setLoading(true);
                const results = await searchUsers(term);
                setSearchResults(results);
                setLoading(false);
            } else {
                setSearchResults([]);
            }
        }, 300),
        []
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };

    const toggleUser = (user: UserProps) => {
        if (selectedUsers.find((u) => u.id === user.id)) {
            setSelectedUsers((prev) => prev.filter((u) => u.id !== user.id));
        } else {
            setSelectedUsers((prev) => [...prev, user]);
        }
    };


    const shortenKey = (key: string) => `${key.slice(0, 6)}...${key.slice(-4)}`;

    return (
        <Box
            sx={{
                height: '100dvh',
                display: 'flex',
                flexDirection: 'column',
                background: 'linear-gradient(180deg, #00162d 0%, #000d1a 100%)',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid rgba(0,168,255,0.3)',
                    background: 'rgba(0,22,45,0.9)',
                    backdropFilter: 'blur(12px)',
                }}
            >
                <IconButton onClick={onBack} sx={{ color: '#a0d4ff', mr: 2 }}>
                    <ArrowBackIcon sx={{ fontSize: 24 }} />
                </IconButton>
                <Typography level="h4" sx={{ color: '#a0d4ff' }}>
                    {t('addParticipants')}
                </Typography>
            </Box>

            <Box sx={{ px: 2, pt: 2 }}>
                <Input
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder={t('invitePrompt')}
                    startDecorator={
                        <>
                            {selectedUsers.map((user) => (
                                <Box
                                    key={user.id}
                                    onClick={() => toggleUser(user)}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        px: 1,
                                        py: 0.5,
                                        borderRadius: 'xl',
                                        mr: 1,
                                        cursor: 'pointer',
                                        transition: '0.2s',
                                        bgcolor: 'rgba(0,168,255,0.08)',
                                        color: '#a0d4ff',
                                        position: 'relative',
                                        '&:hover': {
                                            bgcolor: '#b91c1c',
                                            color: 'white',
                                        },
                                        '&:hover .avatar': { display: 'none' },
                                        '&:hover .cross': { display: 'flex' },
                                    }}
                                >
                                    <Avatar
                                        src={user.avatar}
                                        size="sm"
                                        sx={{ width: 30, height: 30, mr: 1 }}
                                        className="avatar"
                                    />

                                    <Box
                                        className="cross"
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            mr: 1,
                                            display: 'none',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <CloseIcon sx={{ fontSize: 18 }} />
                                    </Box>

                                    <Typography sx={{ fontSize: 16 }}>
                                        {user.username || `${user.public_key.slice(0, 6)}...${user.public_key.slice(-4)}`}
                                    </Typography>
                                </Box>
                            ))}
                        </>
                    }
                    sx={{
                        bgcolor: 'transparent',
                        border: 'none',
                        px: 0,
                        fontSize: 15,
                        color: '#a0d4ff',
                        flexWrap: 'wrap',
                        '&::before, &::after': {
                            display: 'none',
                        },
                        '&:focus-within': {
                            outline: 'none',
                            boxShadow: 'none',
                        },
                    }}
                />
                <Divider sx={{ backgroundColor: 'rgba(0,168,255,0.2)', mt: 1 }} />
            </Box>

            <Box sx={{ flex: 1, overflowY: 'auto', px: 2, pb: 2 }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <CircularProgress size="sm" sx={{ color: '#00a8ff' }} />
                    </Box>
                ) : (
                    searchResults.map((user) => {
                        const selected = selectedUsers.find((u) => u.id === user.id);
                        return (
                            <Box
                                key={user.id}
                                onClick={() => toggleUser(user)}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    p: 1.5,
                                    borderBottom: '1px solid rgba(0, 168, 255, 0.08)',
                                    cursor: 'pointer',
                                    backgroundColor: selected ? 'rgba(185, 28, 28, 0.08)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selected
                                            ? 'rgba(185, 28, 28, 0.12)'
                                            : 'rgba(0,168,255,0.05)',
                                    },
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.2 }}>
                                    <Avatar src={user.avatar} sx={{ width: 45, height: 45 }} />
                                    <Typography sx={{ color: '#a0d4ff', fontSize: 16 }}>
                                        {user.username || user.public_key}
                                    </Typography>
                                </Box>
                                <Checkbox
                                    checked={!!selected}
                                    readOnly
                                    variant="soft"
                                    color="primary"
                                />
                            </Box>
                        );
                    })
                )}
                <Box
                    onClick={() => {
                        if (selectedUsers.length > 0) {
                            onNext(
                                selectedUsers.map((u) => ({
                                    id: u.id,
                                    solachat_pub: u.solachat_pub,
                                }))
                            );
                        }
                    }}
                    sx={{
                        position: 'fixed',
                        bottom: 24,
                        left: 470,
                        zIndex: 99,
                        backgroundColor: 'rgba(0, 168, 255, 0.15)',
                        borderRadius: '50%',
                        padding: '12px',
                        backdropFilter: 'blur(6px)',
                        boxShadow: '0 4px 16px rgba(0, 168, 255, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: selectedUsers.length === 0 ? 'not-allowed' : 'pointer',
                        opacity: selectedUsers.length === 0 ? 0.4 : 1,
                        transition: 'all 0.2s ease-in-out',
                    }}
                >
                    <ArrowForwardIcon sx={{ fontSize: 24, color: '#00a8ff' }} />
                </Box>

            </Box>
        </Box>
    );
}
