const MEDIA_CACHE = "cached-media";

export const cacheMedia = async (mediaUrl: string, mediaBlob: Blob): Promise<void> => {
    const cache = await caches.open(MEDIA_CACHE);
    const response = new Response(mediaBlob, { headers: { "Content-Type": mediaBlob.type } });
    await cache.put(mediaUrl, response);
};

export const getCachedMedia = async (mediaUrl: string): Promise<Blob | null> => {
    const cache = await caches.open(MEDIA_CACHE);
    const cachedResponse = await cache.match(mediaUrl);
    if (cachedResponse) {
        return await cachedResponse.blob();
    }
    return null;
};

export const clearMediaCache = async (): Promise<void> => {
    await caches.delete(MEDIA_CACHE);
};
