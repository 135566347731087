import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export const sendMessage = async (chatId: number, formData: FormData, token: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/api/messages/${chatId}`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                validateStatus: (status) => status < 500,
            }
        );
        console.log("📥 typeof response.status:", typeof response.status, response.status);

        console.log("📥 Ответ от сервера (sendMessage):", response);

        return response;

    } catch (error) {
        console.error('❌ Ошибка отправки сообщения:', error);
        throw new Error('Could not send message');
    }
};


export const editMessage = async (messageId: number, content: string, token: string) => {
    try {
        const response = await axios.put(
            `${API_URL}/api/messages/${messageId}`,
            { content },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error editing message:', error);
        throw new Error('Could not edit message');
    }
};


export const deleteMessage = async (messageId: number) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Authorization token is missing');

        const response = await axios.delete(`${API_URL}/api/messages/messages/${messageId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error deleting message:', error);
        throw new Error('Could not delete message');
    }
};

export const updateMessageStatus = async (messageId: number, updates: { isRead: boolean }, token: string) => {
    try {
        const response = await axios.put(
            `${API_URL}/api/messages/${messageId}/read`,
            updates,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating message status:', error);
        throw error;
    }
};

export const pinMessage = async (messageId: number, token: string) => {
    try {
        const response = await axios.put(
            `${API_URL}/api/messages/${messageId}/pin`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error pinning message:', error);
        throw new Error('Could not pin message');
    }
};

export const unpinMessage = async (messageId: number, token: string) => {
    try {
        const response = await axios.put(
            `${API_URL}/api/messages/${messageId}/unpin`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error unpinning message:', error);
        throw new Error('Could not unpin message');
    }
};
