import React, { useEffect, useState } from 'react';
import { Box, Sheet, Typography, Button, Modal, ModalDialog, DialogTitle, DialogContent, DialogActions } from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyIcon from '@mui/icons-material/VpnKey';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const EncryptionScreen = ({ onBack }: { onBack: () => void }) => {
    const { t } = useTranslation();
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [publicKey, setPublicKey] = useState<string | null>(null);
    const [privateKey, setPrivateKey] = useState<string | null>(null);
    const [showResetModal, setShowResetModal] = useState(false);

    useEffect(() => {
        const sessionData = localStorage.getItem('session_id');
        const pub = localStorage.getItem('solachat_pub');
        const priv = localStorage.getItem('solachat_priv');
        setSessionId(sessionData);
        setPublicKey(pub);
        setPrivateKey(priv);
    }, []);

    const requestSignature = async (message: string) => {
        if (!(window as any).solana && !(window as any).ethereum) {
            alert('Phantom or MetaMask wallet not detected.');
            return false;
        }

        try {
            let signature;
            if ((window as any).solana?.isPhantom) {
                const msg = new TextEncoder().encode(message);
                signature = await (window as any).solana.signMessage(msg, 'utf8');
            } else if ((window as any).ethereum?.request) {
                const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
                const from = accounts[0];
                const hexMsg = '0x' + Buffer.from(message, 'utf8').toString('hex');
                signature = await (window as any).ethereum.request({
                    method: 'personal_sign',
                    params: [hexMsg, from],
                });
            }

            if (!signature) throw new Error('Signature denied');
            return true;
        } catch (err) {
            alert('❌ Signature rejected or failed.');
            return false;
        }
    };

    const handleExport = async () => {
        const confirmed = await requestSignature('Authorize key export');
        if (!confirmed) return;

        const data = JSON.stringify({ publicKey, privateKey });
        const blob = new Blob([data], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'solachat_keys.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleImport = async () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';
        input.onchange = (event: any) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const parsed = JSON.parse(reader.result as string);
                    if (parsed.publicKey && parsed.privateKey) {
                        localStorage.setItem('solachat_pub', parsed.publicKey);
                        localStorage.setItem('solachat_priv', parsed.privateKey);
                        setPublicKey(parsed.publicKey);
                        setPrivateKey(parsed.privateKey);
                        alert('✅ Keys imported successfully.');
                    }
                } catch (err) {
                    alert('❌ Invalid key file.');
                }
            };
            reader.readAsText(file);
        };
        input.click();
    };

    const confirmReset = async () => {
        setShowResetModal(false);
        const confirmed = await requestSignature('Authorize key reset');
        if (!confirmed) return;

        localStorage.removeItem('solachat_pub');
        localStorage.removeItem('solachat_priv');
        setPublicKey(null);
        setPrivateKey(null);
        alert('🔐 Keys have been reset.');
    };

    return (
        <Sheet
            sx={{
                height: '100dvh',
                background: 'linear-gradient(180deg, #00162d 0%, #000d1a 100%)',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                '&::-webkit-scrollbar': { display: 'none' }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid rgba(0,168,255,0.3)',
                    background: 'rgba(0,22,45,0.9)'
                }}
            >
                <IconButton onClick={onBack} sx={{ color: '#00a8ff', mr: 2 }}>
                    <ArrowBackIcon sx={{ fontSize: 24, color: '#a0d4ff' }} />
                </IconButton>
                <Typography level="h4" sx={{ color: '#a0d4ff', textShadow: '0 2px 4px rgba(0,168,255,0.3)' }}>
                    {t('encryption_details')}
                </Typography>
            </Box>

            <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Sheet sx={{ borderRadius: '16px', background: 'rgba(0,22,45,0.6)', border: '1px solid rgba(0,168,255,0.3)', p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <InfoIcon sx={{ color: '#00a8ff', mr: 2, fontSize: 32 }} />
                        <Typography sx={{ color: '#a0d4ff', fontSize: '1.2rem' }}>{t('how_encryption_works')}</Typography>
                    </Box>
                    <Typography sx={{ color: '#8ab4f8', fontSize: '0.95rem', mb: 1 }}>
                        {t('chacha20_description')}
                    </Typography>
                    <Typography sx={{ color: '#8ab4f8', fontSize: '0.95rem', mb: 1 }}>
                        {t('key_safety_warning')}
                    </Typography>
                    <Typography sx={{ color: '#8ab4f8', fontSize: '0.95rem' }}>
                        {t('key_usage_info')}
                    </Typography>
                </Sheet>

                <Sheet sx={{ borderRadius: '16px', background: 'rgba(0,22,45,0.6)', border: '1px solid rgba(0,168,255,0.3)', p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <KeyIcon sx={{ color: '#00a8ff', mr: 2, fontSize: 32 }} />
                        <Typography sx={{ color: '#a0d4ff', fontSize: '1.2rem' }}>{t('encryption_keys')}</Typography>
                    </Box>
                    <Typography sx={{ color: '#8ab4f8', fontSize: '0.9rem', wordBreak: 'break-all', mb: 1 }}>
                        Public Key: {publicKey || 'Not available'}
                    </Typography>
                    <Typography sx={{ color: '#8ab4f8', fontSize: '0.9rem', wordBreak: 'break-all' }}>
                        Private Key: {privateKey ? privateKey.substring(0, 16) + '...' : 'Not available'}
                    </Typography>
                </Sheet>

                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            onClick={handleExport}
                            variant="soft"
                            sx={{ flex: 1, color: '#a0d4ff', border: '1px solid rgba(0,168,255,0.3)', background: 'rgba(0,22,45,0.6)' }}
                        >
                            {t('export_keys')}
                        </Button>
                        <Button
                            onClick={handleImport}
                            variant="soft"
                            sx={{ flex: 1, color: '#a0d4ff', border: '1px solid rgba(0,168,255,0.3)', background: 'rgba(0,22,45,0.6)' }}
                        >
                            {t('import_keys')}
                        </Button>
                    </Box>
                    <Button
                        onClick={() => setShowResetModal(true)}
                        variant="solid"
                        color="danger"
                        sx={{ fontWeight: 600 }}
                    >
                        {t('reset_keys')}
                    </Button>
                </Box>
            </Box>

            <Modal open={showResetModal} onClose={() => setShowResetModal(false)}>
                <ModalDialog variant="soft" color="danger">
                    <DialogTitle>{t('confirm_reset_title')}</DialogTitle>
                    <DialogContent>
                        {t('confirm_reset_description')}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="plain" onClick={() => setShowResetModal(false)}>
                            {t('cancel')}
                        </Button>
                        <Button variant="solid" color="danger" onClick={confirmReset}>
                            {t('confirm')}
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </Sheet>
    );
};

export default EncryptionScreen;
