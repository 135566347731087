import { generateKeyPair, sharedKey } from "@stablelib/x25519";
import {randomBytes} from "@noble/hashes/utils";
import { encode as encodeBase64, decode as decodeBase64 } from "@stablelib/base64";
import {ChaCha20Poly1305} from "@stablelib/chacha20poly1305";


export const generateClientKeyPair = () => {
    const { publicKey, secretKey } = generateKeyPair();
    return {
        publicKey,
        privateKey: secretKey,
    };
};

export const generateGroupKey = (): Uint8Array => {
    return randomBytes(32);
};

export function computeSharedKey(
    adminPrivateKey: Uint8Array,
    userSolachatPubBase64: string
): Uint8Array {
    const userPublicKey = decodeBase64(userSolachatPubBase64);
    return sharedKey(adminPrivateKey, userPublicKey);
}

export function encryptGroupKeyForUser(
    groupKey: Uint8Array,
    adminPrivateKey: Uint8Array,
    userSolachatPubBase64: string
) {
    const sharedSecret = computeSharedKey(adminPrivateKey, userSolachatPubBase64);
    const aead = new ChaCha20Poly1305(sharedSecret);
    // Генерируем случайный nonce (обычно 12 байт для ChaCha20-Poly1305)
    const nonce = randomBytes(12);
    // Шифруем групповой ключ
    const ciphertext = aead.seal(nonce, groupKey);
    return {
        nonce: encodeBase64(nonce),
        ciphertext: encodeBase64(ciphertext)
    };
}

/**
 * Функция для дешифровки группового ключа на стороне пользователя.
 * Пользователь использует свой приватный ключ и публичный ключ администратора для вычисления общего секрета,
 * затем дешифрует зашифрованный групповой ключ.
 *
 * @param encryptedData - Объект с nonce и ciphertext (оба в base64).
 * @param userPrivateKey - Приватный ключ пользователя (Uint8Array).
 * @param adminPublicKeyBase64 - Публичный ключ администратора в формате base64.
 * @returns Расшифрованный групповой ключ (Uint8Array).
 */
export function decryptGroupKeyForUser(
    encryptedData: { nonce: string; ciphertext: string },
    userPrivateKey: Uint8Array,
    adminPublicKeyBase64: string
): Uint8Array {
    const adminPublicKey = decodeBase64(adminPublicKeyBase64);
    const sharedSecret = sharedKey(userPrivateKey, adminPublicKey);
    const aead = new ChaCha20Poly1305(sharedSecret);
    const nonce = decodeBase64(encryptedData.nonce);
    const ciphertext = decodeBase64(encryptedData.ciphertext);
    const plaintext = aead.open(nonce, ciphertext);
    if (plaintext === null) {
        throw new Error("Decryption failed");
    }
    return plaintext;
}
