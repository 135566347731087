import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@/theme/ThemeContext';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes/AppRoutes';

import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
    return (
        <HelmetProvider>
            <ThemeProvider>

                    <AppRoutes />

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                    />

            </ThemeProvider>
        </HelmetProvider>
    );
};

export default App;
