import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Modal, Box, IconButton, Slider, Stack, Button, Typography } from '@mui/joy';
import {
    Close as CloseIcon,
    RotateLeft as RotateLeftIcon,
    ZoomIn as ZoomInIcon,
    ZoomOut as ZoomOutIcon
} from '@mui/icons-material';
import Cropper from 'react-easy-crop';
import getCroppedImg from '@/utils/cropImage';

interface Props {
    open: boolean;
    onClose: () => void;
    onSuccess: (avatar: Blob | File) => void;
}

export default function GroupAvatarModal({ open, onClose, onSuccess }: Props) {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (open && !imageSrc) {
            setTimeout(() => fileInputRef.current?.click(), 100);
        }
    }, [open, imageSrc]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const onCropComplete = useCallback((_: any, area: any) => {
        setCroppedAreaPixels(area);
    }, []);

    const handleConfirm = async () => {
        if (!imageSrc || !croppedAreaPixels) return;
        const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
        const objectUrl = URL.createObjectURL(croppedBlob);
        onSuccess(croppedBlob);
        handleClose();
    };

    const handleClose = () => {
        setImageSrc(null);
        setZoom(1);
        setRotation(0);
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    backgroundColor: '#00162d',
                    border: '1px solid rgba(0,168,255,0.3)',
                    borderRadius: 'lg',
                    p: 3,
                    width: 460,
                    mx: 'auto',
                    mt: 8,
                    position: 'relative',
                    color: '#a0d4ff',
                }}
            >
                <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8, color: '#a0d4ff' }}>
                    <CloseIcon />
                </IconButton>

                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    hidden
                />

                {imageSrc && (
                    <>
                        <Typography
                            level="h4"
                            sx={{
                                mb: 2,
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: 18,
                                color: '#a0d4ff',
                            }}
                        >
                            Изображение можно перемещать
                        </Typography>

                        <Box sx={{ position: 'relative', width: '100%', height: 300 }}>
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                rotation={rotation}
                                aspect={1}
                                showGrid={false}
                                cropShape="round"
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                            />
                        </Box>

                        <Stack direction="row" spacing={1} alignItems="center" mt={2}>
                            <ZoomOutIcon sx={{ color: '#a0d4ff' }} />
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                onChange={(_, val) => setZoom(val as number)}
                                sx={{
                                    flex: 1,
                                    '& .MuiSlider-thumb': { backgroundColor: '#00a8ff' },
                                    '& .MuiSlider-track': { backgroundColor: 'rgba(0, 168, 255, 0.7)' },
                                }}
                            />
                            <ZoomInIcon sx={{ color: '#a0d4ff' }} />
                            <IconButton onClick={() => setRotation((r) => r + 90)} sx={{ color: '#00a8ff' }}>
                                <RotateLeftIcon />
                            </IconButton>
                        </Stack>

                        <Stack direction="row" justifyContent="flex-end" mt={3}>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                sx={{
                                    color: '#a0d4ff',
                                    borderColor: 'rgba(0,168,255,0.5)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0,168,255,0.05)',
                                        borderColor: '#00a8ff',
                                    },
                                }}
                            >
                                Отмена
                            </Button>
                            <Button
                                variant="solid"
                                onClick={handleConfirm}
                                sx={{
                                    ml: 2,
                                    backgroundColor: '#00a8ff',
                                    color: 'white',
                                    '&:hover': { backgroundColor: '#0080cc' },
                                }}
                            >
                                Готово
                            </Button>
                        </Stack>
                    </>
                )}
            </Box>
        </Modal>
    );
}
