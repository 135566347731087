import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/joy';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import { getAudioContext } from '@/context/audioContext';

interface CustomAudioPlayerProps {
    audioSrc: string;
}

const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({ audioSrc }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const sourceNodeRef = useRef<MediaElementAudioSourceNode | null>(null);
    const animationRef = useRef<number | null>(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const drawLiveWaveform = (
        analyser: AnalyserNode,
        dataArray: Uint8Array,
        bufferLength: number
    ) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const draw = () => {
            animationRef.current = requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.beginPath();
            const sliceWidth = canvas.width / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                const v = dataArray[i] / 128.0;
                const y = (v * canvas.height) / 2;

                if (i === 0) ctx.moveTo(x, y);
                else ctx.lineTo(x, y);

                x += sliceWidth;
            }

            ctx.lineTo(canvas.width, canvas.height / 2);
            ctx.strokeStyle = '#00a8ff';
            ctx.lineWidth = 2;
            ctx.stroke();
        };

        draw();
    };

    const stopDrawing = () => {
        if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
            animationRef.current = null;
        }
    };

    useEffect(() => {
        const audio = audioRef.current;
        if (!audio) return;

        const onLoadedMetadata = () => setDuration(audio.duration);
        const onTimeUpdate = () => setCurrentTime(audio.currentTime);
        const onEnded = () => {
            setIsPlaying(false);
            stopDrawing();
        };

        audio.addEventListener('loadedmetadata', onLoadedMetadata);
        audio.addEventListener('timeupdate', onTimeUpdate);
        audio.addEventListener('ended', onEnded);

        return () => {
            stopDrawing();
            audio.removeEventListener('loadedmetadata', onLoadedMetadata);
            audio.removeEventListener('timeupdate', onTimeUpdate);
            audio.removeEventListener('ended', onEnded);
        };
    }, []);

    const togglePlay = () => {
        const audio = audioRef.current;
        if (!audio) return;

        const context = getAudioContext();

        if (!sourceNodeRef.current) {
            const source = context.createMediaElementSource(audio);
            sourceNodeRef.current = source;
            const analyser = context.createAnalyser();
            source.connect(analyser);
            analyser.connect(context.destination);

            analyser.fftSize = 2048;
            const bufferLength = analyser.fftSize;
            const dataArray = new Uint8Array(bufferLength);

            drawLiveWaveform(analyser, dataArray, bufferLength);
        }

        if (isPlaying) {
            audio.pause();
            stopDrawing();
        } else {
            audio.play();
            const analyser = getAudioContext().createAnalyser();
            if (sourceNodeRef.current) {
                sourceNodeRef.current.connect(analyser);
                analyser.connect(getAudioContext().destination);
                analyser.fftSize = 2048;
                const dataArray = new Uint8Array(analyser.fftSize);
                drawLiveWaveform(analyser, dataArray, analyser.fftSize);
            }
        }

        setIsPlaying(!isPlaying);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                width: '100%',
                maxWidth: 420,
                height: 80,
                px: 1,
            }}
        >
            <audio ref={audioRef} src={audioSrc} preload="metadata" hidden />

            <IconButton
                onClick={togglePlay}
                sx={{
                    borderRadius: '50%',
                    width: 40,
                    height: 40,
                    bgcolor: 'white',
                    color: '#00a8ff',
                    flexShrink: 0,
                    ml: 1,
                    '&:hover': { bgcolor: '#f0f0f0' },
                }}
            >

                {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
            </IconButton>

            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <canvas
                    ref={canvasRef}
                    width={240}
                    height={40}
                    style={{
                        width: '100%',
                        height: 40,
                        backgroundColor: 'transparent',
                        display: 'block',
                    }}
                />
                <Typography
                    sx={{
                        fontSize: '13px',
                        color: 'white',
                        textAlign: 'left',
                        ml: 0.5,
                    }}
                >
                    {formatDuration(currentTime)}
                </Typography>
            </Box>
        </Box>
    );
};

export default CustomAudioPlayer;
