import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';

let lastFetchTime = 0;
let cachedStatus: any = null;
const MIN_INTERVAL_MS = 30_000; // 30 seconds

export const getServerStatus = async (): Promise<any> => {
    const now = Date.now();

    if (cachedStatus && now - lastFetchTime < MIN_INTERVAL_MS) {
        return cachedStatus;
    }

    try {
        const response = await axios.get(`${API_URL}/api/status`);
        cachedStatus = response.data;
        lastFetchTime = now;
        return cachedStatus;
    } catch (error) {
        console.error('Failed to fetch server status:', error);
        throw error;
    }
};
