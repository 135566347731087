import React from 'react';
import { Box, Typography } from '@mui/joy';
import { FAQItem } from './FAQItem';

export const UsingSolaChatQuestions = () => (
    <Box>
        <Typography level="h2" sx={{
            color: '#00a8ff',
            fontSize: '1.5rem',
            mb: 4,
            fontWeight: 600,
        }}>
            Using SolaChat Questions
        </Typography>
        <FAQItem id="use-without-wallet" question="Q: Can I use SolaChat without a crypto wallet?" answer={
            <>
                No, you can’t. Your public key from the crypto wallet acts as your main identifier in SolaChat.
                It’s used for end-to-end encryption, identity verification, and secure login.
                Without a wallet, you can’t create an account or send and receive messages.
            </>
        } />

        <FAQItem id="multiple-devices" question="Q: How do I use SolaChat on multiple devices?" answer={
            <>
                You can log in on multiple devices, but to access encrypted messages, you'll need to manually export and import
                your encryption keys. This keeps your data private and under your control.
            </>
        } />

        <FAQItem id="lost-keys" question="Q: What happens if I lose my device or keys?" answer={
            <>
                If you lose access to your keys and haven’t backed them up, encrypted messages will be permanently unreadable.
                We recommend exporting your keys and storing them in a safe place.
            </>
        } />

        <FAQItem id="available-platforms" question="Q: Is there a desktop or mobile version?" answer={
            <>
                Yes. SolaChat works in your browser and we’re also working on dedicated desktop and mobile apps
                for a more seamless experience.
            </>
        } />
    </Box>
);
