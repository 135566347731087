import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export const initiateCall = async (fromUserId: number | null, toUserId: number | null) => {
    try {
        const response = await axios.post(`${API_URL}/api/calls/initiate`, {
            fromUserId,
            toUserId,
        });
        return response.data;
    } catch (error) {
        console.error('Error initiating call:', error);
        throw error;
    }
};

export const endCall = async (fromUserId: number | null, toUserId: number | null, callId: number | null) => {
    try {
        const response = await axios.post(`${API_URL}/api/calls/reject`, {
            fromUserId,
            toUserId,
            callId,
        });
        return response.data;
    } catch (error) {
        console.error('Error cancelling call:', error);
        throw error;
    }
};

export const acceptCall = async (fromUserId: number | null, toUserId: number | null, callId: number | null) => {
    try {
        const response = await axios.post(`${API_URL}/api/calls/answer`, {
            fromUserId,
            toUserId,
            callId,
        });
        return response.data;
    } catch (error) {
        console.error('Error accepting call:', error);
        throw error;
    }
};

export const initiateGroupCall = async (fromUserId: number, participantUserIds: number[], token: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/api/calls/group/initiate`,
            {
                fromUserId,
                participantUserIds,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error initiating group call:', error);
        throw new Error('Could not initiate group call');
    }
};

export const rejectCall = async (fromUserId: number | null, toUserId: number | null, callId: number | null) => {
    try {
        const response = await axios.post(`${API_URL}/api/calls/reject`, {
            fromUserId,
            toUserId,
            callId,
        });
        return response.data;
    } catch (error) {
        console.error('Error rejecting call:', error);
        throw error;
    }
};
