import * as React from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import Sheet from '@mui/joy/Sheet';
import MessagesPane from './MessagesPane';
import ChatsPane from './ChatsPane';
import {ChatProps, MessageProps, UserProps} from '../core/types';
import {fetchChatsFromServer} from '@/api';
import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { JwtPayload } from 'jsonwebtoken';
import { jwtDecode } from 'jwt-decode';
import { useWebSocket } from '@/hooks/useWebSocket';
import PageTitle from './PageTitle';
import Box from "@mui/joy/Box";
import { useNavigate } from 'react-router-dom';
import {useEffect, useRef, useState} from "react";
import CallModal from './CallModal';
import {cacheChats, getCachedChats} from "@/cache/chatsCache";
import {cacheMedia, getCachedMedia} from "@/cache/cacheMedia";
import GlobalStyles from "@mui/joy/GlobalStyles";
import {loadAndCacheSessions} from "@/cache";
import {decryptMessage} from "@/crypto/encryption";
import { AnimatePresence, motion } from 'framer-motion';
import {sharedKey} from "@stablelib/x25519";
import { decode as decodeBase64 } from "@stablelib/base64";
import { isEncrypted } from "@/crypto/isEncrypted";
import {cacheDecryptedMessage, clearDecryptedMessagesExcept, getCachedDecryptedMessage} from "@/cache/decryptedCache";
import {logger} from "@/utils/logger";

export default function MyProfile() {
    const [chats, setChats] = React.useState<ChatProps[]>([]);
    const [selectedChat, setSelectedChat] = React.useState<ChatProps | null>(null);
    const [chatMessages, setChatMessages] = React.useState<MessageProps[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);
    const [currentUser, setCurrentUser] = React.useState<UserProps | null>(null);
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const [sessions, setSessions] = useState<any[]>([]);
    const location = useLocation();
    const selectedChatRef = useRef<ChatProps | null>(null); // выбранный чат
    const currentUserId = currentUser?.id; // id текущего пользователя
    const otherUserPublicKeyBase64 = selectedChatRef.current?.users?.find(u => u.id !== currentUserId)?.solachat_pub; // публичный ключ собеседника

    const navigate = useNavigate();

    const [callModalState, setCallModalState] = useState({
        open: false,
        fromUserId: null as number | null,
        fromUsername: null as string | null,
        fromAvatar: null as string | null,
        toUserId: null as number | null,
        toUsername: null as string | null,
        toAvatar: null as string | null,
        callId: null as number | null,
        status: null,
    });

    const getCurrentUserFromToken = (): UserProps | null => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('Authorization token is missing');
            return null;
        }

        try {
            const decodedToken = jwtDecode<JwtPayload>(token);
            return {
                id: decodedToken.id as number,
                public_key: decodedToken.publicKey as string,
                avatar: decodedToken.avatar as string,
                online: true,
                verified: false,
                lastOnline: new Date().toISOString(),
                role: 'member',
                solachat_pub: decodedToken.solachat_pub as string,
            };
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    const updateLastMessageInChatList = (chatId: number, newMessage: MessageProps) => {
        setChats((prevChats) => {
            return prevChats.map((chat) =>
                chat.id === chatId
                    ? { ...chat, lastMessage: newMessage }
                    : chat
            );
        });

        if (selectedChat?.id === chatId) {
            setSelectedChat((prev) =>
                prev ? { ...prev, lastMessage: newMessage } : prev
            );
        }
    };


    const removeUserFromChat = (chatId: number, userId: number) => {
        setChats((prevChats) =>
            prevChats.map((chat) =>
                chat.id === chatId
                    ? { ...chat, users: chat.users.filter(user => user.id !== userId) }
                    : chat
            )
        );
    };

    const updateRoleInChat = (chatId: number, userId: number, newRole: string) => {
        setChats((prevChats) =>
            prevChats.map((chat) =>
                chat.id === chatId
                    ? {
                        ...chat,
                        users: chat.users.map(user =>
                            user.id === userId ? { ...user, role: newRole } : user
                        ),
                    }
                    : chat
            )
        );
    };
    const chatDeletedRef = useRef(false);
    const scrollPositionsRef = useRef<Map<number, number>>(new Map());
    (window as any).savedScrollPositions = scrollPositionsRef.current;


    const removeChatFromList = (chatId: number) => {
        chatDeletedRef.current = true;

        setChats((prevChats) => prevChats.filter(chat => chat.id !== chatId));
        setSelectedChat(null)
        chatDeletedRef.current = false;

        if (selectedChat?.id === chatId) {
            setSelectedChat(null);
            navigate('/chat');
        }
    };


    useEffect(() => {
        if (!selectedChat) return;

        const foundChat = chats.find(chat => chat.id === selectedChat.id);
        if (!foundChat) {
            if (chatDeletedRef.current) {
                setSelectedChat(null);
                navigate('/chat');
            }
        } else if (foundChat !== selectedChat) {
            setSelectedChat(foundChat);
        }
    }, [chats, selectedChat, navigate]);



    const handleSessionDeletion = (sessionId: string) => {
        const token = localStorage.getItem('token');

        if (token) {
            try {
                const decodedToken = jwtDecode<{ sessionId?: string }>(token);
                if (decodedToken.sessionId === sessionId) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    return true;
                }
            } catch (error) {
                console.error('Token error:', error);
                localStorage.removeItem('token');
            }
        }
        return false;
    };

    const handleWebSocketMessage = (message: any) => {
        switch (message.type) {
            case 'session_deleted':
                handleSessionDeletion(message.sessionId);
                break;
            case 'newMessage':
                updateLastMessageInChatList(message.message.chatId, message.message);
                break;
            case 'userRemoved':
                removeUserFromChat(message.chatId, message.userId);
                break;
            case 'chatDeleted':
                removeChatFromList(message.chatId);
                break;
            case 'roleChange':
                updateRoleInChat(message.chatId, message.userId, message.newRole);
                break;
            case 'callOffer':
                if (message.toUserId === currentUser?.id) {
                    setCallModalState({
                        open: true,
                        fromUserId: message.fromUserId,
                        fromUsername: message.fromUsername,
                        fromAvatar: message.fromAvatar,
                        toUserId: message.toUserId,
                        toUsername: message.toUsername,
                        toAvatar: message.toAvatar,
                        callId: message.callId,
                        status: message.status,
                    });
                }
                break;
            case 'callRejected':
                setCallModalState({
                    open: false,
                    fromUserId: null,
                    fromUsername: null,
                    fromAvatar: null,
                    toUserId: null,
                    toUsername: null,
                    toAvatar: null,
                    callId: null,
                    status: null,
                });
                break;
        }
    };

    useWebSocket(handleWebSocketMessage);

    React.useEffect(() => {
        const user = getCurrentUserFromToken();
        if (user) {
            setCurrentUser(user);
        } else {
            setError('Failed to decode user from token');
        }
    }, []);

    const { wsRef, isConnecting, sendSecuLineMessage } = useWebSocket((data) => {

        if (data.type === 'chatCreated') {
            const newChat = data.chat;
            setChats((prevChats) => {
                if (prevChats.some((chat) => chat.id === newChat.id)) return prevChats;
                return [...prevChats, newChat].sort((a, b) => b.id - a.id);
            });

            if (selectedChat?.id === -1) {
                setSelectedChat(newChat);
            }
        }
        if (data.type === 'messagePinned' && data.message) {
            const pinnedMessage = data.message;

            setChats((prevChats) =>
                prevChats.map((chat) => {
                    if (chat.id !== pinnedMessage.chatId) return chat;

                    const updatedMessages = (chat.messages || []).map((msg) =>
                        msg.id === pinnedMessage.id
                            ? { ...msg, isPinned: true, pinnedAt: pinnedMessage.pinnedAt }
                            : msg
                    );

                    // если сообщения не было (например, оно пришло из другого устройства)
                    const alreadyExists = updatedMessages.some(msg => msg.id === pinnedMessage.id);
                    if (!alreadyExists) {
                        updatedMessages.push({
                            ...pinnedMessage,
                            isPinned: true,
                            pinnedAt: pinnedMessage.pinnedAt,
                            content: '[Encrypted]',
                        });
                    }

                    return {
                        ...chat,
                        messages: updatedMessages,
                    };
                })
            );
        }
        if (data.type === 'messageUnpinned' && data.message) {
            const unpinnedMessage = data.message;

            setChats((prevChats) =>
                prevChats.map((chat) => {
                    if (chat.id !== unpinnedMessage.chatId) return chat;

                    const updatedMessages = (chat.messages || []).map((msg) =>
                        msg.id === unpinnedMessage.id
                            ? { ...msg, isPinned: false, pinnedAt: undefined  }
                            : msg
                    );

                    return {
                        ...chat,
                        messages: updatedMessages,
                    };
                })
            );
        }


        if (data.type === 'chatDeleted') {
            setChats((prevChats) => prevChats.filter((chat) => chat.id !== data.chatId));
            navigate('/chat');
        }

        if (data.type === 'newMessage') {
            const newMessage = data.message;
            const myPrivKeyBase64 = localStorage.getItem("solachat_priv");

            try {
                const cleanBase64 =
                    typeof newMessage.content === "string"
                        ? newMessage.content.replace(/^"|"$/g, "")
                        : newMessage.content;


                const chat = chats.find(c => c.id === newMessage.chatId);
                logger.info("🔵 chat:", chat);

                if (newMessage.groupKey) {
                    const groupKey = decodeBase64(newMessage.groupKey);
                    logger.info("🟢 groupKey (from message):", groupKey);
                    newMessage.content = decryptMessage(cleanBase64, groupKey);

            } else if (myPrivKeyBase64) {
                    const myPrivKey = decodeBase64(myPrivKeyBase64);
                    const isMyMessage = newMessage.userId === currentUser?.id;
                    const theirPubBase64 = isMyMessage
                        ? chat?.users?.find(u => u.id !== currentUser?.id)?.solachat_pub
                        : newMessage.user?.solachat_pub;

                    if (!theirPubBase64) throw new Error("❌ Нет публичного ключа собеседника");

                    const theirPub = decodeBase64(theirPubBase64);
                    const sessionKey = sharedKey(myPrivKey, theirPub);
                    newMessage.content = decryptMessage(cleanBase64, sessionKey);
                }

            } catch (err) {
                logger.warn(`❌ Ошибка расшифровки сообщения (${newMessage.id}):`, err);
                newMessage.content = "[Encrypted]";
            }

            setChats((prevChats) => {
                let chatExists = false;

                const updatedChats = prevChats.map((chat) => {
                    if (chat.id !== newMessage.chatId) return chat;
                    chatExists = true;

                    const existingMsg = (chat.messages || []).find(
                        (msg) => msg.tempId && msg.tempId === newMessage.tempId
                    );

                    const finalMessage = existingMsg
                        ? {
                            ...existingMsg,
                            id: newMessage.id,
                            pending: false,
                            createdAt: newMessage.createdAt || existingMsg.createdAt || new Date().toISOString(),
                            attachment: newMessage.attachment,
                        }
                        : {
                            ...newMessage,
                            createdAt: newMessage.createdAt || new Date().toISOString(),
                        };

                    const updatedMessages = existingMsg
                        ? (chat.messages || []).map((msg) =>
                            msg.tempId === newMessage.tempId ? finalMessage : msg
                        )
                        : [...(chat.messages || []), finalMessage];

                    return {
                        ...chat,
                        messages: updatedMessages,
                        lastMessage: finalMessage,
                        attachment: newMessage.attachment,
                    };
                });

                if (!chatExists) {
                    updatedChats.push({
                        id: newMessage.chatId,
                        messages: [newMessage],
                        lastMessage: {
                            ...newMessage,
                            createdAt: newMessage.createdAt || new Date().toISOString(),
                        },
                        users: [newMessage.user],
                        user: newMessage.user,
                        isGroup: false,
                        otherUserPublicKeyBase64: newMessage.user?.public_key,
                    });
                }

                return updatedChats.sort((a, b) =>
                    new Date(b.lastMessage?.createdAt || b.lastMessage?.timestamp || 0).getTime() -
                    new Date(a.lastMessage?.createdAt || a.lastMessage?.timestamp || 0).getTime()
                );
            });
        }



        if (data.type === 'editMessage' && data.message) {
            const newMessage = data.message;
            const myPrivKeyBase64 = localStorage.getItem("solachat_priv");

            try {
                const cleanContent =
                    typeof newMessage.content === "string"
                        ? newMessage.content.replace(/^"|"$/g, "")
                        : newMessage.content;

                const chat = chats.find(c => c.id === newMessage.chatId);
                if (newMessage.groupKey) {
                    const groupKey = decodeBase64(newMessage.groupKey);
                    newMessage.content = decryptMessage(cleanContent, groupKey);
                }


                else if (myPrivKeyBase64) {
                    const isMyMessage = newMessage.userId === currentUser?.id || newMessage.user?.id === currentUser?.id;
                    const keyToUse = isMyMessage
                        ? newMessage.recipientPublicKeyBase64
                        : newMessage.user?.solachat_pub;

                    if (!keyToUse) throw new Error("❌ Нет публичного ключа для расшифровки");

                    const myPrivKey = decodeBase64(myPrivKeyBase64);
                    const pubKey = decodeBase64(keyToUse);
                    const sessionKey = sharedKey(myPrivKey, pubKey);
                    newMessage.content = decryptMessage(cleanContent, sessionKey);
                }
            } catch (err) {
                console.warn(`❌ Ошибка дешифровки editMessage (${newMessage.id}):`, err);
                newMessage.content = "[Encrypted]";
            }

            setChats((prevChats) =>
                prevChats.map(chat =>
                    chat.id === newMessage.chatId
                        ? {
                            ...chat,
                            messages: (chat.messages || []).map((msg: MessageProps) =>
                                msg.id === newMessage.id
                                    ? {
                                        ...msg,
                                        content: newMessage.content,
                                        isEdited: true,
                                        updatedAt: newMessage.updatedAt,
                                        pending: false,
                                    }
                                    : msg
                            ),
                            lastMessage: chat.lastMessage && chat.lastMessage.id === newMessage.id
                                ? {
                                    ...chat.lastMessage,
                                    content: newMessage.content,
                                    isEdited: true,
                                    updatedAt: newMessage.updatedAt,
                                    id: newMessage.id!,
                                }
                                : chat.lastMessage,
                        }
                        : chat
                )
            );
        }



        if (data.type === 'deleteMessage') {
            const { chatId, messageId } = data;
            setChats((prevChats) =>
                prevChats.map((chat) =>
                    chat.id === chatId
                        ? {
                            ...chat,
                            messages: (chat.messages || []).filter(msg => msg.id !== messageId),
                            lastMessage:
                                chat.lastMessage?.id === messageId ? undefined : chat.lastMessage,
                        }
                        : chat
                )
            );
        }

        if (data.type === 'messageRead') {
            const messageId = data.messageId;
            setChats((prevChats) =>
                prevChats.map((chat) => ({
                    ...chat,
                    messages: (chat.messages || []).map((msg) =>
                        msg.id === messageId ? { ...msg, isRead: true } : msg
                    ),
                    lastMessage:
                        chat.lastMessage && chat.lastMessage.id === messageId
                            ? { ...(chat.lastMessage as MessageProps), isRead: true }
                            : chat.lastMessage,
                }))
            );
        }

        if ((data.type === 'USER_CONNECTED' || data.type === 'USER_DISCONNECTED') && 'publicKey' in data) {
            const isOnline = data.type === 'USER_CONNECTED';
            const lastOnline = isOnline ? null : data.lastOnline;

            setChats((prevChats) =>
                prevChats.map((chat) => {
                    const userIndex = chat.users.findIndex(u => u.public_key === data.publicKey);
                    if (userIndex === -1) return chat;

                    const user = chat.users[userIndex];

                    if (user.online === isOnline && user.lastOnline === lastOnline) {
                        return chat;
                    }

                    const updatedUser = { ...user, online: isOnline, lastOnline };
                    const updatedUsers = [...chat.users];
                    updatedUsers[userIndex] = updatedUser;

                    return { ...chat, users: updatedUsers };
                })
            );
        }


    }, []);



    useEffect(() => {
        if (!currentUser) return;

        const token = localStorage.getItem("token");
        if (!token) {
            setError("Authorization token is missing");
            return;
        }

        const myPrivKeyBase64 = localStorage.getItem("solachat_priv");
        if (!myPrivKeyBase64) {
            console.error("❌ Приватный ключ не найден в localStorage");
            return;
        }

        const myPrivKey = decodeBase64(myPrivKeyBase64);

        const processAttachments = async (attachment: any): Promise<any> => {
            const processSingle = async (file: any) => {
                const originalUrl = file.cachedUrl || file.filePath;
                const normalizedUrl = originalUrl?.split("?")[0];

                if (!normalizedUrl) {
                    logger.warn("❌ У файла нет ссылки:", file);
                    return { ...file, filePath: null };
                }

                let blob = await getCachedMedia(normalizedUrl);

                if (!blob && navigator.onLine) {
                    try {
                        const res = await fetch(normalizedUrl);
                        if (!res.ok) throw new Error(`HTTP error: ${res.status}`);
                        blob = await res.blob();
                        await cacheMedia(normalizedUrl, blob);
                        logger.info("✅ Файл скачан и закеширован:", normalizedUrl);
                    } catch (err) {
                        console.warn("❌ Ошибка при скачивании:", normalizedUrl, err);
                    }
                }

                if (!blob) {
                    console.warn("❌ Нет файла ни в кэше, ни на сервере:", normalizedUrl);
                    return {
                        ...file,
                        filePath: null,
                        cachedUrl: normalizedUrl,
                    };
                }

                const blobUrl = URL.createObjectURL(blob);
                return {
                    ...file,
                    filePath: blobUrl,
                    cachedUrl: normalizedUrl,
                };
            };

            if (Array.isArray(attachment)) {
                return await Promise.all(attachment.map(processSingle));
            }

            return await processSingle(attachment);
        };

        const processChats = async (chats: any[]) => {
            return await Promise.all(
                chats.map(async (chat) => {
                    const updatedMessages = await Promise.all(
                        chat.messages.map(async (msg: any) => {
                            if (!msg) return null;

                            if (isEncrypted(msg.content)) {
                                const cachedDecrypted = await getCachedDecryptedMessage(msg.id);
                                if (cachedDecrypted) {
                                    msg.content = cachedDecrypted;
                                } else {
                                    try {
                                        let decryptedContent = '[Encrypted]';

                                        if (chat.isGroup && chat.groupKey) {
                                            const sessionKey = decodeBase64(chat.groupKey);
                                            decryptedContent = decryptMessage(msg.content, sessionKey);
                                        } else {
                                            const isMyMessage = msg.userId === currentUser.id;
                                            const peerPubKeyBase64 = isMyMessage
                                                ? chat.users.find((u: any) => u.id !== currentUser.id)?.solachat_pub
                                                : msg.user?.solachat_pub;

                                            if (!peerPubKeyBase64) throw new Error("❌ Нет публичного ключа собеседника");

                                            const peerPubKey = decodeBase64(peerPubKeyBase64);
                                            const sessionKey = sharedKey(myPrivKey, peerPubKey);
                                            decryptedContent = decryptMessage(msg.content, sessionKey);
                                        }

                                        msg.content = decryptedContent;
                                        await cacheDecryptedMessage(msg.id, decryptedContent);
                                    } catch (err) {
                                        console.warn(`❌ Ошибка расшифровки сообщения (${msg.id}):`, err);
                                        console.log("🔎 Проблемный content:", msg.content);
                                    }
                                }
                            }

                            if (msg.attachment !== undefined) {
                                msg.attachment = await processAttachments(msg.attachment);
                            }

                            return msg;
                        })
                    );

                    const filteredMessages = updatedMessages.filter((msg) => msg !== null);

                    const updatedUsers = await Promise.all(
                        chat.users.map(async (user: any) => {
                            if (!user.avatar) return user;

                            try {
                                const avatarOriginalUrl = user.originalAvatarUrl || user.avatar;
                                let blob: Blob | null = await getCachedMedia(avatarOriginalUrl);

                                if (!blob && navigator.onLine) {
                                    const res = await fetch(avatarOriginalUrl);
                                    if (!res.ok) throw new Error(`HTTP error: ${res.status}`);
                                    blob = await res.blob();
                                    await cacheMedia(avatarOriginalUrl, blob);
                                }

                                const blobUrl = blob ? URL.createObjectURL(blob) : null;

                                return {
                                    ...user,
                                    avatar: blobUrl || avatarOriginalUrl,
                                    originalAvatarUrl: avatarOriginalUrl,
                                };
                            } catch (err) {
                                console.warn(`❌ Ошибка загрузки аватарки: ${user.avatar}`, err);
                                return user;
                            }
                        })
                    );

                    const lastMessage = filteredMessages.at(-1) || null;

                    return {
                        ...chat,
                        messages: filteredMessages,
                        users: updatedUsers,
                        lastMessage,
                        isDecrypted: true,
                    };
                })
            );
        };

        const sortChats = (chats: any[]) => {
            return chats.sort((a, b) => {
                const getLastTime = (chat: any) =>
                    chat.lastMessage
                        ? new Date(chat.lastMessage.createdAt).getTime()
                        : chat.messages.length
                            ? new Date(chat.messages.at(-1).createdAt).getTime()
                            : 0;
                return getLastTime(b) - getLastTime(a);
            });
        };



        const loadChatsAndMessages = async () => {
            setLoading(true);
            setError(null);

            try {
                const initialHashChatId = Number(window.location.hash.replace(/^#-/, ''));
                const cachedChats = await getCachedChats().catch(() => []);
                const processedCached = await processChats(cachedChats || []);
                setChats(sortChats(processedCached));

                const serverChats = await fetchChatsFromServer(currentUser.id, currentUser.public_key, token);
                const processedServer = await processChats(serverChats);

                await cacheChats(processedServer);
                const allMessageIds = processedServer.flatMap(chat =>
                    chat.messages.map((msg: MessageProps) => msg.id)
                );
                await clearDecryptedMessagesExcept(allMessageIds);

                setChats(sortChats(processedServer));

                if (initialHashChatId) {
                    const targetChat = processedServer.find(c => c.id === initialHashChatId);
                    if (targetChat) {
                        setSelectedChat(targetChat);
                        navigate(`/chat/#-${targetChat.id}`, { replace: true });
                    }
                }

            } catch (err) {
                console.error("🚨 Chat loading failed", err);

                if (!cacheChats || cacheChats.length === 0) {
                    setError(t('error.loadingChats'));
                }

        } finally {
                setLoading(false);
            }
        };


        const mergeChats = (existing: ChatProps[], updated: ChatProps[]) => {
            const merged = [...existing];

            updated.forEach(newChat => {
                const index = merged.findIndex(c => c.id === newChat.id);
                if (index === -1) {
                    merged.push(newChat);
                } else {
                    merged[index] = {
                        ...newChat,
                        messages: mergeMessages(merged[index].messages, newChat.messages)
                    };
                }
            });

            return merged;
        };

        const mergeMessages = (existing: MessageProps[], updated: MessageProps[]) => {
            const messageMap = new Map<number, MessageProps>();

            [...existing, ...updated].forEach(msg => {
                if (msg.id) messageMap.set(msg.id, msg);
                if (msg.tempId) messageMap.set(msg.tempId, msg);
            });

            return Array.from(messageMap.values()).sort((a, b) =>
                new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
        };


        loadChatsAndMessages();

    }, [currentUser]);



    const handleSendMessage = (message: MessageProps) => {
        const createdAt = message.createdAt || new Date().toISOString();

        setChats((prevChats) => {
            const updated = prevChats.map((chat) => {
                if (chat.id === message.chatId) {
                    const updatedMessages = [...(chat.messages || []), { ...message, createdAt }];
                    return {
                        ...chat,
                        messages: updatedMessages,
                        lastMessage: { ...message, createdAt },
                    };
                }
                return chat;
            });

            const getLastTime = (chat: ChatProps) => {
                const date = chat.lastMessage?.createdAt || chat.lastMessage?.timestamp;
                return date ? new Date(date).getTime() : 0;
            };

            return updated.sort((a, b) => getLastTime(b) - getLastTime(a));
        });
    };

    const handleEditMessage = (messageId: number, content: string) => {
        const updatedMessages = chatMessages.map((msg: MessageProps) =>
            msg.id === messageId
                ? { ...msg, content: content, isEdited: true, pending: false }
                : msg
        );

        setChatMessages([...updatedMessages]);

        if (sendSecuLineMessage && currentUserId && otherUserPublicKeyBase64) {
            sendSecuLineMessage(
                {
                    id: messageId,
                    user_id: currentUserId,
                    chat_id: selectedChatRef.current?.id || 0,
                    content: content,
                    recipientPublicKeyBase64: otherUserPublicKeyBase64,
                    attachment: [],
                },
                selectedChatRef.current?.id || 0,
                7
            );
        }
    };

    useEffect(() => {
        const hash = location.hash;

        if (!hash) {
            setSelectedChat(null);
            return;
        }

        if (hash.startsWith('#-')) {
            const groupChatId = Number(hash.replace('#-', ''));
            const chat = chats.find(c => c.isGroup && c.id === groupChatId);
            if (chat) {
                setSelectedChat(prev => (prev?.id !== chat.id ? chat : { ...chat }));
            }

        } else {
            const rawId = hash.replace('#', '').replace('_pinned', '');
            const recipientId = Number(rawId);

            if (!Number.isNaN(recipientId)) {
                let chat = chats.find(c =>
                    !c.isGroup && c.users.some(user => user.id === recipientId)
                );

                if (chat) {
                    console.log("🟢 Приватный чат найден по recipientId:", chat);
                    setSelectedChat(chat);
                } else {
                    const user = chats.flatMap(c => c.users).find(u => u.id === recipientId);

                    if (user) {
                        const tempChat: ChatProps = {
                            id: -1,
                            users: [user],
                            messages: [],
                            isGroup: false,
                            user,
                            otherUserPublicKeyBase64: user.solachat_pub,
                        };
                        console.warn("⚠️ Приватный чат не найден. Создаём временный чат:", tempChat);
                        setSelectedChat(tempChat);
                    } else {
                        console.error("❌ Пользователь не найден в списке:", recipientId);
                        setSelectedChat(null);
                    }
                }
            } else {
                console.error("❌ Некорректный recipientId в hash:", hash);
            }
        }

    }, [location.hash, chats]);

    const GlobalStyle = () => (
        <GlobalStyles
            styles={{
                '@keyframes glow': {
                    '0%, 100%': { opacity: 0.6 },
                    '50%': { opacity: 1 }
                }
            }}
        />
    );

    return (
        <>
            <GlobalStyle />
            <PageTitle
                title={
                    selectedChat
                        ? selectedChat.isGroup
                            ? selectedChat.name ?? 'Unnamed Group'
                            : selectedChat.users?.find(user => user.id !== currentUser?.id)?.public_key ?? 'Unnamed User'
                        : 'Messenger'
                }
            />

            <Sheet
                sx={{
                    flex: 1,
                    width: '100%',
                    mx: 'auto',
                    height: '100dvh',
                    pt: { xs: 'var(--Header-height)', sm: 0 },
                    display: 'grid',
                    overflow: 'hidden',
                    gridTemplateColumns: {
                        xs: selectedChat ? '0fr 1fr' : '1fr',
                        sm: selectedChat ? '0.5fr 1fr' : '1fr',
                        md: 'minmax(min-content, 550px) 1fr',
                    },
                    background: 'radial-gradient(circle at center, #0a192f 0%, #081428 100%)',
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        left: { sm: '250px', md: '550px' },
                        top: 0,
                        bottom: 0,
                        width: '1px',
                        background: `
              linear-gradient(
                180deg,
                transparent 0%,
                rgba(0, 168, 255, 0.6) 50%,
                transparent 100%
              )`,
                        boxShadow: '0 0 20px rgba(0, 168, 255, 0.3)',
                        zIndex: 999,
                        animation: 'glow 2s ease-in-out infinite',
                    }
                }}
            >
                {currentUser ? (
                    <>
                        <ChatsPane
                            chats={chats}
                            selectedChat={selectedChat}
                            selectedChatId={selectedChat ? String(selectedChat.id) : ''}
                            setSelectedChat={(chat: ChatProps) => {
                                setSelectedChat(chat);
                                navigate(`/chat/#-${chat.id}`);
                            }}
                            currentUser={currentUser}
                            isConnecting={isConnecting}
                        />

                        <Sheet
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                position: {
                                    xs: selectedChat ? 'absolute' : 'relative',
                                    sm: 'relative'
                                },
                                background: 'radial-gradient(circle at center, #0a192f 0%, #081428 100%)',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                transition: 'all 0.3s ease-in-out',

                            }}
                        >
                            {error ? (
                                <Typography sx={{ textAlign: 'center', color: 'red' }}>{error}</Typography>
                            ) : loading ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                                </Box>
                            ) : selectedChat ? (
                                <MessagesPane
                                    chat={selectedChat}
                                    chats={chats}
                                    selectedChat={selectedChat}
                                    members={selectedChat?.users || []}
                                    setSelectedChat={setSelectedChat}
                                    setChats={setChats}
                                    onSendMessage={handleSendMessage}
                                    onEditMessage={handleEditMessage}
                                />

                            ) : (
                                <MessagesPane chat={null} onEditMessage={handleEditMessage} setChats={setChats}   onSendMessage={handleSendMessage}  selectedChat={selectedChat}  chats={chats} setSelectedChat={setSelectedChat}   />
                            )}
                        </Sheet>
                    </>
                ) : (
                    <Typography>{t('loadingUserInformation')}</Typography>
                )}

                {callModalState.open && (
                    <CallModal
                        open={callModalState.open}
                        onClose={() => setCallModalState({
                            open: false,
                            fromUserId: null,
                            fromUsername: null,
                            fromAvatar: null,
                            toUserId: null,
                            toUsername: null,
                            toAvatar: null,
                            callId: null,
                            status: null
                        })}
                        sender={{
                            id: callModalState.fromUserId!,
                            username: callModalState.fromUsername || 'User',
                            avatar: callModalState.fromAvatar || 'avatar.png',
                            online: true,
                            role: 'member',
                        }}
                        receiver={{
                            id: callModalState.toUserId ?? currentUser?.id!,
                            username: callModalState.toUsername || currentUser?.username || 'User',
                            avatar: callModalState.toAvatar || currentUser?.avatar || 'avatar.png',
                        }}
                        currentUserId={currentUser?.id || null}
                        ws={null}
                        callId={callModalState.callId || null}
                        status={callModalState.status || 'incoming'}
                    />
                )}
            </Sheet>
        </>
    );
}
