import React, { useState, useMemo } from "react";
import {
    Box,
    Typography,
    Avatar,
    IconButton,
    Sheet,
    Stack,
    Button, Menu, MenuItem,
} from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/VpnKey";
import {AnimatePresence, motion} from "framer-motion";
import ImageViewer from "./ImageViewer";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { TFunction } from "i18next";
import {useTranslation} from "react-i18next";
import Verified from "@mui/icons-material/Verified";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ChatEditPanel from "@/components/screen/ChatEditPanel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MemberScreen from "@/components/screen/MemberScreen";
import AddMemberScreen from "@/components/screen/AddMemberScreen";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import RemoveUserModal from "@/components/modals/RemoveUserModal";
import {removeUserFromChat} from "@/api";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ClickAwayListener } from "@mui/material";


interface ChatInfoPanelProps {
    profile: {
        id: number;
        avatar: string;
        username?: string;
        publicKey: string;
        bio?: string;
        verified?: boolean;
        isGroup?: boolean;
        members?: Array<{
            id: number;
            avatar: string;
            username: string;
            publicKey: string;
            online: boolean;
            lastOnline: string;
            role?: string;
        }>;
    };
    messages: any[];
    onSave: (data: { name: string; avatar?: File }) => void | Promise<void>;
    onClose: () => void;
}

const getMemberLabel = (count: number, locale: string = 'en') => {
    if (locale === 'ru') {
        if (count % 10 === 1 && count % 100 !== 11) return 'участник';
        if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20))
            return 'участника';
        return 'участников';
    }
    return count === 1 ? 'member' : 'members';
};


const formatTimeAgo = (timestamp: number, t: TFunction, i18n: any) => {
    const now = Date.now();
    const diff = now - timestamp;
    const date = new Date(timestamp);
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(diff / 3600000);

    if (diff < 60000) return t("just_now");

    if (new Date(timestamp).toDateString() === new Date().toDateString()) {
        if (minutes < 60) return t("minutes_ago", { count: minutes });
        return t("hours_ago", { count: hours });
    }

    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);
    if (date.toDateString() === yesterday.toDateString()) {
        const timeString = date.toLocaleTimeString(i18n.language, {
            hour: "2-digit",
            minute: "2-digit"
        });
        return t("last_seen_yesterday", { time: timeString });
    }

    return date.toLocaleDateString(i18n.language, {
        day: "numeric",
        month: "long",
        year: "numeric"
    });
};


const ChatInfoPanel: React.FC<ChatInfoPanelProps> = ({ profile, messages, onClose, onSave }) => {
    const [selectedTab, setSelectedTab] = useState("");

    React.useEffect(() => {
        if (!selectedTab) {
            const defaultTab = profile.isGroup
                ? "members"
                : "media";
            setSelectedTab(defaultTab);
        }
    }, [profile.isGroup, selectedTab]);

    const [viewerOpen, setViewerOpen] = useState(false);
    const [initialIndex, setInitialIndex] = useState<number>(0);
    const { t, i18n } = useTranslation();
    const [activeScreen, setActiveScreen] = useState<'info' | 'edit' | 'members' | 'add-members'>('info');
    const navigate = useNavigate();
    const [contextMenuAnchor, setContextMenuAnchor] = useState<null | HTMLElement>(null);
    type MemberType = NonNullable<typeof profile.members>[number];
    const [selectedUser, setSelectedUser] = useState<MemberType | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);



    const imageList = useMemo(() =>
            messages
                .filter(msg => Array.isArray(msg.attachment))
                .flatMap(msg =>
                    msg.attachment
                        .filter((f: { fileType: string }) => f.fileType.startsWith("image"))
                        .map((f: { filePath: string }) => f.filePath)
                ),
        [messages]
    );
    const token = localStorage.getItem('token');
    let currentUserPublicKey = '';

    if (token) {
        try {
            const payloadBase64 = token.split('.')[1];
            const payload = JSON.parse(atob(payloadBase64));
            currentUserPublicKey = payload.publicKey;
        } catch (e) {
            console.error('Error decoded JWT', e);
        }
    }

    const currentUser = profile.members?.find(
        member => member.publicKey === currentUserPublicKey
    );


    const userRole = currentUser?.role;
    const isEditable = userRole === 'admin' || userRole === 'moderator' || userRole === 'owner';

    const handleUserClick = (userId: number) => {
        navigate(`/chat/#${userId}`);
        onClose();
    };

    const handleThumbnailClick = (src: string) => {
        const index = imageList.findIndex(i => i === src);
        if (index !== -1) {
            setInitialIndex(index);
            setViewerOpen(true);
        }
    };

    const formatLocalizedDate = (rawDate: string | Date, t: TFunction): string => {
        const date = new Date(rawDate);
        const now = new Date();

        const isToday = date.toDateString() === now.toDateString();
        const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === date.toDateString();

        const locale = i18n.language === "ru" ? "ru-RU" : "en-US";

        const time = date.toLocaleTimeString(locale, {
            hour: "2-digit",
            minute: "2-digit",
        });

        if (isToday) return t("atTime", { date: t("today"), time });
        if (isYesterday) return t("atTime", { date: t("yesterday"), time });

        const formattedDate = date.toLocaleDateString(locale, {
            day: "numeric",
            month: "short",
        });

        return t("atTime", { date: formattedDate, time });
    };
    const isContextSelected = selectedUser?.id === profile.id && contextMenuAnchor;


    React.useEffect(() => {
        if (viewerOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [viewerOpen]);

    const formatTimeAgo = (timestamp: number): string => {
        const now = new Date();
        const date = new Date(timestamp);
        const diff = now.getTime() - date.getTime();
        const minutes = Math.floor(diff / 60000);
        const hours = Math.floor(diff / 3600000);

        if (diff < 60000) return t("just_now");

        if (date.toDateString() === now.toDateString()) {
            if (minutes < 60) return t("minutes_ago", { count: minutes });
            return t("hours_ago", { count: hours });
        }

        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        if (date.toDateString() === yesterday.toDateString()) {
            const timeString = date.toLocaleTimeString(i18n.language, { hour: "2-digit", minute: "2-digit" });
            return t("last_seen_yesterday", { time: timeString });
        }

        return date.toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "long",
            year: "numeric"
        });
    };

    const formattedDateTooltip = new Date().toLocaleString(i18n.language, {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
    });

    const handleCreateGroupChat = () => {
        console.log('TODO: реализация createGroupChat');
    };

    const handleRemoveFromChat = () => {
        console.log('TODO: реализация удаления из чата');
    };

    const handleAddMembers = () => {
        setActiveScreen('add-members');
    };

    return (
        <>
            <Box position="relative" sx={{ overflow: 'hidden', height: '100%' }}>
            {
                activeScreen === 'edit' ? (
                    <motion.div
                        key="edit"
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.25 }}
                        style={{ position: 'absolute', inset: 0 }}
                    >
                        <ChatEditPanel
                            initialAvatar={profile.avatar}
                            initialName={profile.username || ''}
                            members={profile.members || []}
                            onSave={async (data) => {
                                await onSave(data);
                                setActiveScreen('info');
                            }}
                            onBack={() => setActiveScreen('info')}
                            onAvatarClick={() => console.log('Change group avatar')}
                            onMembersClick={() => setActiveScreen('members')}
                        />
                    </motion.div>
                ) : activeScreen === 'members' ? (
                    <motion.div
                        key="members"
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.25 }}
                        style={{ position: 'absolute', inset: 0 }}
                    >
                        <MemberScreen
                            members={profile.members || []}
                            onBack={() => setActiveScreen('edit')}
                        />
                    </motion.div>
                ) : activeScreen === 'add-members' ? (
                    <motion.div
                        key="add-members"
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.25 }}
                        style={{ position: 'absolute', inset: 0 }}
                    >
                        <AddMemberScreen
                            chatId={profile.id}
                            existingMemberIds={(profile.members || []).map(m => m.id)}
                            onBack={() => setActiveScreen('info')}
                            onAddMembers={(users) => {
                                console.log('Добавленные пользователи:', users);
                                setActiveScreen('members');
                            }}
                        />
                    </motion.div>
                ) : (
        <Box
            sx={{
                height: "100dvh",
                display: "flex",
                flexDirection: "column",
                background: "linear-gradient(180deg, #00162d 0%, #000d1a 100%)",
                borderLeft: "1px solid rgba(0, 168, 255, 0.3)",
                overflowY: "auto",
                '&::-webkit-scrollbar': { display: 'none' },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 2,
                    borderBottom: "1px solid rgba(0,168,255,0.3)",
                    background: "rgba(0,22,45,0.9)",
                    backdropFilter: "blur(12px)",
                }}
            >
                <IconButton onClick={onClose} sx={{ color: '#00a8ff', mr: 2 }}>
                    <CloseIcon sx={{ fontSize: 24, color: '#a0d4ff' }} />
                </IconButton>
                <Typography level="h4" sx={{ color: "#a0d4ff", flexGrow: 1 }}>
                    {t('information')}
                </Typography>
                {isEditable && activeScreen === 'info' && (
                    <IconButton
                        onClick={() => setActiveScreen('edit')}
                        sx={{ color: '#00a8ff' }}
                    >
                        <EditIcon sx={{ fontSize: 24, color: '#a0d4ff' }} />
                    </IconButton>
                )}
            </Box>

            <Box sx={{ position: "relative", borderBottom: "1px solid rgba(0,168,255,0.3)", flex: "0 0 30%" }}>
                <Avatar
                    src={profile.avatar}
                    sx={{ width: "100%", height: "100%", borderRadius: 0, objectFit: "cover" }}
                />
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: "linear-gradient(transparent 0%, rgba(0,22,45,0.9) 100%)",
                        p: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#00a8ff",
                                fontSize: "1.3rem",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {profile.username || profile.publicKey}
                        </Typography>
                        {profile.verified && (
                            <Verified
                                sx={{
                                    ml: 1,
                                    color: "#00a8ff",
                                    fontSize: "1.5rem",
                                }}
                            />
                        )}
                    </Box>
                    {profile.isGroup ? (
                        <Typography sx={{
                            color: "#a0d4ff",
                            fontSize: "0.9rem",
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5
                        }}>
                            {profile.members?.length} {getMemberLabel(profile.members?.length || 0, i18n.language)}
                        </Typography>
                    ) : profile.bio && (
                        <Typography sx={{
                            color: "#a0d4ff",
                            fontSize: "0.9rem",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                        }}>
                            {profile.bio}
                        </Typography>
                    )}
                </Box>

            </Box>

            {!profile.isGroup && (
                <Box sx={{ p: 2 }}>
                    <Sheet sx={{
                        borderRadius: "10px",
                        background: "rgba(0,22,45,0.6)",
                        border: "1px solid rgba(0,168,255,0.3)",
                        p: 2,
                    }}
                    >
                        <Stack direction="row" alignItems="center">
                            <KeyIcon sx={{ color: "#00a8ff", mr: 1, fontSize: 28 }} />
                            <Box>
                                <Typography sx={{ color: "#a0d4ff", fontSize: "0.9rem", mb: 0.5 }}>
                                    {t('public_key')}
                                </Typography>
                                <Typography sx={{
                                    color: "#8ab4f8",
                                    wordBreak: "break-all",
                                    fontSize: "0.8rem",
                                    lineHeight: 1.4
                                }}>
                                    {profile.publicKey}
                                </Typography>
                            </Box>
                        </Stack>
                    </Sheet>
                </Box>
            )}

            {profile.username && (
                <Box sx={{ p: 2 }}>
                    <Sheet
                        sx={{
                            borderRadius: "10px",
                            background: "rgba(0,22,45,0.6)",
                            border: "1px solid rgba(0,168,255,0.3)",
                            p: 2,
                        }}
                    >
                        <Stack direction="row" alignItems="center">
                            <AlternateEmailIcon sx={{ color: "#00a8ff", mr: 1, fontSize: 28 }} />
                            <Box>
                                <Typography sx={{ color: "#a0d4ff", fontSize: "0.9rem", mb: 0.5 }}>Username</Typography>
                                <Typography sx={{ color: "#8ab4f8", wordBreak: "break-all", fontSize: "0.8rem", lineHeight: 1.4 }}>
                                    {profile.username}
                                </Typography>
                            </Box>
                        </Stack>
                    </Sheet>
                </Box>
            )}

            <Stack direction="row" spacing={0} sx={{ px: 3 }}>
                {(profile.isGroup
                        ? ["members", "media", "files", "links"]
                        : ["media", "files", "links"]
                ).map((tab) => {
                    const isActive = selectedTab === tab;
                    return (
                        <Button
                            key={tab}
                            variant="plain"
                            onClick={() => setSelectedTab(tab)}
                            sx={{
                                textTransform: 'none',
                                borderRadius: 0,
                                flex: 1,
                                justifyContent: 'center',
                                fontWeight: isActive ? 600 : 400,
                                borderBottom: isActive ? '2px solid #00a8ff' : '2px solid transparent',
                                color: isActive ? '#00a8ff' : '#a0d4ff',
                                transition: 'all 0.2s ease-in-out',
                                py: 1.5,
                                minWidth: 0,
                                borderTopLeftRadius: '8px',
                                borderTopRightRadius: '8px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 168, 255, 0.05)',
                                    borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px',
                                },
                            }}
                        >
                            {t(`tabs.${tab}`)}
                        </Button>
                    );
                })}
            </Stack>

            <Box sx={{ flex: 1 }}>
                {selectedTab === "members" && profile.isGroup && (
                    <Stack spacing={1} sx={{ p: 2 }}>
                        {profile.members?.map((member) => (
                            <Sheet
                                key={member.id}
                                onClick={(e) => {
                                    if (contextMenuAnchor) {
                                        e.preventDefault();
                                        return;
                                    }
                                    handleUserClick(member.id);
                                }}

                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    if (!isEditable || member.role === 'owner') return;
                                    setSelectedUser(member);
                                    setContextMenuAnchor(e.currentTarget);
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: 1,
                                    p: 1,
                                    borderRadius: 'md',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.2s ease',
                                    bgcolor: isContextSelected ? 'rgba(0, 168, 255, 0.1)' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(0, 168, 255, 0.15)',
                                    },
                                }}

                            >
                                <Avatar
                                    src={member.avatar}
                                    sx={{ width: { xs: 35, sm: 40 }, height: { xs: 35, sm: 40 } }}
                                />
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ color: '#a0d4ff' }}>
                                        {member.username || `${member.publicKey.slice(0, 4)}...${member.publicKey.slice(-4)}`}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: '#8ab4f8',
                                            fontSize: '0.75rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                        }}
                                    >
                                        {member.online
                                            ? t('online')
                                            : formatTimeAgo(new Date(member.lastOnline).getTime())}
                                    </Typography>
                                </Box>
                                {member.role && member.role !== "member" && (
                                    <Typography
                                        sx={{
                                            color: '#00a8ff',
                                            fontSize: '0.8rem',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {t(member.role)}
                                    </Typography>
                                )}
                            </Sheet>
                        ))}
                    </Stack>
                )}


            <Box>
                {selectedTab === "media" && (
                    imageList.length === 0 ? (
                        <Typography sx={{ color: '#888', mt: 2 }}></Typography>
                    ) : (
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', flexShrink: 0 }}>
                        {imageList.map((src: string, index: number) => (
                                <Box
                                    key={index}
                                    sx={{ width: '100%', aspectRatio: '1 / 1', overflow: 'hidden', cursor: 'pointer' }}
                                    onClick={() => handleThumbnailClick(src)}
                                >
                                    <img src={src} alt="media" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>
                            ))}
                        </Box>
                    )
                )}
                {selectedTab === "files" &&
                    messages
                        .filter((msg) =>
                            Array.isArray(msg.attachment) &&
                            msg.attachment.some((file: { fileType: string }) => !file.fileType.startsWith("image"))
                        )
                        .flatMap((msg) =>
                            msg.attachment
                                .filter((file: { fileType: string }) => !file.fileType.startsWith("image"))
                                .map((file: { filePath: string, fileName: string, fileSize: number, createdAt: string }, index: number) => (
                                    <Box
                                        key={`${msg.id}-${index}`}
                                        sx={{
                                            width: '100%',
                                            p: '8px',
                                            maxWidth: '300px',
                                            mb: 1,
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={1.5}
                                            sx={{
                                                alignItems: 'center',
                                                width: '100%',
                                                position: 'relative',
                                                textDecoration: 'none',
                                            }}
                                            component="a"
                                            href={file.filePath}
                                            download={file.fileName}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    flexShrink: 0,
                                                    width: '48px',
                                                    height: '48px',
                                                    cursor: 'pointer',
                                                    '&:hover .download-icon': {
                                                        opacity: 1,
                                                        visibility: 'visible',
                                                    },
                                                    '&:hover .file-type': {
                                                        opacity: 0,
                                                        visibility: 'hidden',
                                                    },
                                                }}
                                            >
                                                <InsertDriveFileRoundedIcon
                                                    sx={{
                                                        fontSize: '48px',
                                                        color: '#a0d4ff',
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                />

                                                <Typography
                                                    className="file-type"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        fontSize: '14px',
                                                        fontWeight: '700',
                                                        color: 'white',
                                                        textShadow: '0 1px 3px rgba(0, 0, 0, 0.4)',
                                                        transition: 'all 0.2s ease',
                                                        textTransform: 'uppercase',
                                                        pointerEvents: 'none',
                                                        userSelect: 'none',
                                                    }}
                                                >
                                                    {(() => {
                                                        const ext = file.fileName.split('.').pop()?.toUpperCase();
                                                        return ext && ext.length > 5 ? ext.slice(0, 4) + '…' : ext;
                                                    })()}
                                                </Typography>

                                                <DownloadIcon
                                                    className="download-icon"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        fontSize: '24px',
                                                        opacity: 0,
                                                        visibility: 'hidden',
                                                        color: 'white',
                                                        transition: 'all 0.2s ease',
                                                        bgcolor: 'rgba(0, 0, 0, 0.3)',
                                                        borderRadius: '50%',
                                                        p: '4px',
                                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                                                        pointerEvents: 'none',
                                                    }}
                                                />
                                            </Box>

                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '13px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        color: '#a0d4ff',
                                                        pl: 1,
                                                        pr: 1,
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    {file.fileName}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '11px',
                                                        color: '#a0d4ff',
                                                        pl: 1,
                                                        pr: 1,
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    {formatLocalizedDate(file.createdAt, t)}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                ))
                        )}
                {selectedTab === "links" &&
                    messages
                        .filter((msg) =>
                            typeof msg.content === "string" &&
                            msg.content.startsWith("http")
                        )
                        .map((msg, index) => {
                            const url = msg.content.trim();
                            let hostname = "";
                            try {
                                hostname = new URL(url).hostname;
                            } catch (e) {
                                return null;
                            }

                            const favicon = `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=64`;

                            const title = hostname.replace("www.", "").split(".")[0];

                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        gap: 1,
                                        mb: 1,
                                        px: 2,
                                        py: 1,
                                        borderBottom: '1px solid rgba(0,168,255,0.1)',
                                    }}
                                >
                                    <Avatar
                                        src={favicon}
                                        variant="soft"
                                        sx={{
                                            width: 36,
                                            height: 36,
                                            borderRadius: 8,
                                            flexShrink: 0,
                                        }}
                                    />
                                    <Box sx={{  minWidth: 0 }}>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: '#a0d4ff',
                                                fontWeight: 500,
                                                mb: 0.5,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {title.charAt(0).toUpperCase() + title.slice(1)}
                                        </Typography>


                                        <Typography
                                            component="a"
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontSize: '12px',
                                                color: '#4ab3ff',
                                                textDecoration: 'none',
                                                wordBreak: 'break-all',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                },
                                            }}
                                        >
                                            {url}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
            </Box>
            </Box>
            {profile.isGroup && selectedTab === 'members' && isEditable && (
                <Box
                    onClick={handleAddMembers}
                    sx={{
                        position: 'fixed',
                        bottom: 24,
                        right: 24,
                        zIndex: 999,
                        backgroundColor: 'rgba(0, 168, 255, 0.15)',
                        borderRadius: '50%',
                        padding: '12px',
                        backdropFilter: 'blur(6px)',
                        boxShadow: '0 4px 16px rgba(0, 168, 255, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out',
                    }}
                >
                    <PersonAddAlt1Icon sx={{ fontSize: 24, color: '#00a8ff' }} />
                </Box>
            )}

            <AnimatePresence>
                <ImageViewer
                    open={viewerOpen}
                    imageSrcList={imageList}
                    initialIndex={initialIndex}
                    senderAvatar={profile.avatar}
                    senderPublicKey={profile.publicKey}
                    date={formattedDateTooltip}
                    onClose={() => setViewerOpen(false)}
                />
            </AnimatePresence>
        </Box>
            )}
                {contextMenuAnchor && (
                    <ClickAwayListener onClickAway={() => setContextMenuAnchor(null)}>
                        <Menu
                            open={Boolean(contextMenuAnchor)}
                            anchorEl={contextMenuAnchor}
                            onClose={() => setContextMenuAnchor(null)}
                            placement="bottom-start"
                            disablePortal
                            sx={{
                                zIndex: 1500,
                                p: 0.5,
                                borderRadius: 'md',
                                bgcolor: 'rgba(0, 22, 45, 0.95)',
                                boxShadow: '0 8px 24px rgba(0, 168, 255, 0.25)',
                                backdropFilter: 'blur(12px)',
                                border: '1px solid rgba(0, 168, 255, 0.2)',
                                minWidth: 180,
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setShowConfirmModal(true);
                                    setContextMenuAnchor(null);
                                }}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    px: 2,
                                    py: 1.2,
                                    fontSize: 14,

                                    borderRadius: 'sm',
                                    '&:hover': {
                                        bgcolor: 'rgba(255, 77, 79, 0.08)',
                                    },
                                }}
                            >
                                <DeleteOutlineIcon sx={{ fontSize: 18 }} />
                                {t('remove_from_group')}
                            </MenuItem>
                        </Menu>
                    </ClickAwayListener>
                )}


                <RemoveUserModal
                open={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                username={selectedUser?.username}
                publicKey={selectedUser?.publicKey}
                onConfirm={async () => {
                    if (!selectedUser) return;
                    try {
                        const token = localStorage.getItem('token');
                        if (!token) throw new Error('No token found');
                        await removeUserFromChat(profile.id, selectedUser.id, token);
                        if (profile.members) {
                            const index = profile.members.findIndex(u => u.id === selectedUser.id);
                            if (index !== -1) {
                                profile.members.splice(index, 1);
                            }
                        }

                        setShowConfirmModal(false);
                    } catch (err) {
                        console.error('Ошибка при удалении пользователя', err);
                    }
                }}
            />
            </Box>
        </>
    );
};

export default ChatInfoPanel;
