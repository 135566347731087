import React from 'react';
import { Box, IconButton, Sheet, Stack, Typography, Avatar } from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Member {
    id: number;
    avatar: string;
    username: string;
    publicKey: string;
    online: boolean;
    lastOnline: string;
    role?: string;
}

interface MemberScreenProps {
    members: Member[];
    onBack: () => void;
}

const formatTimeAgo = (timestamp: number): string => {
    const diff = Date.now() - timestamp;
    const minutes = Math.floor(diff / 60000);
    if (minutes < 1) return 'только что';
    if (minutes < 60) return `${minutes} мин назад`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} ч назад`;
    const days = Math.floor(hours / 24);
    return `${days} дн назад`;
};

const MemberScreen: React.FC<MemberScreenProps> = ({ members, onBack }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleUserClick = (userId: number) => {
        navigate(`/chat/#${userId}`);
    };

    return (
        <Box
            sx={{
                height: '100dvh',
                display: 'flex',
                flexDirection: 'column',
                background: 'linear-gradient(180deg, #00162d 0%, #000d1a 100%)',
                overflow: 'auto',
                '&::-webkit-scrollbar': { display: 'none' }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid rgba(0,168,255,0.3)',
                    background: 'rgba(0,22,45,0.9)',
                    backdropFilter: 'blur(12px)',
                }}
            >
                <IconButton onClick={onBack} sx={{ color: '#a0d4ff', mr: 2 }}>
                    <ArrowBackIcon sx={{ fontSize: 24 }} />
                </IconButton>
                <Typography level="h4" sx={{ color: '#a0d4ff', flexGrow: 1 }}>
                    {t('members')}
                </Typography>
            </Box>

            <Stack spacing={1} sx={{ p: 2 }}>
                {members.map((member) => (
                    <Sheet
                        key={member.id}
                        onClick={() => handleUserClick(member.id)}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 1,
                            p: 1,
                            borderRadius: 'md',
                            cursor: 'pointer',
                            bgcolor: 'transparent',
                            '&:hover': { bgcolor: 'rgba(0, 168, 255, 0.1)' },
                        }}
                    >
                        <Avatar
                            src={member.avatar}
                            sx={{
                                width: { xs: 35, sm: 40 },
                                height: { xs: 35, sm: 40 },
                            }}
                        />
                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ color: '#a0d4ff' }}>
                                {member.publicKey.slice(0, 4)}...{member.publicKey.slice(-4)}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#8ab4f8',
                                    fontSize: '0.75rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5,
                                }}
                            >
                                {member.online
                                    ? t('online')
                                    : formatTimeAgo(new Date(member.lastOnline).getTime())}
                            </Typography>
                        </Box>
                    </Sheet>
                ))}
            </Stack>
        </Box>
    );
};

export default MemberScreen;
