import { useEffect, useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { updateUserStatus } from '@/api';
import { jwtDecode } from 'jwt-decode';
import { encode as msgpackEncode } from "@msgpack/msgpack";
import { encryptMessage } from "@/crypto/encryption";
import { sharedKey } from "@stablelib/x25519";
import { decode as decodeBase64 } from "@stablelib/base64";
import { getItem } from "@/crypto/keyStore";

const WS_URL = process.env.WS_URL || 'ws://localhost:4005';
const SECULINE_URL = 'ws://localhost:8080';

const RECONNECT_INTERVAL = 3000;
const HEARTBEAT_INTERVAL = 120000;

export type AttachmentBinary = {
    fileName: string;
    fileType: string;
    data: Uint8Array;
};


export type SecuPayload = {
    user_id: number;
    chat_id: number;
    content: string;
    recipientPublicKeyBase64?: string;
    tempId?: number;
    groupKey?: string;
    isGroup?: boolean;
    id?: number;
    attachment?: AttachmentBinary[];
    user?: {
        id: number;
        solachat_pub: string;
    };
};


export const useWebSocket = (onMessage: (message: any) => void, dependencies: any[] = []) => {
    const wsRef = useRef<WebSocket | null>(null);
    const seculineRef = useRef<WebSocket | null>(null);

    const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);
    const heartbeatInterval = useRef<NodeJS.Timeout | null>(null);

    const [isConnected, setIsConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(true);
    const [currentUserId, setCurrentUserId] = useState<number | null>(null);

    const myPrivateKeyBase64 = getItem("solachat_priv");
    const myPublicKeyBase64 = getItem("solachat_pub");

    useEffect(() => {
        const ws = new WebSocket(SECULINE_URL);
        seculineRef.current = ws;

        ws.onopen = () => {
            console.log('🟢 SecuLine connected');

            const solachatPub = localStorage.getItem("solachat_pub");

            if (!solachatPub) {
                console.error("❌ Нет публичного ключа (solachat_pub) в localStorage");
                return;
            }

            const publicKeyBytes = Uint8Array.from(atob(solachatPub), c => c.charCodeAt(0));

            if (publicKeyBytes.length !== 32) {
                console.error("❌ Неверная длина публичного ключа:", publicKeyBytes.length);
                return;
            }

            const token = localStorage.getItem("token");

            if (!token) {
                console.error(" Token not found in localStorage");
                return;
            }

            const sessionInitPacket = {
                type: 'initSession',
                client_public_key: Array.from(publicKeyBytes),
                timestamp: Math.floor(Date.now() / 1000),
                token,
            };

            try {
                ws.send(JSON.stringify(sessionInitPacket));
                console.log("🔐 SessionInitPacket отправлен:", sessionInitPacket);
            } catch (err) {
                console.error("❌ Ошибка при отправке SessionInitPacket:", err);
            }
        };

        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'ackMessage') {
                    console.log("📥 ACK получен от SecuLine:", data);
                    onMessage(data);
                }
            } catch (e) {
                console.warn('⚠️ Unrecognized SecuLine message', event.data);
            }
        };

        ws.onerror = (err) => {
            console.error('❌ SecuLine WebSocket error:', err);
        };

        ws.onclose = () => {
            console.warn('🔌 SecuLine WebSocket closed');
        };

        return () => {
            ws.close();
        };
    }, []);

    const sendHeartbeat = useCallback(() => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ type: 'heartbeat' }));
            console.log('Heartbeat sent');
        }
    }, []);


    const connectWebSocket = useCallback(() => {
        if (isConnected || wsRef.current) return;

        setIsConnecting(true);

        const token = localStorage.getItem('token');
        if (!token) {
            toast.error('Authorization token is missing');
            setIsConnecting(false);
            return;
        }

        const ws = new WebSocket(`${WS_URL.replace(/^http/, 'ws')}/ws?token=${token}`);
        wsRef.current = ws;

        ws.onopen = () => {
            console.log('Express WebSocket opened');
            setIsConnected(true);
            setIsConnecting(false);

            if (reconnectTimeout.current) {
                clearTimeout(reconnectTimeout.current);
                reconnectTimeout.current = null;
            }

            if (!heartbeatInterval.current) {
                heartbeatInterval.current = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
            }


        };

        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);

            if (message.targetUserId && message.targetUserId !== currentUserId) return;

            if (message.type === 'chatCreated') {
                const currentUserInChat = message.chat.users.some((user: { id: number }) => user.id === currentUserId);
                if (!currentUserInChat) return;
            }

            onMessage(message);
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = (event) => {
            console.error('WebSocket closed', event.code, event.reason);
            setIsConnected(false);
            setIsConnecting(false);
            if (heartbeatInterval.current) {
                clearInterval(heartbeatInterval.current);
                heartbeatInterval.current = null;
            }

            wsRef.current = null;
            handleReconnection();
        };
    }, [onMessage, sendHeartbeat, isConnected, currentUserId]);

    const handleReconnection = useCallback(() => {
        if (!reconnectTimeout.current) {
            reconnectTimeout.current = setTimeout(() => {
                console.log('Reconnecting...');
                connectWebSocket();
            }, RECONNECT_INTERVAL);
        }
    }, [connectWebSocket]);

    const sendSecuLineMessage = async (payload: SecuPayload, chatId: number, msgType: number = 6) => {
        console.log("🧠 sendSecuLineMessage вызван", payload);

        if (!seculineRef.current || seculineRef.current.readyState !== WebSocket.OPEN) {
            console.warn("❌ Невозможно отправить: соединение не открыто");
            return;
        }

        if (!myPrivateKeyBase64) {
            console.error("❌ Нет приватного ключа пользователя");
            return;
        }

        const myPrivateKey = decodeBase64(myPrivateKeyBase64);
        let sessionKey: Uint8Array;

        if (payload.groupKey) {
            console.log("🔐 Используем groupKey для шифрования (групповой чат)");
            sessionKey = decodeBase64(payload.groupKey);
        } else {
            console.log("📨 recipientPublicKeyBase64", payload.recipientPublicKeyBase64);
            const base64ToUint8Array = (base64: string): Uint8Array => {
                const binaryString = atob(base64);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                return bytes;
            };

            let recipientPublicKey: Uint8Array;

            if (!payload.groupKey && !payload.recipientPublicKeyBase64) {
                console.error("❌ Ни groupKey, ни recipientPublicKeyBase64 не передан");
                return;
            }


            try {
                if (!payload.recipientPublicKeyBase64) {
                    console.error("❌ recipientPublicKeyBase64 не передан");
                    return;
                }

                recipientPublicKey = base64ToUint8Array(payload.recipientPublicKeyBase64);


                if (recipientPublicKey.length !== 32) {
                    console.error("❌ recipientPublicKey должен быть длиной 32 байта. Сейчас:", recipientPublicKey.length);
                    return;
                }
            } catch (err) {
                console.error("❌ Ошибка при декодировании recipientPublicKey:", err);
                return;
            }

            sessionKey = sharedKey(myPrivateKey, recipientPublicKey);
        }

        const encryptedBase64 = encryptMessage(payload.content, sessionKey);

        const packet = {
            message_id: payload.id ?? payload.tempId,
            chat_id: chatId,
            sender: currentUserId,
            timestamp: new Date().toISOString(),
            msg_type: msgType,
            payload: Array.from(decodeBase64(encryptedBase64)),
            groupKey: payload.groupKey,
            ack_id: null,
            retry_count: 0,
            attachment: payload.attachment || [],
            user: payload.user,
            ...(msgType === 7 ? { recipientPublicKeyBase64: payload.recipientPublicKeyBase64 } : {})
        };

        const binary = msgpackEncode(packet);
        seculineRef.current.send(binary);
    };


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken: { id: number } = jwtDecode(token);
                setCurrentUserId(decodedToken.id);
            } catch (error) {
                console.error('Token decode failed', error);
            }
        }
    }, []);

    useEffect(() => {
        if (currentUserId !== null && !isConnected) {
            connectWebSocket();
        }
    }, [currentUserId, connectWebSocket, isConnected, ...dependencies]);

    return {
        wsRef: wsRef.current,
        isConnecting,
        isConnected,
        connectWebSocket,
        sendSecuLineMessage,
    };
};

