export const DB_NAME = "sweb-account-1";
export const DB_VERSION = 1;

export const STORE_CHATS = "chats";
export const STORE_MESSAGES = "messages";
export const STORE_MEDIA = "media";
export const STORE_DECRYPTED = "decryptedMessages";
export const STORE_KEYS = "keys"; // 🔐 Добавлено

export function openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onupgradeneeded = () => {
            const db = request.result;

            if (!db.objectStoreNames.contains(STORE_CHATS)) {
                db.createObjectStore(STORE_CHATS, { keyPath: "id" });
            }

            if (!db.objectStoreNames.contains(STORE_MESSAGES)) {
                const messagesStore = db.createObjectStore(STORE_MESSAGES, { keyPath: "id" });
                messagesStore.createIndex("chatId", "chatId", { unique: false });
            }

            if (!db.objectStoreNames.contains(STORE_MEDIA)) {
                db.createObjectStore(STORE_MEDIA, { keyPath: "url" });
            }

            if (!db.objectStoreNames.contains(STORE_DECRYPTED)) {
                db.createObjectStore(STORE_DECRYPTED, { keyPath: "id" });
            }

            if (!db.objectStoreNames.contains(STORE_KEYS)) {
                db.createObjectStore(STORE_KEYS);
            }
        };

        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}
