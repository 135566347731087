import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export const setupTotp = async (token: string): Promise<{ secret: string; otpauthUrl: string }> => {
    try {
        const response = await axios.post(
            `${API_URL}/api/users/setup-totp`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error setting up TOTP:', error);
        throw error;
    }
};


export const verifyTotp = async (totpCode: string, token: string): Promise<{ success: boolean }> => {
    try {
        const response = await axios.post(
            `${API_URL}/api/users/verify-totp`,
            { totpCode },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error verifying TOTP:', error);
        throw error;
    }
};
