import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface RemoveUserModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    username?: string;
    publicKey?: string;
}

const RemoveUserModal: React.FC<RemoveUserModalProps> = ({ open, onClose, onConfirm, username, publicKey }) => {
    const { t } = useTranslation();
    const shortKey = publicKey ? `${publicKey.slice(0, 6)}...${publicKey.slice(-4)}` : '';

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#08182e',
                    p: 3,
                    borderRadius: 'md',
                    width: 320,
                    boxShadow: '0 6px 24px rgba(0, 168, 255, 0.3)',
                    border: '1px solid rgba(0,168,255,0.3)',
                }}
            >
                <Typography sx={{ color: '#a0d4ff', mb: 1 }}>
                    {t('remove_user')}?
                </Typography>
                <Typography sx={{ color: '#8ab4f8', mb: 2 }}>
                    {t('remove_user_confirm', {
                        user: username || shortKey
                    })}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button variant="plain" onClick={onClose}>{t('cancel')}</Button>
                    <Button variant="solid" color="danger" onClick={onConfirm}>{t('delete')}</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RemoveUserModal;
