import axios from 'axios';
import {jwtDecode} from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export const createPrivateChat = async (currentUserId: number, userId: number, token: string) => {
    try {

        if (!token) {
            throw new Error('Authorization token is missing');
        }

        const response = await axios.post(
            `${API_URL}/api/chats/private`,
            {
                user1Id: currentUserId,
                user2Id: userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error creating private chat:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error creating private chat:', error);
        }
        throw new Error('Could not create chat');
    }
};

export const fetchChatsFromServer = async (userId: number, publicKey: string, token: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/api/chats/chats`,
            { userId, publicKey },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log("✅ Серверный ответ получен, обновляем кэш.", response.data);

        return response.data;
    } catch (error: any) {
        console.warn("⚠️ Ошибка сервера, используем кэш:", error.response?.data || error.message);
        return null;
    }
};

export const fetchChatWithMessages = async (chatId: number, token: string) => {
    try {
        const response = await axios.get(`${API_URL}/api/chats/${chatId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error fetching chat with messages:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error fetching chat with messages:', error);
        }
        throw new Error('Could not fetch chat with messages');
    }
};

export const deleteChat = async (chatId: number, token: string) => {
    try {
        const response = await fetch(`${API_URL}/api/chats/${chatId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to delete chat');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error deleting chat:', error);
        throw new Error('Could not delete chat');
    }
};

export const addUsersToGroupChat = async (chatId: number, newUserIds: number[], token: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/api/chats/${chatId}/add-users`,
            {
                chatId,
                newUserIds
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error adding users to group:', error);
        throw error;
    }
};

export const assignRoleInChat = async (chatId: number, userId: number, role: 'admin' | 'member', token: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/api/chats/${chatId}/assign-role`,
            {
                userIdToAssign: userId,
                role
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error assigning role:', error);
        throw error;
    }
};

export const createGroupChat = async (
    groupName: string,
    avatar?: File | null,
    selectedUserIds?: number[],
    groupKeyBase64?: string
): Promise<number | null> => {
    try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Token is missing');

        const decodedToken: any = jwtDecode(token);
        const creatorId = decodedToken.id;

        const allUserIds = [...(selectedUserIds || []), creatorId].filter((id, index, self) => self.indexOf(id) === index);

        const formData = new FormData();
        formData.append('groupName', groupName);
        if (avatar) {
            formData.append('avatar', avatar);
        }
        if (groupKeyBase64) {
            formData.append('groupKey', groupKeyBase64);
        }

        allUserIds.forEach(id => formData.append('selectedUsers[]', id.toString()));

        const response = await axios.post(`${API_URL}/api/chats/group`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const groupId = response.data.id;
        return groupId;
    } catch (error) {
        console.error('Error creating group:', error);
        return null;
    }
};

export const removeUserFromChat = async (chatId: number, userId: number, token: string) => {
    try {
        const response = await axios.post(
            `${API_URL}/api/chats/${chatId}/kick-user`,
            { userIdToKick: userId },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error removing user:', error);
        throw error;
    }
};

export const updateChatSettings = async (chatId: number, formData: FormData, token: string) => {
    const response = await axios.put(`${API_URL}/api/chats/${chatId}/settings`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

export const createFavoriteChat = async (token: string) => {
    try {
        if (!token) {
            throw new Error('Authorization token is missing');
        }

        const response = await axios.post(
            `${API_URL}/api/chats/chats/favorite`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Error creating or retrieving favorite chat:', error.response?.data || error.message);
        } else {
            console.error('Unexpected error creating or retrieving favorite chat:', error);
        }
        throw new Error('Could not create or retrieve favorite chat');
    }
};
