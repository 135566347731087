import * as React from 'react';
import Box from '@mui/joy/Box';
import {useLocation, useNavigate} from 'react-router-dom';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import MessagesPaneHeader from './MessagesPaneHeader';
import { ChatProps, DecodedToken, MessageProps, UserProps } from '../core/types';
import {useState, useEffect, useRef, useLayoutEffect, useMemo} from 'react';
import { useWebSocket } from '@/hooks/useWebSocket';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import {updateChatSettings, updateMessageStatus} from '@/api';
import dayjs from "dayjs";
import {AnimatePresence, motion} from "framer-motion";
import ChatInfoPanel from "./ChatInfoPanel";
import {decryptMessage} from "@/crypto/encryption";
import {sharedKey} from "@stablelib/x25519";
import { decode as decodeBase64 } from "@stablelib/base64";
import {logger} from "@/utils/logger";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type MessagesPaneProps = {
    chat: ChatProps | null;
    members?: UserProps[];
    chats: ChatProps[];
    selectedChat: ChatProps | null;
    setSelectedChat: (chat: ChatProps | null) => void;
    setChats: React.Dispatch<React.SetStateAction<ChatProps[]>>;
    onSendMessage: (message: MessageProps) => void;
    onEditMessage: (messageId: number, content: string) => void;
};


export default React.memo(function MessagesPane({
                                                    chat,
                                                    chats,
                                                    members = [],
                                                    setSelectedChat,
                                                    selectedChat,
                                                    setChats,
                                                    onSendMessage,
                                                    onEditMessage
                                                }: MessagesPaneProps) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const previousPath = location.state?.from || '/chat';
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);

    const chatIdFromUrl = useMemo(() => {
        const raw = location.hash?.replace('#', '');
        return raw?.startsWith('-') ? raw.slice(1) : raw;
    }, [location.hash]);

    const [chatMessages, setChatMessages] = useState<MessageProps[]>(chat?.messages || []);
    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const [currentUserId, setCurrentUserId] = useState<number | null>(null);
    const [currentUser, setCurrentUser] = useState<DecodedToken | null>(null);
    const [editingMessageId, setEditingMessageId] = useState<number | null>(null);
    const [userStatuses, setUserStatuses] = useState<
        Record<string, Pick<UserProps, 'online' | 'lastOnline'>>
    >({});
    const [visibleDate, setVisibleDate] = useState<string | null>(null);
    const [isClosing, setIsClosing] = useState(false);
    const [showInfoPanel, setShowInfoPanel] = useState(false);
    const chatIdRef = useRef<number | null>(null);
    const chatMessagesRef = useRef<MessageProps[]>([]);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const selectedChatRef = useRef<ChatProps | null>(null);
    const [visibleCount, setVisibleCount] = useState(20);
    const observer = useRef<IntersectionObserver | null>(null);
    const observedMessages = useRef<Set<number>>(new Set());
    const [pinnedMessage, setPinnedMessage] = useState<MessageProps | null>(null);
    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(
            async (entries) => {
                const token = localStorage.getItem('token');
                if (!token) return;

                const decodedToken: { id: number } = jwtDecode(token);
                const myId = decodedToken.id;

                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        const el = entry.target as HTMLElement;
                        const messageId = el.dataset.messageId;
                        const userId = el.dataset.userId;

                        if (!messageId || !userId) continue;

                        const id = Number(messageId);
                        const uid = Number(userId);

                        const message = chatMessagesRef.current.find((msg) => msg.id === id);
                        if (!message || message.isRead || uid === myId || !Number.isInteger(id)) continue;

                        if (!observedMessages.current.has(id)) {
                            observedMessages.current.add(id);
                            try {
                                await updateMessageStatus(id, { isRead: true }, token);
                                setChatMessages((prev) =>
                                    prev.map((msg) =>
                                        msg.id === id ? { ...msg, isRead: true } : msg
                                    )
                                );
                            } catch (e) {
                                logger.error('❌ Ошибка при пометке прочтения:', e);
                            }
                        }
                    }
                }
            },
            { threshold: 0.6 }
        );

        return () => observer.current?.disconnect();
    }, []);


    const groupMessagesByDate = React.useCallback((msgs: MessageProps[]) => {
        return msgs.reduce((acc, message) => {
            const messageDate = dayjs(message.createdAt).format('DD MMMM YYYY');
            if (!acc[messageDate]) {
                acc[messageDate] = [];
            }
            acc[messageDate].push(message);
            return acc;
        }, {} as Record<string, MessageProps[]>);
    }, []);

    const visibleMessages = chatMessages.slice(-visibleCount);
    const showPinnedOnly = chatIdFromUrl?.includes('_pinned');

    const filteredMessages = showPinnedOnly
        ? chatMessages.filter((msg) => msg.isPinned)
        : visibleMessages;

    const groupedMessages = useMemo(
        () => groupMessagesByDate(filteredMessages),
        [filteredMessages, groupMessagesByDate]
    );

    const scrollToBottom = React.useCallback((smooth: boolean = true) => {
        const container = messagesContainerRef.current;
        if (!container) return;

        isAutoScrollingRef.current = true;

        requestAnimationFrame(() => {
            container.scrollTo({
                top: container.scrollHeight,
                behavior: smooth ? 'smooth' : 'auto',
            });

            setTimeout(() => {
                isAutoScrollingRef.current = false;
            }, 200);
        });
    }, []);

    const isAtBottom = () => {
        const container = messagesContainerRef.current;
        if (!container) return false;
        const threshold = 100;
        return container.scrollTop + container.clientHeight >= container.scrollHeight - threshold;
    };


    const isAutoScrollingRef = useRef(false);


    const scrollOffsetRef = useRef<number | null>(null);

    const markMessagesAsRead = React.useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('Authorization token is missing');

            const decodedToken: { id: number } = jwtDecode(token);
            const currentUser = decodedToken.id;

            const messageIds = chatMessages
                .filter((msg) => !msg.isRead && msg.userId !== currentUser && Number.isInteger(msg.id))
                .map((msg) => msg.id);

            if (messageIds.length === 0) return;
            logger.info('📤 Отправляем запрос на обновление прочитанности сообщений:', messageIds);

            await Promise.all(
                messageIds.map((id) => updateMessageStatus(Number(id), { isRead: true }, token)),
            );

            setChatMessages((prevMessages) =>
                prevMessages.map((msg) => (messageIds.includes(msg.id) ? { ...msg, isRead: true } : msg)),
            );
        } catch (error) {
            logger.error('❌ Ошибка при отметке сообщений как прочитанных:', error);
        }
    }, [chatMessages]);


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken: DecodedToken = jwtDecode(token);
            const id = Number(decodedToken.id);
            if (!Number.isNaN(id)) {
                setCurrentUserId(id);
                setCurrentUser(decodedToken);
            } else {
                logger.warn('❌ ID из токена некорректен:', decodedToken.id);
            }
        }
    }, []);
    const deletedMessageIdRef = useRef<number | null>(null);

    const prevLengthRef = useRef(0);
    const scrollToBottomAndMarkRead = () => {
        isAutoScrollingRef.current = true;
        scrollToBottom();
        markMessagesAsRead();
        setTimeout(() => {
            isAutoScrollingRef.current = false;
        }, 300);
    };
    const isLoadingMoreRef = useRef(false);

    useEffect(() => {
        if (chatMessages.length > prevLengthRef.current) {
            if (!isLoadingMoreRef.current) {
                scrollToBottomAndMarkRead();
            }
        }
        prevLengthRef.current = chatMessages.length;
    }, [chatMessages]);


    const incomingMessageRef = useRef(false);

    useEffect(() => {
        const updateChatIdFromHash = () => {
            let hash = window.location.hash.slice(1);
            if (hash.startsWith('-')) {
                hash = hash.slice(1);
            }
        };

        updateChatIdFromHash();
        window.addEventListener('hashchange', updateChatIdFromHash);
        return () => window.removeEventListener('hashchange', updateChatIdFromHash);
    }, []);

    useEffect(() => {
        if (!chatIdFromUrl) return;

        const baseId = Number(chatIdFromUrl.replace('_pinned', ''));
        if (chatIdRef.current === baseId) return;


        const recipientId = Number(chatIdFromUrl.replace('_pinned', ''));

        if (!recipientId || recipientId === currentUserId) {
            logger.warn('⚠️ Некорректный recipientId:', recipientId);
            return;
        }

        const chatFromUrl = chats.find(
            (c) => !c.isGroup && c.users.some((user) => user.id === recipientId),
        );
        if (chatFromUrl) {
            setSelectedChat(chatFromUrl);
        }


    }, [chatIdFromUrl, chats, setSelectedChat, currentUserId]);



    function deduplicateMessages(messages: MessageProps[]): MessageProps[] {
        const seen = new Map<number | string, MessageProps>();

        for (const msg of messages) {
            const key = msg.tempId ?? msg.id ?? msg.createdAt;
            if (!seen.has(key)) {
                seen.set(key, msg);
            } else {
                const existing = seen.get(key)!;
                if (existing.pending && !msg.pending) {
                    seen.set(key, msg);
                }
            }
        }

        return Array.from(seen.values());
    }

    useEffect(() => {
        if (!chat) {
            chatMessagesRef.current = [];
            setChatMessages([]);
            chatIdRef.current = null;
            selectedChatRef.current = null;
            return;
        }

        const chatFromFullList = chats.find(c => c.id === chat.id);
        const newMessages = chatFromFullList?.messages || [];

        const isPinned = chatIdFromUrl?.includes('_pinned') || false;
        const baseId = chat.id;


        const prevUsers = selectedChatRef.current?.users?.map(u => u.id).sort().join(',') || '';
        const currUsers = chat.users?.map(u => u.id).sort().join(',') || '';
        const isChatChanged = prevUsers !== currUsers || chatIdRef.current !== baseId;

        if (isChatChanged || showPinnedOnly !== isPinned) {
            logger.info('🔁 ПЕРЕГРУЗКА MessagesPane: чат сменился или pinned режим сменился');

            chatIdRef.current = baseId;
            selectedChatRef.current = chat;

            const pinnedMessages = newMessages.filter(msg => msg.isPinned);
            chatMessagesRef.current = isPinned ? pinnedMessages : newMessages;
            setChatMessages(isPinned ? pinnedMessages : newMessages.slice(-40));
            setVisibleCount(40);
            return;
        }

        const mergedMessages = deduplicateMessages([...newMessages]);

        const isChanged = JSON.stringify(mergedMessages) !== JSON.stringify(chatMessagesRef.current);

        if (isChanged) {
            chatMessagesRef.current = mergedMessages;
            setChatMessages([...chatMessagesRef.current]);
        }
    }, [chat, chats, chatIdFromUrl, showPinnedOnly]);

    useLayoutEffect(() => {
        const container = messagesContainerRef.current;
        if (!chat || !container) return;

        const savedScroll = (window as any).savedScrollPositions?.get(chat.id);

        const scroll = () => {
            if (savedScroll !== undefined) {
                container.scrollTop = savedScroll;
            } else {
                container.scrollTop = container.scrollHeight;
            }
        };

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                scroll();
            });
        });
    }, [chat?.id]);
    useEffect(() => {
        if (!chat) return;

        if (!showPinnedOnly) {
            scrollToBottomAndMarkRead();
        }
    }, [chat?.id, showPinnedOnly]);



    useLayoutEffect(() => {
        const container = messagesContainerRef.current;
        if (!container || scrollOffsetRef.current === null) return;

        container.scrollTop = container.scrollHeight - scrollOffsetRef.current;

        scrollOffsetRef.current = null;
    }, [chatMessages]);

    useEffect(() => {
        const container = messagesContainerRef.current;
        if (!container) return;

        const handleScroll = () => {
            if (!container) return;

            if (container.scrollTop < 150 && visibleCount < chatMessagesRef.current.length) {
                isLoadingMoreRef.current = true;

                scrollOffsetRef.current = container.scrollHeight - container.scrollTop;
                const nextCount = Math.min(visibleCount + 20, chatMessagesRef.current.length);
                const startIndex = Math.max(0, chatMessagesRef.current.length - nextCount);
                const nextSlice = chatMessagesRef.current.slice(startIndex);

                setChatMessages(nextSlice);
                setVisibleCount(nextCount);
            }

            const isNearBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 200;
            setShowScrollToBottom(!isNearBottom);

            if (isNearBottom) {
                isLoadingMoreRef.current = false;
            }
        };

        container.addEventListener('scroll', handleScroll);

        return () => container.removeEventListener('scroll', handleScroll);
    }, [visibleCount]);




    const handleEditMessageInList = React.useCallback((updatedMessage: MessageProps) => {
        setChatMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.id === updatedMessage.id
                    ? {
                        ...msg,
                        content: updatedMessage.content,
                        isEdited: true,
                        pending: false,
                    }
                    : msg,
            )
        );
    }, []);

    const handleDeleteMessageInList = React.useCallback((messageId: number) => {
        setChatMessages((prevMessages) => prevMessages.filter((msg) => Number(msg.id) !== messageId));
    }, []);


    const replaceMessageByTempId = React.useCallback(
        (serverMessage: MessageProps, finalMessage: MessageProps) => {
            const index = chatMessagesRef.current.findIndex(
                (msg) => msg.tempId === finalMessage.tempId,
            );
            if (index !== -1) {
                const existing = chatMessagesRef.current[index];
                chatMessagesRef.current[index] = {
                    ...existing,
                    id: serverMessage.id,
                    pending: false,
                    createdAt: serverMessage.createdAt ?? existing.createdAt,
                };
                setChatMessages([...chatMessagesRef.current]);

            } else {
                const duplicateIndex = chatMessagesRef.current.findIndex(msg => msg.id === finalMessage.id);
                if (duplicateIndex === -1) {
                    logger.warn('⚠️ tempId не найден, пушим как новое:', finalMessage.tempId);
                    chatMessagesRef.current.push(finalMessage);
                    setChatMessages([...chatMessagesRef.current]);
                } else {
                    logger.info('ℹ️ Уже существует сообщение с таким id, не добавляем дубликат');
                }
            }

        },
        [],
    );

    const handleSaveGroupSettings = async ({ name, avatar }: { name: string; avatar?: File }) => {
        if (!selectedChat?.id) return;

        try {
            const formData = new FormData();
            formData.append('groupName', name);
            if (avatar) formData.append('avatar', avatar);

            const token = localStorage.getItem('token');
            if (!token) throw new Error('Missing token');

            await updateChatSettings(selectedChat.id, formData, token);

            setChats(prev =>
                prev.map(chat =>
                    chat.id === selectedChat.id
                        ? {
                            ...chat,
                            name,
                            avatar: avatar ? URL.createObjectURL(avatar) : chat.avatar,
                        }
                        : chat
                )
            );
        } catch (error) {
            logger.error('❌ Ошибка при обновлении настроек чата:', error);
        }
    };

    const { sendSecuLineMessage } = useWebSocket(
        React.useCallback(
            (data) => {
                logger.info('📥 Получено сообщение от сервера:', data);
                if (data.type === 'newMessage' && data.message) {
                    const serverMessage = data.message;
                    const currentChatId = selectedChatRef.current?.id;
                    if (currentChatId !== serverMessage.chatId) return;

                    logger.info('📥 Получено новое сообщение:', serverMessage);

                    let decryptedContent = '[Encrypted]';
                    try {
                        const cleanBase64 = serverMessage.content.startsWith('"')
                            ? JSON.parse(serverMessage.content)
                            : serverMessage.content;

                        const myPrivKeyBase64 = localStorage.getItem('solachat_priv');
                        const senderPubKeyBase64 = serverMessage.user?.solachat_pub;

                        if (selectedChatRef.current?.isGroup && selectedChatRef.current.groupKey) {
                            const groupKey = decodeBase64(selectedChatRef.current.groupKey);
                            decryptedContent = decryptMessage(cleanBase64, groupKey);
                        }
                        else if (myPrivKeyBase64 && senderPubKeyBase64 && serverMessage.userId !== currentUserId) {
                            const myPrivKey = decodeBase64(myPrivKeyBase64);
                            const senderPubKey = decodeBase64(senderPubKeyBase64);
                            const sessionKey = sharedKey(myPrivKey, senderPubKey);
                            decryptedContent = decryptMessage(cleanBase64, sessionKey);
                        }
                        else if (serverMessage.userId === currentUserId) {
                            decryptedContent = cleanBase64;
                        }
                    } catch (err) {
                        logger.warn('❌ Ошибка дешифровки сообщения:', err);
                    }


                    const parsedDate = new Date(serverMessage.createdAt);
                    const fallbackDate = new Date().toISOString();
                    const finalMessage = {
                        ...serverMessage,
                        content: decryptedContent,
                        createdAt: isNaN(parsedDate.getTime()) ? fallbackDate : parsedDate.toISOString(),
                        pending: false,
                    };

                    replaceMessageByTempId(serverMessage, finalMessage);

                }

                if (data.type === 'editMessage' && data.message) {
                    const updatedMessage = data.message;

                    if (updatedMessage.chatId !== chatIdRef.current) return;

                    let finalContent = '[Encrypted]';

                    try {
                        if (!currentUser) return;

                        const isMyMessage =
                            updatedMessage.userId === currentUserId || updatedMessage.user?.id === currentUserId;

                        const cleanContent = updatedMessage.content.startsWith('"')
                            ? JSON.parse(updatedMessage.content)
                            : updatedMessage.content;

                        const myPrivKeyBase64 = localStorage.getItem('solachat_priv');
                        if (!myPrivKeyBase64) throw new Error("❌ Нет приватного ключа");
                        const myPrivKey = decodeBase64(myPrivKeyBase64);

                        const otherPubKeyBase64 = isMyMessage
                            ? updatedMessage.recipientPublicKeyBase64
                            : updatedMessage.user?.solachat_pub;

                        if (!otherPubKeyBase64) throw new Error("❌ Нет публичного ключа собеседника");
                        const pubKey = decodeBase64(otherPubKeyBase64);
                        const sessionKey = sharedKey(myPrivKey, pubKey);

                        finalContent = decryptMessage(cleanContent, sessionKey);

                        chatMessagesRef.current = chatMessagesRef.current.map((msg) =>
                            msg.id === updatedMessage.id
                                ? {
                                    ...msg,
                                    content: finalContent,
                                    isEdited: true,
                                    updatedAt: updatedMessage.updatedAt,
                                    pending: false,
                                }
                                : msg
                        );

                        setChatMessages([...chatMessagesRef.current]);
                    } catch (err) {
                        logger.warn("❌ Ошибка дешифровки отредактированного сообщения:", err);
                    }

                } else if (data.type === 'deleteMessage') {
                    const { messageId, chatId } = data;
                    if (chatId === chatIdRef.current) {
                        handleDeleteMessageInList(messageId);
                    }
                }
                else if (data.type === 'messageRead' && data.messageId) {
                    const index = chatMessagesRef.current.findIndex(msg => msg.id === data.messageId);
                    if (index === -1 || chatMessagesRef.current[index].isRead) return;

                    chatMessagesRef.current[index] = {
                        ...chatMessagesRef.current[index],
                        isRead: true,
                    };

                    setChatMessages([...chatMessagesRef.current]);
                }


                if ((data.type === 'USER_CONNECTED' || data.type === 'USER_DISCONNECTED') && data.publicKey) {
                    setUserStatuses((prev) => {
                        const isOnline = data.type === 'USER_CONNECTED';
                        const current = prev[data.publicKey];

                        if (current?.online === isOnline) return prev;

                        return {
                            ...prev,
                            [data.publicKey]: {
                                online: isOnline,
                                lastOnline: isOnline ? null : data.lastOnline,
                            },
                        };
                    });

                }
                if (data.type === 'ackMessage') {
                    const { tempId, status } = data;
                    if (status === 'accepted') {
                        logger.info('✅ ACK: Меняем pending → false по tempId', tempId);
                        chatMessagesRef.current = chatMessagesRef.current.map((msg) =>
                            msg.tempId === tempId ? { ...msg, pending: false } : msg,
                        );
                        setChatMessages(chatMessagesRef.current.filter(Boolean));
                    }
                }
                if (data.type === 'messagePinned' && data.message) {
                    logger.info('📌 Пришло событие messagePinned для ID=', data.message.id);

                    if (data.message.chatId !== chatIdRef.current) {
                        logger.info(`🚫 Чат не совпадает, ожидаем chatId=${chatIdRef.current}, пришёл ${data.message.chatId}`);
                        return;
                    }

                    const pinnedId = data.message.id;
                    const existingMessage = chatMessagesRef.current.find(msg => msg.id === pinnedId);

                    if (!existingMessage) {
                        logger.warn(`⚠️ Сообщение ID=${pinnedId} не найдено в chatMessagesRef — игнорируем`);
                        return;
                    }

                    if (existingMessage.isPinned) {
                        logger.info(`ℹ️ Сообщение ID=${pinnedId} уже закреплено`);
                    } else {
                        logger.info(`📌 Закрепляем сообщение ID=${pinnedId}`);
                        chatMessagesRef.current = chatMessagesRef.current.map((msg) =>
                            msg.id === pinnedId ? { ...msg, isPinned: true } : msg
                        );
                    }

                    if (showPinnedOnly) {
                        const pinnedMsg = chatMessagesRef.current.find((msg) => msg.id === pinnedId);
                        if (pinnedMsg && !chatMessages.some((msg) => msg.id === pinnedId)) {
                            logger.info(`➕ Добавляем сообщение ID=${pinnedId} в список закрепов`);
                            setChatMessages((prev) => [...prev, pinnedMsg]);
                        } else {
                            setChatMessages([...chatMessagesRef.current.filter(msg => msg.isPinned)]);
                        }
                    } else {
                        setChatMessages([...chatMessagesRef.current]);
                    }
                }



                 if (data.type === 'messageUnpinned' && data.message) {
                    if (data.message.chatId !== chatIdRef.current) return;

                    const unpinnedId = data.message.id;

                    chatMessagesRef.current = chatMessagesRef.current.map((msg) =>
                        msg.id === unpinnedId ? { ...msg, isPinned: false } : msg
                    );

                    if (showPinnedOnly) {
                        setChatMessages((prev) => prev.filter((msg) => msg.id !== unpinnedId));
                    } else {
                        setChatMessages([...chatMessagesRef.current]);
                    }
                }
            },
            [
                currentUserId,
                replaceMessageByTempId,
                handleEditMessageInList,
                handleDeleteMessageInList,
            ],
        ),
        [currentUserId],
    );

    const handleEditMessage = React.useCallback((messageId: number, content: string) => {
        setEditingMessageId(messageId);
        setTextAreaValue(content);
    }, []);

    const interlocutor = React.useMemo(() => {
        if (!chat?.isGroup) {
            const userFromDb = chat?.users?.find((u) => u.id !== currentUserId);
            if (userFromDb) {
                const currentStatus = userStatuses[userFromDb.public_key] ?? {};
                return {
                    ...userFromDb,
                    online: currentStatus.online ?? userFromDb.online ?? false,
                    lastOnline: currentStatus.lastOnline ?? userFromDb.lastOnline,
                };
            }
        }
        return undefined;
    }, [chat?.id, currentUserId, userStatuses[chat?.users?.find((u) => u.id !== currentUserId)?.public_key ?? '']]);


    const otherUserPublicKeyBase64 = interlocutor?.solachat_pub || '';

    const formatDate = React.useCallback(
        (dateString: string) => {
            const date = new Date(dateString);
            const day = date.getDate().toString();
            const monthNumber = date.getMonth() + 1;
            const year = date.getFullYear();

            const monthKey = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
            const translatedMonth = t(`months.${monthKey}`);
            const formatKey = year === new Date().getFullYear() ? 'date_format' : 'date_format_with_year';

            return t(formatKey, { day, month: translatedMonth, year });
        },
        [t],
    );

    const handlePublicKeyClick = React.useCallback(() => {
        setShowInfoPanel((prev) => !prev);
    }, []);

    useEffect(() => {
        const savedState = sessionStorage.getItem('showInfoPanel');
        if (savedState === 'true') {
            setShowInfoPanel(true);
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('showInfoPanel', showInfoPanel.toString());
    }, [showInfoPanel]);

    const scrollPositionsRef = useRef<Map<number, number>>(new Map());
    (window as any).savedScrollPositions = scrollPositionsRef.current;


    const handleBack = React.useCallback(() => {
        const container = document.querySelector('[data-messages-container]') as HTMLDivElement | null;
        if (container && selectedChat?.id) {
            scrollPositionsRef.current.set(selectedChat.id, container.scrollTop);
        }

        setIsClosing(true);
        setSelectedChat(null);
        navigate(previousPath);
    }, [selectedChat, navigate, previousPath]);

    // useEffect(() => {
    //     const handleEsc = (e: KeyboardEvent) => {
    //         if (e.key === 'Escape') {
    //             handleBack();
    //         }
    //     };
    //
    //     window.addEventListener('keydown', handleEsc);
    //     return () => window.removeEventListener('keydown', handleEsc);
    // }, [handleBack]);

    useEffect(() => {
        if (showPinnedOnly) {
            const pinnedOnly = chatMessagesRef.current.filter(msg => msg.isPinned);
            setChatMessages(pinnedOnly);
        } else {
            setChatMessages([...chatMessagesRef.current]);
        }
    }, [showPinnedOnly]);


    return (
        <Sheet
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                overflow: 'hidden',
                background: 'radial-gradient(circle at center, #0a192f 0%, #081428 100%)',
            }}
        >
            <Box
                sx={{
                    flex: '1 1 auto',
                    maxWidth: showInfoPanel ? 'calc(100% - 450px)' : '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {chat?.id && (
                    <MessagesPaneHeader
                        sender={interlocutor}
                        chatMessages={chatMessages}
                        chatId={chat.id}
                        chat={chat}
                        isGroup={chat.isGroup}
                        chatName={chat.isGroup ? chat.name : undefined}
                        groupAvatar={chat.isGroup ? chat.avatar || '/default-group-avatar.png' : undefined}
                        members={chat?.users || []}
                        onBack={handleBack}
                        onPublicKeyClick={handlePublicKeyClick}
                    />
                )}

                {visibleDate && (
                    <Box
                        component={motion.div}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        sx={{
                            position: 'fixed',
                            top: 10,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            bgcolor: 'rgba(0, 168, 255, 0.2)',
                            color: '#00a8ff',
                            padding: '6px 12px',
                            borderRadius: 'lg',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            zIndex: 1000,
                            border: '1px solid rgba(0, 168, 255, 0.3)',
                            boxShadow: '0 4px 16px rgba(0, 168, 255, 0.2)',
                        }}
                    >
                        {visibleDate}
                    </Box>
                )}

                <Box
                    data-messages-container
                    ref={messagesContainerRef}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        px: 1,
                        py: { xs: 2, sm: 1 },
                        overflowY: 'auto',
                        alignItems: 'center',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            bgcolor: 'rgba(0, 168, 255, 0.1)',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            bgcolor: 'rgba(0, 168, 255, 0.3)',
                            borderRadius: '4px',
                        },
                    }}
                >

                    {chatMessages.length > 0 ? (
                        <Stack
                            spacing={2}
                            sx={{
                                width: '100%',
                                maxWidth: 800,
                                mx: 'auto',
                            }}
                        >
                            {Object.entries(groupedMessages).map(([date, messages]) => (
                                <div key={date}>
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            color: '#a0d4ff',
                                            bgcolor: 'rgba(0, 168, 255, 0.1)',
                                            padding: '4px 8px',
                                            borderRadius: '8px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            maxWidth: 'fit-content',
                                            margin: '7px auto',
                                            border: '1px solid rgba(0, 168, 255, 0.3)',
                                            boxShadow: '0 2px 8px rgba(0, 168, 255, 0.2)',
                                        }}
                                    >
                                        {formatDate(date)}
                                    </Typography>

                                    <Stack spacing={1}>
                                        <AnimatePresence initial={false}>
                                            {messages
                                                .filter(
                                                    (message) =>
                                                        message.content ||
                                                        (message.attachment && message.attachment.length > 0)
                                                )
                                                .map((message: MessageProps, index) => {
                                                    const isCurrentUser = message.userId === currentUserId;
                                                    const prevMessage = messages[index - 1];
                                                    const isFirstInGroup =
                                                        index === 0 ||
                                                        messages[index - 1]?.userId !== message.userId ||
                                                        dayjs(message.createdAt).diff(messages[index - 1]?.createdAt, 'minute') > 5;


                                                    const messageKey = message.tempId ?? message.id;

                                                    return (
                                                        <motion.div
                                                            key={messageKey}
                                                            ref={(el) => {
                                                                if (el && observer.current && message.id) {
                                                                    el.dataset.messageId = String(message.id);
                                                                    el.dataset.userId = String(message.userId);
                                                                    observer.current.observe(el);
                                                                }
                                                            }}
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                spacing={1}
                                                                flexDirection={isCurrentUser ? 'row-reverse' : 'row'}
                                                            >
                                                                <ChatBubble
                                                                    id={message.id}
                                                                    isFirstInGroup={isFirstInGroup}
                                                                    chatId={message.chatId}
                                                                    userId={message.userId}
                                                                    variant={isCurrentUser ? 'sent' : 'received'}
                                                                    user={message.user}
                                                                    content={message.content}
                                                                    createdAt={message.createdAt}
                                                                    attachment={message.attachment}
                                                                    isRead={message.isRead ?? false}
                                                                    isDelivered={message.isDelivered ?? false}
                                                                    unread={message.unread}
                                                                    isEdited={message.isEdited}
                                                                    onEditMessage={handleEditMessage}
                                                                    messageCreatorId={message.userId}
                                                                    isGroupChat={chat?.isGroup || false}
                                                                    pending={message.pending}
                                                                    isPinned={message.isPinned}
                                                                />
                                                            </Stack>
                                                        </motion.div>
                                                    );
                                                })}
                                        </AnimatePresence>
                                    </Stack>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </Stack>
                    ) : (
                        <Typography
                            sx={{
                                textAlign: 'center',
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                color: 'text.secondary',
                            }}
                        >
                            {t('')}
                        </Typography>
                    )}

                </Box>

                {chat && !showPinnedOnly && (
                    <Box   sx={{
                        width: '100%',
                        maxWidth: 820,
                        mx: 'auto',
                        pt: { xs: 1.5, sm: 1 },
                        pb: { xs: 2, sm: 1},
                    }}>
                        <MessageInput
                            chatId={chat?.id ?? null}
                            selectedChat={chat}
                            sendSecuLineMessage={sendSecuLineMessage}
                            setSelectedChat={setSelectedChat}
                            otherUserPublicKeyBase64={otherUserPublicKeyBase64}
                            currentUserId={currentUserId!}
                            onEditMessage={handleEditMessageInList}
                            onSubmit={(newMessage: MessageProps) => {
                                chatMessagesRef.current.push(newMessage);
                                setChatMessages(prev => [...prev, newMessage]);
                                incomingMessageRef.current = true;
                                setTextAreaValue('');
                                onSendMessage(newMessage);
                                setEditingMessageId(null);

                                if (!isAtBottom()) {
                                    scrollToBottom();
                                }
                            }}
                            setChats={setChats}
                            currentUser={currentUser!}
                            editingMessage={
                                editingMessageId !== null
                                    ? {
                                        id: editingMessageId,
                                        content:
                                            chatMessages.find((msg) => msg.id === editingMessageId)?.content || '',
                                    }
                                    : { id: null, content: '' }
                            }
                            setEditingMessage={(msg) => {
                                if (!msg) {
                                    setEditingMessageId(null);
                                } else {
                                    const messageToEdit = chatMessages.find(
                                        (msgItem) => msgItem.content === msg.content
                                    );
                                    if (messageToEdit) {
                                        setEditingMessageId(messageToEdit.id);
                                    }
                                }
                            }}
                        />
                    </Box>
                )}
            </Box>


            <AnimatePresence>
                {showInfoPanel && chat && (
                    <motion.div
                        initial={{ x: 450, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 450, opacity: 0 }}
                        transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                        style={{
                            width: 450,
                            height: '100vh',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            borderLeft: '1px solid rgba(0, 168, 255, 0.3)',
                            overflowY: 'auto',
                            background: 'rgba(10, 25, 47, 0.95)',
                            display: 'flex',
                            flexDirection: 'column',
                            zIndex: 1000,
                        }}
                    >
                        <ChatInfoPanel
                            profile={{
                                id: chat.id,
                                avatar: chat.isGroup
                                    ? chat.avatar || '/default-group-avatar.png'
                                    : interlocutor?.avatar || '/default-avatar.png',
                                username: chat.isGroup ? chat.name || '' : interlocutor?.username || '',
                                publicKey: interlocutor?.public_key ?? '',
                                isGroup: chat.isGroup,
                                members: chat.users.map(user => ({
                                    id: user.id,
                                    avatar: user.avatar || '/default-avatar.png',
                                    username: user.username || '',
                                    publicKey: user.public_key || '',
                                    online: user.online || false,
                                    lastOnline: user.lastOnline || '',
                                    role: user.role || '',
                                })),
                            }}
                            onSave={handleSaveGroupSettings}
                            messages={chatMessages}
                            onClose={() => setShowInfoPanel(false)}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {showScrollToBottom && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.25 }}
                        style={{
                            position: 'fixed',
                            bottom: 75,
                            right: 24,
                            zIndex: 999,
                        }}
                    >
                        <Box
                            onClick={() => scrollToBottom(true)}
                            sx={{
                                backgroundColor: 'rgba(0, 168, 255, 0.15)',
                                borderRadius: '50%',
                                padding: '12px',
                                backdropFilter: 'blur(6px)',
                                boxShadow: '0 4px 16px rgba(0, 168, 255, 0.3)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                transition: 'all 0.2s ease-in-out',
                            }}
                        >
                            <ArrowDownwardIcon sx={{color: '#00a8ff'}}/>
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>

        </Sheet>
    );
});
