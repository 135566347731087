import {Box} from "@mui/joy";
import React from "react";

export const CustomDivider = () => (
    <Box sx={{
            height: '1px',
            background: '#00a8ff33',
            my: 6,
            width: '100%',
            maxWidth: 800,
            mx: 'auto'
    }} />
);
