import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Typography,
    Avatar,
    Stack,
    Sheet,
    IconButton,
    Button,
    Input,
} from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import GroupIcon from '@mui/icons-material/Group';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { updateChatSettings } from '@/api/chatApi'
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import GroupAvatarModal from "@/components/modals/GroupAvatarModal";
import {AnimatePresence, motion } from 'framer-motion';

interface Member {
    id: number;
    avatar: string;
    username: string;
    publicKey: string;
    online: boolean;
    lastOnline: string;
    role?: string;
}

interface ChatEditPanelProps {
    initialName?: string;
    initialAvatar?: string;
    onSave: (updated: { name: string; avatar?: File }) => void;
    onAvatarClick: () => void;
    onBack: () => void;
    members: Member[];
    onMembersClick?: () => void;
}

const ChatEditPanel: React.FC<ChatEditPanelProps> = ({
                                                         initialName = '',
                                                         initialAvatar = '',
                                                         onSave,
                                                         onAvatarClick,
                                                         onBack,
                                                         members,
                                                         onMembersClick
                                                     }) => {
    const { t } = useTranslation();
    const [name, setName] = useState(initialName);
    const [hasChanges, setHasChanges] = useState(false);
    const [groupAvatarBlob, setGroupAvatarBlob] = useState<File | null>(null);
    const [avatarModalOpen, setAvatarModalOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);


    useEffect(() => {
        setHasChanges(name !== initialName);
    }, [name, initialName]);

    return (
        <>
        <GroupAvatarModal
            open={avatarModalOpen}
            onClose={() => setAvatarModalOpen(false)}
            onSuccess={(file) => {
                if (file instanceof File) {
                    setGroupAvatarBlob(file);
                } else {
                    const convertedFile = new File([file], 'avatar.png', { type: file.type });
                    setGroupAvatarBlob(convertedFile);
                }
                setAvatarModalOpen(false);
            }}

        />
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: '1px solid rgba(0,168,255,0.3)',
                    background: 'rgba(0,22,45,0.9)',
                    backdropFilter: 'blur(12px)',
                }}>
                    <IconButton onClick={onBack} sx={{color: '#a0d4ff', mr: 2}}>
                        <ArrowBackIcon sx={{fontSize: 24}}/>
                    </IconButton>
                    <Typography level="h4" sx={{
                        color: '#a0d4ff',
                        flexGrow: 1,
                        textShadow: '0 2px 4px rgba(0,168,255,0.3)'
                    }}>
                        {t('edit')}
                    </Typography>
                </Box>

                <Stack spacing={3} sx={{p: 3, pt: 4}} alignItems="center">
                    <Box
                        onClick={() => setAvatarModalOpen(true)}
                        sx={{
                            cursor: 'pointer',
                            width: 140,
                            height: 140,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <Avatar
                            src={
                                groupAvatarBlob
                                    ? URL.createObjectURL(groupAvatarBlob)
                                    : initialAvatar || undefined
                            }
                            sx={{
                                width: '100%',
                                height: '100%',
                                filter: 'brightness(0.6)',
                            }}
                        />

                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translate(-50%, -50%) scale(1.2)',
                                    opacity: 0.8,
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <AddAPhotoIcon sx={{fontSize: 42}}/>
                        </Box>

                        <input
                            type="file"
                            ref={fileInputRef}
                            hidden
                            accept="image/*"
                            onChange={(e) => {
                                const file = e.target.files?.[0];
                                if (file) {
                                    setGroupAvatarBlob(file);
                                    setHasChanges(true);
                                }
                            }}
                        />
                    </Box>

                    <Sheet
                        sx={{
                            width: '100%',
                            maxWidth: 400,
                            p: 2,
                            borderRadius: '12px',
                            backgroundColor: 'rgba(0,22,45,0.6)',
                            border: '1px solid rgba(0,168,255,0.3)',
                        }}
                    >
                        <Input
                            placeholder={t('group_name')}
                            value={name}
                            variant="soft"
                            onChange={(e) => {
                                setName(e.target.value);
                                setHasChanges(true);
                            }}
                            sx={{
                                bgcolor: 'rgba(0,168,255,0.06)',
                                color: '#a0d4ff',
                                '& input': {
                                    fontSize: '1rem',
                                    color: '#a0d4ff',
                                }
                            }}
                        />
                    </Sheet>

                    <Sheet
                        onClick={onMembersClick}
                        sx={{
                            width: '100%',
                            maxWidth: 400,
                            borderRadius: '12px',
                            background: 'rgba(0,22,45,0.6)',
                            border: '1px solid rgba(0,168,255,0.3)',
                            p: 2,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease-in-out',
                            '&:hover': {
                                background: 'rgba(0,22,45,0.8)',
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <GroupIcon sx={{ color: '#00a8ff', fontSize: 24 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                <Typography level="body-md" sx={{ color: '#a0d4ff', fontSize: '1rem' }}>
                                    {t('members')}
                                </Typography>
                                <Typography sx={{ color: '#8ab4f8', fontSize: '1rem' }}>
                                    {members.length}
                                </Typography>
                            </Box>
                        </Box>
                    </Sheet>
                </Stack>

                <AnimatePresence>
                <motion.div
                    initial={{opacity: 0, scale: 0.8}}
                    animate={{opacity: 1, scale: 1}}
                    exit={{opacity: 0, scale: 0.8}}
                    transition={{duration: 0.2}}
                    style={{
                        position: 'fixed',
                        bottom: 24,
                        left: 470,
                        zIndex: 99,
                    }}
                >
                    {hasChanges && (
                        <IconButton
                            onClick={() => {
                                onSave({
                                    name,
                                    avatar: groupAvatarBlob || undefined
                                });
                                setHasChanges(false);
                            }}
                            sx={{
                                position: 'fixed',
                                bottom: 20,
                                right: 20,
                                borderRadius: '50%',

                                color: '#00162d',
                                backdropFilter: 'blur(6px)',
                                backgroundColor: 'rgba(0, 168, 255, 0.15)',
                                boxShadow: '0 4px 12px rgba(0,168,255,0.4)',
                                cursor: 'pointer',
                            }}

                        >
                            <CheckIcon sx={{fontSize: 24, color: '#00a8ff'}}/>
                        </IconButton>
                    )}
                </motion.div>
                </AnimatePresence>
            </Box>
        </>
);
};

export default ChatEditPanel;
