import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import './i18n';

if (navigator.userAgent.toLowerCase().includes('electron')) {
    localStorage.setItem('theme', 'dark'); // 💥 насильно устанавливаем тему
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
