import { chacha20poly1305 } from "@noble/ciphers/chacha";
import { randomBytes } from "@noble/hashes/utils";
import { encode as encodeBase64, decode as decodeBase64 } from "@stablelib/base64";

export function exportPrivateKey(privateKey: string): void {
    localStorage.setItem('solachat_priv', privateKey);
}

export function importPrivateKey(): string | null {
    return localStorage.getItem('solachat_priv');
}

export function encryptMessage(plaintext: string, key: Uint8Array): string {
    const nonce = randomBytes(12);
    const messageBytes = new TextEncoder().encode(plaintext);
    const cipher = chacha20poly1305(key, nonce);
    const encrypted = cipher.encrypt(messageBytes);

    const combined = new Uint8Array(nonce.length + encrypted.length);
    combined.set(nonce, 0);
    combined.set(encrypted, nonce.length);

    return encodeBase64(combined);
}

export function decryptMessage(ciphertextBase64: string, key: Uint8Array): string {
    const combined = decodeBase64(ciphertextBase64);
    const nonce = combined.slice(0, 12);
    const ciphertext = combined.slice(12);

    const cipher = chacha20poly1305(key, nonce);
    const decrypted = cipher.decrypt(ciphertext);

    return new TextDecoder().decode(decrypted);
}

function toHex(bytes: Uint8Array): string {
    return Array.from(bytes)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
}

export function debugDecrypt(ciphertextBase64: string, key: Uint8Array): void {
    const combined = decodeBase64(ciphertextBase64);
    const nonce = combined.slice(0, 12);
    const ciphertext = combined.slice(12);

    console.log("[decrypt] 🔐 Ciphertext (hex):", toHex(ciphertext));
    console.log("[decrypt] 🔑 Key (hex):", toHex(key));
    console.log("[decrypt] 🌀 Nonce (hex):", toHex(nonce));

    const cipher = chacha20poly1305(key, nonce);
    const decrypted = cipher.decrypt(ciphertext);

    console.log("[decrypt] 🔓 Decrypted bytes (hex):", toHex(decrypted));
    console.log("[decrypt] 🧾 Decrypted string:", new TextDecoder().decode(decrypted));
}
