import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Sheet,
    CircularProgress
} from '@mui/joy';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { CssVarsProvider } from '@mui/joy/styles';
import Navbar from '@/components/home/Navbar';
import Footer from '@/components/home/Footer';
import framesxTheme from '@/theme/theme';
import { getServerStatus } from '@/api/serverApi';
import { useTranslation } from 'react-i18next';

const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

export default function ServerStatusPage() {
    const { t } = useTranslation();
    const [status, setStatus] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    const systems = [
        { nameKey: 'api', statusKey: 'operational' },
        { nameKey: 'search', statusKey: 'operational' },
        { nameKey: 'web_pages', statusKey: 'operational' }
    ];

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const result = await getServerStatus();
                setStatus(result);
            } catch (e) {
                setStatus({ status: 'error' });
            } finally {
                setLoading(false);
            }
        };
        fetchStatus();
    }, []);

    return (
        <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
            <Helmet>
                <title>SolaChat - {t('server_status.page_title')}</title>
            </Helmet>
            <Navbar />

            <Box
                sx={{
                    minHeight: '100vh',
                    background: 'radial-gradient(circle at center, #0a192f 0%, #081428 100%)',
                    py: 10,
                    px: 2
                }}
            >
                <Box sx={{ maxWidth: 900, mx: 'auto' }}>
                    <Typography
                        level="h4"
                        sx={{
                            mb: 3,
                            background: 'rgba(40, 200, 120, 0.2)',
                            color: '#00ff99',
                            borderRadius: 8,
                            p: 2,
                            textAlign: 'center',
                            fontWeight: 600
                        }}
                    >
                        🟢 {t('server_status.all_operational')}
                    </Typography>

                    {loading ? (
                        <Box sx={{ textAlign: 'center', mt: 4 }}>
                            <CircularProgress color="primary" size="lg" />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            {systems.map((system, idx) => (
                                <Sheet
                                    key={idx}
                                    sx={{
                                        p: 3,
                                        borderRadius: 'md',
                                        background: 'rgba(255, 255, 255, 0.03)',
                                        backdropFilter: 'blur(12px)',
                                        border: '1px solid rgba(0, 168, 255, 0.2)'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography level="h4" sx={{ color: '#a0d4ff' }}>
                                            {t(`server_status.systems.${system.nameKey}`)}
                                        </Typography>
                                        <Typography sx={{ color: '#00ff99', fontWeight: 500 }}>
                                            {t(`server_status.statuses.${system.statusKey}`)}
                                        </Typography>
                                    </Box>
                                </Sheet>
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>

            <Footer />
        </CssVarsProvider>
    );
}
