import axios from "axios";
import {Session} from "../components/core/types";
import {getCachedSessionsIndexedDB} from "../cache";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';

export const getSessions = async (userId: number, token: string): Promise<Session[]> => {
    try {
        const cachedSessions = await getCachedSessionsIndexedDB();
        const sessionId = cachedSessions && cachedSessions.length > 0 ? cachedSessions[0].sessionId : '';

        const response = await axios.get(`${API_URL}/api/sessions/${userId}/${sessionId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log("📥 Ответ от сервера (getSessions):", response.data);
        return response.data as Session[];
    } catch (error) {
        console.error("Error fetching sessions:", error);
        throw error;
    }
};

export const deleteSessionById = async (sessionId: string, token: string) => {
    try {
        const response = await axios.delete(`${API_URL}/api/sessions/${sessionId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data.message;
    } catch (error) {
        console.error("Error deleting session:", error);
        throw error;
    }
};

export const deleteAllOtherSessions = async (token: string, sessionId: string) => {
    try {
        const response = await axios.delete(`${API_URL}/api/sessions?sessionId=${sessionId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data.message;
    } catch (error) {
        console.error("Error deleting other sessions:", error);
        throw error;
    }
};
