import { openDatabase, STORE_DECRYPTED } from './indexedDB';

export async function cacheDecryptedMessage(messageId: string, decryptedContent: string): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_DECRYPTED, 'readwrite');
    const store = transaction.objectStore(STORE_DECRYPTED);

    store.put({ id: messageId, content: decryptedContent });

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
    });
}

export async function getCachedDecryptedMessage(messageId: string): Promise<string | null> {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_DECRYPTED, 'readonly');
    const store = transaction.objectStore(STORE_DECRYPTED);

    return new Promise((resolve, reject) => {
        const request = store.get(messageId);
        request.onsuccess = () => {
            if (request.result) {
                resolve(request.result.content);
            } else {
                resolve(null);
            }
        };
        request.onerror = () => reject(request.error);
    });
}

export async function clearDecryptedMessagesExcept(messageIdsToKeep: string[]): Promise<void> {
    const db = await openDatabase();
    const tx = db.transaction(STORE_DECRYPTED, 'readwrite');
    const store = tx.objectStore(STORE_DECRYPTED);

    const request = store.getAllKeys();

    return new Promise((resolve, reject) => {
        request.onsuccess = () => {
            const allKeys = request.result as string[];

            for (const key of allKeys) {
                if (!messageIdsToKeep.includes(key)) {
                    store.delete(key);
                }
            }

            tx.oncomplete = () => resolve();
            tx.onerror = () => reject(tx.error);
        };

        request.onerror = () => reject(request.error);
    });
}

