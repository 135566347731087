import React from 'react';
import { Box, Typography } from '@mui/joy';
import { FAQItem } from './FAQItem';

export const EncryptionKeyQuestions = () => (
    <Box>
        <Typography level="h2" sx={{
            color: '#00a8ff',
            fontSize: '1.5rem',
            mb: 4,
            fontWeight: 600,

        }}>
            Encryption Key Questions
        </Typography>
        <FAQItem id="how-encryption-works" question="Q: How does encryption work in SolaChat?" answer={
            <>
                SolaChat uses end-to-end encryption by default. Messages are encrypted on your device using a shared key
                between you and the recipient. Only the recipient can decrypt it using their private key or the group key.
                <Box mt={2} display="flex" justifyContent="center">
                    <img
                        src="/faq/e2ee.png"
                        alt="End-to-End Encryption Flow"
                        style={{ maxWidth: '100%', borderRadius: 8, boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}
                    />
                </Box>
                <Typography level="body-sm" mt={1} sx={{ color: '#888' }}>
                    End-to-End Encryption Flow
                </Typography>
            </>
        } />

        <FAQItem id="export-keys" question="Q: Can I export and import my encryption keys?" answer={
            <>
                Yes. You can export your encryption keys and import them on another device. This allows you to access
                your encrypted chats across devices while keeping your privacy intact.
            </>
        } />

        <FAQItem id="why-transfer-keys" question="Q: Why do I need to transfer my key manually?" answer={
            <>
                For security reasons, SolaChat doesn’t sync keys automatically. Manual transfer ensures your encryption keys
                never leave your control, which protects your data from being accessed by third parties or the server.
            </>
        } />

        <FAQItem id="what-if-key-leaked" question="Q: What if someone gets my encryption key?" answer={
            <>
                If someone obtains your encryption key, they could potentially decrypt your past and future messages.
                That’s why it's crucial to store it securely and never share it. You can always generate a new key and re-encrypt chats.
            </>
        } />
    </Box>
);
