import { openDatabase, STORE_CHATS } from './indexedDB';
import {ChatProps} from "@/components/core/types";

export async function cacheChats(chats: any[]): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_CHATS, 'readwrite');
    const store = transaction.objectStore(STORE_CHATS);

    store.clear();
    chats.forEach(chat => {
        store.put(chat);
    });

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
    });
}

export async function getCachedChats(): Promise<any[]> {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_CHATS, 'readonly');
    const store = transaction.objectStore(STORE_CHATS);

    return new Promise((resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

export async function replaceChatsCache(chats: any[]): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_CHATS, 'readwrite');
    const store = transaction.objectStore(STORE_CHATS);

    store.clear();

    chats.forEach(chat => {
        store.put(chat);
    });

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
    });
}

export async function getCachedChatById(chatId: number): Promise<ChatProps | undefined> {
    try {
        const db = await openDatabase(); // 👈 используем твой openDatabase
        const transaction = db.transaction(STORE_CHATS, 'readonly');
        const store = transaction.objectStore(STORE_CHATS);

        return new Promise((resolve, reject) => {
            const request = store.get(chatId);
            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
        });
    } catch (e) {
        console.warn('⚠️ Не удалось получить чат из IndexedDB:', e);
        return undefined;
    }
}
