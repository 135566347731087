import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LoginPage from '../../pages/LoginPage';
import RegisterPage from '../../pages/RegisterPage';
import MyAccount from '../../pages/AccountPage';
import ContactsPage from '../../pages/ContactsPage';
import MyMessages from '../../components/messages/MyMessages';
import NotFoundPage from '../../pages/NotFoundPage';
import UnderConstruction from '../../pages/UnderConstruction';
import PrivateRoute from '../../hooks/PrivateRoute';
import HomePage from '../../pages/HomePage';
import AccessDeniedPage from '../../pages/AccessDeniedPage';
import FeedbackPage from "../../pages/FeedbackPage";
import VacanciesPage from "../../pages/VacanciesPage";
import BlogPage from "../../pages/BlogPage";
import ApplicationsPage from "../../pages/ApplicationsPage";
import FeaturesPage from '../../pages/FeaturesPage';
import FAQPage from "../../pages/FAQPage";
import AccountPage from "../../pages/AccountPage";
import {underDevelopmentRoutes} from "./UnderConstructionRoutes";
import ServerStatusPage from "@/pages/ServerStatusPage";


const AppRoutes: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/profile/:identifier" element={<AccountPage />} />

                <Route path="/" element={<HomePage />} />
                <Route path="/feedback" element={<FeedbackPage />} />
                <Route path="/contacts" element={<ContactsPage />} />
                <Route path="/jobs" element={<VacanciesPage />} />

                <Route path="/chat" element={<PrivateRoute element={<MyMessages />} />} />

                <Route path="/features" element={<FeaturesPage />} />
                <Route path="/faq" element={<FAQPage />} />

                {underDevelopmentRoutes.map((route) => (
                    <Route path={route} element={<UnderConstruction />} key={route} />
                ))}

                <Route path="/access-denied" element={<AccessDeniedPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/apps" element={<ApplicationsPage />} />
                <Route path="/new-feature" element={<UnderConstruction />} />
                <Route path="/main" element={<HomePage />} />
                <Route path="/status" element={<ServerStatusPage/>} />

                <Route path="*" element={<HomePage />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
