// components/EmojiPickerPopover.tsx
import React from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material';

type EmojiPickerPopoverProps = {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onEmojiSelect: (emoji: string) => void;
};

const EmojiPickerPopover: React.FC<EmojiPickerPopoverProps> = ({
                                                                   anchorEl,
                                                                   onClose,
                                                                   onEmojiSelect,
                                                               }) => {
    const open = Boolean(anchorEl);
    const id = open ? 'emoji-popover' : undefined;
    const theme = useTheme();

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.mode === 'dark' ? '#1e1f22' : '#fff',
                    borderRadius: 2,
                },
            }}
        >
            <Picker
                data={data}
                theme={'dark'}
                onEmojiSelect={(emoji: any) => {
                    onEmojiSelect(emoji.native);
                    onClose();
                }}
                locale="en"
                emojiStyle={'apple'}
                previewPosition="none"
                navPosition="top"
            />
        </Popover>
    );
};

export default EmojiPickerPopover;
