import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000';


export const searchUsers = async (searchTerm: string) => {
    try {
        const response = await axios.get(`${API_URL}/api/users/search?searchTerm=${searchTerm}`);
        return response.data;
    } catch (error) {
        console.error('Error searching users:', error);
        return [];
    }
};


export const updateUserStatus = async (userId: number, isOnline: boolean, token: string) => {
    if (!token) {
        throw new Error('Authorization token is missing');
    }

    try {
        await axios.post(
            `${API_URL}/api/users/update-status`,
            {
                userId,
                isOnline,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    } catch (error) {
        console.error('Failed to update user status:', error);
        throw error;
    }
};


export const checkUsernameAvailability = async (username: string) => {
    try {
        const response = await axios.get(`${API_URL}/api/users/check-username/${username}`);
        return response.data.available;
    } catch (error) {
        console.error('Error checking username:', error);
        return false;
    }
};


export const updateUserProfile = async (publicKey: string, newUsername: string, aboutMe: string, token: string) => {
    try {
        const response = await axios.put(
            `${API_URL}/api/users/profile/${publicKey}`,
            { newUsername, aboutMe },
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        localStorage.setItem('token', response.data.token);
        return response.data.user;
    } catch (error) {
        console.error('Error updating profile:', error);
        throw error;
    }
};
