export const blogPosts = [
    {
        id: 1,
        title: 'futureOfEncryption',
        excerpt: 'encryptionTrends',
        image: '/img/blog/blog1.jpg',
        date: '2024-03-15'
    },
    {
        id: 2,
        title: 'decentralizationBenefits',
        excerpt: 'decentralizationFuture',
        image: '/blog2.jpg',
        date: '2024-03-14'
    },
];
