import { openDatabase, STORE_MEDIA } from './indexedDB';

export async function cacheMedia(url: string, blob: Blob): Promise<void> {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_MEDIA, 'readwrite');
    const store = transaction.objectStore(STORE_MEDIA);

    const item = { url, blob };
    store.put(item);

    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => resolve();
        transaction.onerror = () => reject(transaction.error);
    });
}

export async function getCachedMedia(url: string): Promise<Blob | null> {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_MEDIA, 'readonly');
    const store = transaction.objectStore(STORE_MEDIA);

    return new Promise((resolve, reject) => {
        const request = store.get(url);
        request.onsuccess = () => {
            if (request.result?.blob) {
                resolve(request.result.blob);
            } else {
                resolve(null);
            }
        };
        request.onerror = () => reject(request.error);
    });
}
