import * as React from 'react';
import { useState, useRef, useEffect, useMemo, memo } from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import DownloadIcon from '@mui/icons-material/Download';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {CircularProgress} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {deleteMessage, pinMessage, unpinMessage} from '@/api';
import ContextMenu from './ContextMenu';
import { MessageProps } from '../core/types';
import {jwtDecode} from "jwt-decode";
import {JwtPayload} from "jsonwebtoken";
import CustomAudioPlayer from '@/components/core/UI/CustomAudioPlayer';
import CheckIcon from "@mui/icons-material/Check";
import {motion} from "framer-motion";
import ImageViewer from "./ImageViewer";
import PushPinIcon from '@mui/icons-material/PushPin';
import { Link } from 'react-router-dom';

type DecodedToken = JwtPayload & { id?: number };

type ChatBubbleProps = MessageProps & {
    variant: 'sent' | 'received';
    onEditMessage: (messageId: number, content: string) => void;
    messageCreatorId: number;
    userId: number;
    user: {
        id: number;
        avatar: string;
        public_key: string;
    };
    isGroupChat: boolean;
    isRead: boolean;
    isDelivered: boolean;
    pending?: boolean;
    isFirstInGroup?: boolean;
    isPinned?: boolean;
};


const isFile = (filePath: string, fileType?: string) => {
    return !isImageFile(filePath, fileType) &&
        !isVideoFile(filePath, fileType) &&
        !isAudioFile(filePath, fileType);
};


const isImageFile = (filePath: string, fileType?: string) => {
    if (fileType?.startsWith("image/")) return true;
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
    const ext = filePath.split('.').pop()?.toLowerCase();
    return ext ? imageExtensions.includes(ext) : false;
};

const isVideoFile = (filePath: string, fileType?: string) => {
    if (fileType?.startsWith("video/")) return true;
    const videoExtensions = ["mp4", "webm", "ogg", "mov", "mkv"];
    const ext = filePath.split('.').pop()?.toLowerCase();
    return ext ? videoExtensions.includes(ext) : false;
};

const isAudioFile = (filePath: string, fileType?: string) => {
    if (fileType?.startsWith("audio/")) return true;
    const audioExtensions = ["mp3", "wav", "ogg", "aac", "flac"];
    const ext = filePath.split('.').pop()?.toLowerCase();
    return ext ? audioExtensions.includes(ext) : false;
};


const isLink = (text: string) => {
    const urlRegex = /(?:^|\s)(https?:\/\/[^\s]+|(?:[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?:[\/\w.-]*)?)(?=\s|$)/g;
    return urlRegex.test(text.trim());
};


const renderMessageContent = (text: string) => {
    const parts = text.split(/((?:https?:\/\/[^\s]+|(?:[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?:[\/\w.-]*)?)(?=\s|$))/g);

    return parts.map((part, index) => {
        if (isLink(part)) {
            let href = part;

            if (href.includes('@')) {
                href = `mailto:${part}`;
            } else if (!part.startsWith('http')) {
                href = `https://${part}`;
            }
            return (
                <a
                    href={part}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        cursor: 'pointer'
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                    onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                >
                    {part.trim()}
                </a>
            );
        }
        return <span key={index}>{part}</span>;
    });
};

const ChatBubble = memo(function ChatBubble(props: ChatBubbleProps) {
    const { t, i18n } = useTranslation();
    const { content, attachment, variant, createdAt, id, isEdited, onEditMessage, messageCreatorId, user, isGroupChat, isRead, pending, isFirstInGroup, isPinned, userId } = props;
    const isSent = variant === 'sent';
    const formattedTime = new Date(createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const handleImageLoad = () => {
        setImageLoading(false);
    };
    const [pendingImage, setPendingImage] = useState<string | null>(null);

    const [isImageOpen, setIsImageOpen] = useState(false);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [anchorPosition, setAnchorPosition] = useState<{ mouseX: number; mouseY: number } | null>(null);
    const [currentVideoTime, setCurrentVideoTime] = useState(0);
    const [currentVolume, setCurrentVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);
    const longPressTimeout = useRef<NodeJS.Timeout | null>(null);

    const modalVideoRef = useRef<HTMLVideoElement>(null);
    const [isClosing, setIsClosing] = useState(false);

    const getAttachmentUrl = () => {
        return attachment?.[0]?.filePath || '';
    };

    const isImage = isImageFile(attachment?.[0]?.filePath || '', attachment?.[0]?.fileType);
    const isVideo = isVideoFile(attachment?.[0]?.filePath || '', attachment?.[0]?.fileType);
    const isAudio = isAudioFile(attachment?.[0]?.filePath || '', attachment?.[0]?.fileType);

    const displayName = user?.username || `${user?.public_key?.slice(0, 4)}…${user?.public_key?.slice(-4)}`;

    const imgRefs = useRef<(HTMLImageElement | null)[]>([]);


    const handleImageClick = ( index: number) => {
        setClickedImageIndex(index);
        setIsImageOpen(true);
    };


    const handleClose = () => {
        setIsClosing(true);
    };

    const syncVideoWithModal = () => {
        if (modalVideoRef.current) {
            modalVideoRef.current.currentTime = currentVideoTime;
            modalVideoRef.current.volume = currentVolume;
            modalVideoRef.current.muted = isMuted;
            modalVideoRef.current.play();
        }
    };


    const [clickedImageIndex, setClickedImageIndex] = useState<number>(0);

    const handleCopy = () => {
        navigator.clipboard.writeText(content);
    };

    const handleForward = () => {
        console.log('Forwarding:', content);
    };

    const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setAnchorPosition({
            mouseX: event.clientX,
            mouseY: event.clientY,
        });
    };

    const handleTouchStart = (event: React.TouchEvent<HTMLElement>) => {
        longPressTimeout.current = setTimeout(() => {
            const touch = event.touches[0];
            setAnchorPosition({
                mouseX: touch.clientX,
                mouseY: touch.clientY,
            });
        }, 200);
    };

    const handleTouchEnd = () => {
        if (longPressTimeout.current) {
            clearTimeout(longPressTimeout.current);
        }
    };

    const handleEdit = () => {
        onEditMessage(Number(id), content);
        setAnchorPosition(null);
    };

    const handleDelete = async () => {
        try {
            await deleteMessage(Number(id));
        } catch (error) {
            console.error('Failed to delete message:', error);
        }
    };

    const updateVideoState = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const video = event.currentTarget;
        if (video.currentTime !== currentVideoTime) {
            setCurrentVideoTime(video.currentTime);
        }
        if (video.volume !== currentVolume) {
            setCurrentVolume(video.volume);
        }
        if (video.muted !== isMuted) {
            setIsMuted(video.muted);
        }
    };

    useEffect(() => {
        if (isVideoOpen && modalVideoRef.current) {
            modalVideoRef.current.addEventListener('loadedmetadata', syncVideoWithModal);
            return () => {
                modalVideoRef.current?.removeEventListener('loadedmetadata', syncVideoWithModal);
            };
        }
    }, [isVideoOpen]);

    const token = localStorage.getItem('token');
    let currentUserId: number | null = null;

    if (token) {
        const decodedToken: DecodedToken = jwtDecode(token);
        currentUserId = decodedToken.id || 0;
    }

    const isFileAttachment = Array.isArray(attachment) &&
        attachment.length > 0 &&
        isFile(attachment[0].filePath || '', attachment[0].fileType);


    const [textSize, setTextSize] = useState(() => {
        const textSizeFromSession = sessionStorage.getItem("textSize");
        return textSizeFromSession ? textSizeFromSession : 14;
    });

    useEffect(() => {
        const handleStorageChange = (event: any) => {
            if (event.key === "textSize") {
                const newTextSize = event.newValue ? event.newValue : 14;
                setTextSize(newTextSize);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const formattedDateTooltip = new Date(createdAt).toLocaleString(i18n.language, {
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
    });
    const isSelected = Boolean(anchorPosition);

    const handlePin = async () => {
        try {
            await pinMessage(Number(id), token!);
        } catch (e) {
            console.error("Ошибка при закреплении:", e);
        }
    };

    const handleUnpin = async () => {
        try {
            await unpinMessage(Number(id), token!);
        } catch (e) {
            console.error("Ошибка при откреплении:", e);
        }
    };

    return (
        <Box
            component={motion.div}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            sx={{
                display: 'flex',
                justifyContent: isSent ? 'flex-end' : 'flex-start',
                mb: { xs: 1, sm: 1 },
                px: { xs: 0, sm: 1 },
                width: { xs: '90%', sm: '70%', md: '100%' },
                flexDirection: 'column',
                alignItems: isSent ? 'flex-end' : 'flex-start',
                backgroundColor: isSelected ? 'rgba(0, 168, 255, 0.1)' : 'transparent',
                borderRadius: '12px',
                transition: 'background-color 0.2s ease',
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={handleContextMenu}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isSent ? 'row-reverse' : 'row',
                    alignItems: 'flex-end',
                    width: '100%',
                }}
            >
                {isGroupChat && !isSent && (
                    <Box
                        sx={{
                            width: '35px',
                            height: '35px',
                            marginRight: '8px',
                            alignSelf: 'flex-end',
                            mt: isFirstInGroup ? '2px' : 0,
                        }}
                    >
                        {isFirstInGroup && (
                            <Link         to={`/chat/#${userId}`}>
                            <img
                                src={user.avatar || 'path/to/default-avatar.jpg'}
                                alt="avatar"
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                }}
                            />
                            </Link>
                        )}
                    </Box>
                )}


                <Sheet
                    component={motion.div}
                    sx={(theme) => ({
                        maxWidth: '100%',
                        width: 'fit-content',
                        minWidth: 'unset',
                        p: !isImage && !isVideo && !isAudio ? { xs: '4px 8px', sm: '6px 8px' } : 0,
                        borderRadius: 'lg',
                        bgcolor: isSelected
                            ? 'rgba(0, 168, 255, 0.2)'
                            : isSent
                                ? 'rgba(0, 168, 255, 0.15)'
                                : 'rgba(255, 255, 255, 0.05)',

                        border: '1px solid rgba(0, 168, 255, 0.3)',
                        boxShadow: '0 4px 16px rgba(0, 168, 255, 0.1)',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        display: 'inline-block',
                        fontSize: '14px',
                        lineHeight: '18px',
                        position: 'relative',
                        overflow: 'hidden',
                        '@media (max-width: 600px)': { maxWidth: '85%' },
                        '&:before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            zIndex: -1,
                        },
                    })}
                >
                    {isGroupChat && !isSent && isFirstInGroup && (
                        <Typography sx={{
                            color: '#00a8ff',
                            fontWeight: 'bold',
                            mb: 0.5
                        }}>
                            {displayName}
                        </Typography>
                    )}

                    {Array.isArray(attachment) && attachment.length > 0 && (
                        <Box
                            sx={{
                                display: attachment.length === 1 ? 'flex' : 'grid',
                                justifyContent: 'flex-start',
                                gap: '4px',
                                maxWidth: '600px',
                                gridTemplateColumns: (() => {
                                    if (attachment.length === 1) return undefined;
                                    if (attachment.length === 2) return 'repeat(2, 1fr)';
                                    if (attachment.length === 3 || attachment.length === 4) return '2fr 1fr';
                                    if (attachment.length >= 5) return 'repeat(3, 1fr)';
                                })(),
                                mb: content ? 1 : 0,
                            }}
                        >
                            {attachment
                                .filter(file => isImageFile(file.filePath, file.fileType) || isVideoFile(file.filePath, file.fileType))
                                .map((file, index) => {
                                    const isImage = isImageFile(file.filePath, file.fileType);
                                    const isVideo = isVideoFile(file.filePath, file.fileType);
                                    const isSingle = attachment.length === 1;

                                    const gridColumn =
                                        attachment.length === 3 && index === 0 ? '1 / 2' :
                                            attachment.length === 4 && index === 0 ? '1 / 2' :
                                                attachment.length === 5 && index === 0 ? '1 / 2' :
                                                    attachment.length === 5 && index === 1 ? '2 / 3' :
                                                        attachment.length === 5 && index === 2 ? '3 / 4' :
                                                            attachment.length === 5 && index === 3 ? '1 / 3' :
                                                                attachment.length === 5 && index === 4 ? '3 / 4' :
                                                                    'auto';

                                    const gridRow =
                                        attachment.length === 3 && index === 0 ? '1 / 3' :
                                            attachment.length === 4 && index === 0 ? '1 / 4' :
                                                attachment.length === 5 && index >= 3 ? '2 / 3' :
                                                    'auto';

                                    return (
                                        <Box
                                            key={index}
                                            onClick={() => isImage ? handleImageClick(index) : null}
                                            sx={{
                                                position: 'relative',
                                                cursor: isImage ? 'pointer' : 'default',
                                                overflow: 'hidden',
                                                width: '100%',
                                                maxWidth: isSingle ? '500px' : undefined,
                                                gridColumn,
                                                gridRow,
                                                backgroundColor: '#00000010',
                                            }}
                                        >
                                            {isImage && (
                                                <img
                                                    ref={el => imgRefs.current[index] = el}
                                                    src={file.filePath}
                                                    alt={`attachment-${index}`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: isSingle ? 'contain' : 'cover',

                                                        opacity: imageLoading ? 0.5 : 1,
                                                        transition: 'opacity 0.3s ease',
                                                        display: 'block',
                                                        maxHeight: isSingle ? '400px' : undefined,
                                                    }}
                                                    onLoad={handleImageLoad}
                                                />
                                            )}

                                            {isVideo && (
                                                <video
                                                    src={file.filePath}
                                                    controls
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '10px',
                                                        display: 'block',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    );
                                })}
                        </Box>
                    )}
                    {isAudio && !isVideo && (
                        <Box sx={{
                            width: '100%',
                            maxWidth: { xs: '280px', md: '600px' },
                            maxHeight: { xs: '60px', md: '80px' },
                            mt: 1,
                            mb: content ? 1 : 0
                        }}>
                            <CustomAudioPlayer audioSrc={getAttachmentUrl()} />
                        </Box>
                    )}

                    {content && (
                        <Typography
                            sx={{
                                fontSize: { xs: `${textSize}px`, sm: `${textSize}px` },
                                color: isSent ? '#a0d4ff' : '#8ab4f8',
                                marginLeft: isImage || isVideo || isAudio ? '12px' : '0px',
                                marginBottom: isImage || isVideo || isAudio ? '8px' : '4px',
                                textAlign: 'left',
                                transition: 'color 0.3s ease',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                maxWidth: '800px',
                                wordBreak: 'break-word',
                                hyphens: 'auto',
                                display: 'inline-block',
                                paddingRight: isEdited
                                    ? (isSent ? '115px' : '90px')
                                    : isPinned
                                        ? (isSent ? '80px' : '75px')
                                        : (isSent ? '60px' : '40px'),

                            }}
                        >
                            {renderMessageContent(content)}
                        </Typography>
                    )}
                    <Stack
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                        sx={{
                            position: 'absolute',
                            bottom: '6px',
                            right:  '10px',
                            alignItems: 'center',
                            backgroundColor: (isImage && !content) || (isVideo && !content) ? 'rgba(0, 0, 0, 0.4)' : 'transparent',
                            padding: (isImage && !content) || (isVideo && !content) ? '2px 6px' : '0px',
                            borderRadius: (isImage && !content) || (isVideo && !content) ? '12px' : '0px',
                        }}
                    >
                        {props.isPinned && (
                            <PushPinIcon
                                fontSize="small"
                                sx={{
                                    color: '#00a8ff',
                                    opacity: 0.8,
                                    mr: 0.5,
                                    fontSize: '16px',
                                    transform: 'rotate(45deg)',
                                }}
                            />
                        )}
                        {isEdited && (
                            <Typography sx={{
                                fontSize: '12px',
                                opacity: 0.8
                            }}>
                                {t('edited')}
                            </Typography>
                        )}
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '12px',
                            color: '#00a8ff'
                        }}>
                            <Typography component="span" sx={{ fontSize: '12px' }}  title={formattedDateTooltip}>
                                {new Date(createdAt).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </Typography>

                            {isSent && (
                                pending ? (
                                    <CircularProgress
                                        size="sm"
                                        sx={{
                                            fontSize: "12px",
                                            ml: { xs: '1px', sm: '4px' },
                                            color: '#00a8ff'
                                        }}
                                    />
                                ) : (
                                    isRead ? (
                                        <DoneAllIcon sx={{
                                            fontSize: '18px',
                                            ml: { xs: '1px', sm: '4px' }
                                        }} />
                                    ) : (
                                        <CheckIcon sx={{
                                            fontSize: '18px',
                                            ml: { xs: '1px', sm: '4px' }
                                        }} />
                                    )
                                )
                            )}
                        </Box>
                    </Stack>
                    {isFileAttachment && Array.isArray(attachment) && attachment.length > 0 && (
                        <Box
                            sx={{
                                width: '100%',
                                p: '8px',
                                maxWidth: '300px',
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={1.5}
                                sx={{
                                    alignItems: 'center',
                                    width: '100%',
                                    position: 'relative',
                                    textDecoration: 'none',
                                }}
                                component="a"
                                href={attachment[0].filePath}
                                download={attachment[0].fileName}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        flexShrink: 0,
                                        width: '48px',
                                        height: '48px',
                                        cursor: 'pointer',
                                        '&:hover .download-icon': {
                                            opacity: 1,
                                            visibility: 'visible',
                                        },
                                        '&:hover .file-type': {
                                            opacity: 0,
                                            visibility: 'hidden',
                                        },
                                    }}
                                >
                                    <InsertDriveFileRoundedIcon
                                        sx={{
                                            fontSize: '48px',
                                            color: isSent ? '#a0d4ff' : '#8ab4f8',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />

                                    <Typography
                                        className="file-type"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '14px',
                                            fontWeight: '700',
                                            color: 'white',
                                            textShadow: '0 1px 3px rgba(0, 0, 0, 0.4)',
                                            transition: 'all 0.2s ease',
                                            textTransform: 'uppercase',
                                            pointerEvents: 'none',
                                            userSelect: 'none',
                                        }}
                                    >
                                        {(() => {
                                            const ext = attachment[0].fileName.split('.').pop()?.toUpperCase();
                                            return ext && ext.length > 5 ? ext.slice(0, 4) + '…' : ext;
                                        })()}
                                    </Typography>

                                    <DownloadIcon
                                        className="download-icon"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: '24px',
                                            opacity: 0,
                                            visibility: 'hidden',
                                            color: 'white',
                                            transition: 'all 0.2s ease',
                                            bgcolor: 'rgba(0, 0, 0, 0.3)',
                                            borderRadius: '50%',
                                            p: '4px',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                                            pointerEvents: 'none',
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        flexGrow: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        color: isSent ? '#a0d4ff' : '#8ab4f8',
                                        pl: 1,
                                        pr: 1,
                                    }}
                                >
                                    {attachment[0].fileName}
                                </Typography>
                            </Stack>
                        </Box>
                    )}
                </Sheet>
            </Box>

            {isVideoOpen && videoSrc && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 999,
                        cursor: 'pointer',
                    }}
                    onClick={handleClose}
                >
                    <video
                        ref={modalVideoRef}
                        src={videoSrc}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            borderRadius: '12px',
                        }}
                        controls
                        onPlay={syncVideoWithModal}
                        onTimeUpdate={updateVideoState}
                        onVolumeChange={updateVideoState}
                    />
                </Box>
            )}

            {Array.isArray(attachment) && attachment.length > 0 && (
                <ImageViewer
                    open={isImageOpen}
                    imageSrcList={attachment.map(file => file.filePath)}
                    initialIndex={clickedImageIndex || 0}
                    senderAvatar={user?.avatar || ''}
                    content={content}
                    senderPublicKey={user?.public_key || ''}
                    date={formattedDateTooltip}
                    onClose={() => setIsImageOpen(false)}
                    onDelete={() => console.log("Удаление изображения")}
                    loading={!!pendingImage}

                />
            )}
            {anchorPosition !== null && (
                <ContextMenu
                    anchorPosition={{ top: anchorPosition.mouseY, left: anchorPosition.mouseX }}
                    open={true}
                    onClose={() => setAnchorPosition(null)}
                    onPin={handlePin}
                    onUnpin={handleUnpin}
                    onEdit={handleEdit}
                    isPinned={isPinned ?? false}
                    onCopy={handleCopy}
                    onForward={handleForward}
                    onDelete={handleDelete}
                    currentUserId={currentUserId ?? 0}
                    messageCreatorId={messageCreatorId}
                />
            )}

        </Box>
    );
});

export default ChatBubble;
